const Spacing = {
    extraExtraLarge: 30,
    extraLarge: 20,
    large: 15,
    base: 10,
    small: 5,
    extraSmall: 2
};

export default Spacing;
