import {createSlice} from "@reduxjs/toolkit";
import Api from "../../services/Api";

const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState: {
        tutorialScreens: null,
        loading: false,
        error: null,
    },
    reducers: {
        requestViewerTutorialScreens: {
            reducer(state) {
                state.error = null;
            }
        },
        receiveViewerTutorialScreens: {
            reducer(state, action) {
                state.tutorialScreens = action.payload.tutorialScreens;
                state.loading = false;
                state.error = null;
            },
            prepare(tutorialScreens) {
                return { payload: { tutorialScreens } };
            }
        },
        receiveFailedViewerTutorialScreens: {
            reducer(state, action) {
                state.tutorialScreens = null;
                state.loading = false;
                state.error = action.payload.error;
            },
            prepare(error) {
                return { payload: { error } };
            }
        },
        readTutorialScreen: {
            reducer(state, action) {
                const index = state.tutorialScreens.findIndex(t => t.tutorialScreen.id === action.payload.id);
                state.tutorialScreens[index] = {...state.tutorialScreens[index], read: true};
            },
            prepare(id) {
                return { payload: { id } };
            }
        },
    }
});

export const {
    requestViewerTutorialScreens,
    receiveViewerTutorialScreens,
    receiveFailedViewerTutorialScreens,
    readTutorialScreen
} = tutorialSlice.actions;

export default tutorialSlice.reducer;

export const fetchViewerTutorialScreens = () => async (dispatch, getState) => {
    dispatch(requestViewerTutorialScreens());

    const state = getState();
    try {
        const tutorialScreens = await Api.viewerTutorialScreens(state.auth.login.accessToken);
        dispatch(receiveViewerTutorialScreens(tutorialScreens));
    } catch (e) {
        dispatch(receiveFailedViewerTutorialScreens(e.message));
    }
};

export const performReadTutorial = (id) => async (dispatch, getState) => {
    const state = getState();
    try {
        await Api.readTutorial(state.auth.login.accessToken, id);
        dispatch(readTutorialScreen(id));
    } catch (e) {
        console.log(e);
    }
};
