import React from 'react';
import { G, Mask, Path, Rect, Svg } from 'react-native-svg';

const GameStatsIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">

            <Path d="M28.998 3.5L21.5595 7.93489L29.1195 12.1594L28.998 3.5ZM12.8215 14.7405L13.3713 14.2303L12.8215 14.7405ZM9.63393 15.0832L8.98831 14.7016L9.63393 15.0832ZM17.1275 19.3801L17.6772 18.8699L17.1275 19.3801ZM17.6772 18.8699L13.3713 14.2303L12.2718 15.2507L16.5778 19.8903L17.6772 18.8699ZM8.98831 14.7016L3.13643 24.6005L4.42768 25.3638L10.2796 15.4649L8.98831 14.7016ZM25.0507 9.02658L19.6847 18.6293L20.9941 19.361L26.3601 9.75829L25.0507 9.02658ZM5.50371 28.7499H14.998V27.2499H5.50371V28.7499ZM3.13643 24.6005C2.05272 26.4337 3.37416 28.7499 5.50371 28.7499V27.2499C4.53573 27.2499 3.93508 26.1971 4.42768 25.3638L3.13643 24.6005ZM13.3713 14.2303C12.1202 12.8823 9.92419 13.1184 8.98831 14.7016L10.2796 15.4649C10.705 14.7453 11.7032 14.638 12.2718 15.2507L13.3713 14.2303ZM16.5778 19.8903C17.8479 21.2588 20.0833 20.9909 20.9941 19.361L19.6847 18.6293C19.2707 19.3702 18.2546 19.4919 17.6772 18.8699L16.5778 19.8903Z" fill={color} />
            <Path d="M29.25 16C29.25 15.3096 28.6904 14.75 28 14.75C27.3096 14.75 26.75 15.3096 26.75 16H29.25ZM9.63589 15.0832L8.55985 14.4471L9.63589 15.0832ZM17.1295 19.3801L18.0457 18.5298L17.1295 19.3801ZM20.3413 18.9952L21.4325 19.6049L20.3413 18.9952ZM18.0457 18.5298L13.7397 13.8901L11.9073 15.5908L16.2133 20.2304L18.0457 18.5298ZM8.55985 14.4471L2.70797 24.346L4.86004 25.6182L10.7119 15.7193L8.55985 14.4471ZM5.50567 29.2499H26V26.7499H5.50567V29.2499ZM29.25 25.9999V16H26.75V25.9999H29.25ZM22.9088 11.838L19.2501 18.3854L21.4325 19.6049L25.0912 13.0575L22.9088 11.838ZM25.4088 7.36412L22.9088 11.838L25.0912 13.0575L27.5912 8.58363L25.4088 7.36412ZM26 29.2499C27.7949 29.2499 29.25 27.7948 29.25 25.9999H26.75C26.75 26.4141 26.4142 26.7499 26 26.7499V29.2499ZM2.70797 24.346C1.42721 26.5125 2.98892 29.2499 5.50567 29.2499V26.7499C4.92488 26.7499 4.56448 26.1182 4.86004 25.6182L2.70797 24.346ZM13.7397 13.8901C12.2612 12.2971 9.66588 12.5762 8.55985 14.4471L10.7119 15.7193C10.9672 15.2876 11.5661 15.2232 11.9073 15.5908L13.7397 13.8901ZM16.2133 20.2304C17.7143 21.8477 20.3562 21.5311 21.4325 19.6049L19.2501 18.3854C19.0017 18.8299 18.3921 18.903 18.0457 18.5298L16.2133 20.2304Z" fill={color} />
        </Svg>

    );
};

export default GameStatsIcon;
