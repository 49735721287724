import {createSlice} from "@reduxjs/toolkit";
import Api from "../../../services/Api";
import {receiveUserAccount} from "../actions/login";
import UserSession from "../../../services/UserSession";

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        loadingEditProfile: false,
        isErrorEditProfile: false,
        resultMessageEditProfile: '',

        loadingEditUsername: false,
        isErrorEditUsername: false,
        resultMessageEditUsername: '',

        loadingEditEmail: false,
        isErrorEditEmail: false,
        resultMessageEditEmail: '',

        loadingChangePassword: false,
        isErrorChangePassword: false,
        resultMessageChangePassword: '',
        subscription: null,

        settings: {},
    },
    reducers: {
        clearResultMessage: {
            reducer(state) {
                state.isErrorEditProfile = false;
                state.resultMessageEditProfile = '';
                state.isErrorEditEmail = false;
                state.resultMessageEditEmail = '';
                state.isErrorChangePassword = false;
                state.resultMessageChangePassword = '';
            }
        },
        editProfile: {
            reducer(state) {
                state.loadingEditProfile = true;
                state.resultMessageEditProfile = null;
                state.isErrorEditProfile = false;
            },
        },
        receiveEditProfile: {
            reducer(state) {
                state.loadingEditProfile = false;
                state.resultMessageEditProfile = 'Profile edited successfully';
                state.isErrorEditProfile = false;
            },
        },
        receiveFailedEditProfile: {
            reducer(state, action) {
                state.loadingEditProfile = false;
                state.resultMessageEditProfile = action.payload.error;
                state.isErrorEditProfile = true;
            },
            prepare(error) {
                return {payload: {error}}
            }
        },
        editUsername: {
            reducer(state) {
                state.loadingEditUsername = true;
                state.resultMessageEditUsername = null;
                state.isErrorEditUsername = false;
            },
        },
        receiveEditUsername: {
            reducer(state) {
                state.loadingEditUsername = false;
                state.resultMessageEditUsername = 'Username changed successfully';
                state.isErrorEditUsername = false;
            },
        },
        receiveFailedEditUsername: {
            reducer(state, action) {
                state.loadingEditUsername = false;
                state.resultMessageEditUsername = action.payload.error;
                state.isErrorEditUsername = true;
            },
            prepare(error) {
                return {payload: {error}}
            }
        },
        editEmail: {
            reducer(state) {
                state.loadingEditEmail = true;
                state.resultMessageEditEmail = null;
                state.isErrorEditEmail = false;
            },
        },
        receiveEditEmail: {
            reducer(state) {
                state.loadingEditEmail = false;
                state.resultMessageEditEmail = 'Email changed successfully';
                state.isErrorEditEmail = false;
            },
        },
        receiveFailedEditEmail: {
            reducer(state, action) {
                state.loadingEditEmail = false;
                state.resultMessageEditEmail = action.payload.error;
                state.isErrorEditEmail = true;
            },
            prepare(error) {
                return {payload: {error}}
            }
        },

        changePassword: {
            reducer(state) {
                state.loadingChangePassword = true;
                state.resultMessageChangePassword = null;
                state.isErrorChangePassword = false;
            },
        },
        receiveChangePassword: {
            reducer(state) {
                state.loadingChangePassword = false;
                state.resultMessageChangePassword = 'Password changed successfully';
                state.isErrorChangePassword = false;
            },
        },
        receiveFailedChangePassword: {
            reducer(state, action) {
                state.loadingChangePassword = false;
                state.resultMessageChangePassword = action.payload.error;
                state.isErrorChangePassword = true;
            },
            prepare(error) {
                return {payload: {error}}
            }
        },
        receiveSubscription: {
            reducer(state, action) {
                state.subscription = action.payload.subscription;
            },
            prepare(subscription) {
                return {payload: {subscription, skipSubscriptionMiddleware: true,}}
            }
        },
        receiveSettings: {
            reducer(state, action) {
                state.settings = {...state.settings, ...action.payload.settings};
            },
            prepare(settings) {
                return { payload: { settings } };
            }
        },
    }
});

export const {
    clearResultMessage,
    editProfile,
    receiveEditProfile,
    receiveFailedEditProfile,
    editUsername,
    receiveEditUsername,
    receiveFailedEditUsername,
    editEmail,
    receiveEditEmail,
    receiveFailedEditEmail,
    changePassword,
    receiveChangePassword,
    receiveFailedChangePassword,
    receiveSubscription,
    receiveSettings
} = profileSlice.actions;

export default profileSlice.reducer;

export const performEditProfile = (firstName, lastName, birthdate, newsletter) => async (dispatch, getState) => {

    dispatch(editProfile());
    const state = getState();
    try {
        const userAccount = await Api.editUserAccountInfo(state.auth.login.accessToken, null, null, null, firstName, lastName, birthdate, newsletter);

        const tokenData = await UserSession.getTokenData();
        tokenData.userAccount = userAccount;
        await UserSession.setTokenData(tokenData);

        dispatch(receiveEditProfile());
        dispatch(receiveUserAccount(userAccount))
    } catch (e) {
        dispatch(receiveFailedEditProfile(e.message));
    }
}
export const performEditUsername = (email, username, currentPassword, firstName, lastName, birthdate, newsletter) => async (dispatch, getState) => {

    dispatch(editUsername());
    const state = getState();
    try {
        const userAccount = await Api.editUserAccountInfo(state.auth.login.accessToken, email, username, currentPassword, firstName, lastName, birthdate, newsletter);

        const tokenData = await UserSession.getTokenData();
        tokenData.userAccount = userAccount;
        await UserSession.setTokenData(tokenData);

        dispatch(receiveEditUsername());
        dispatch(receiveUserAccount(userAccount))
    } catch (e) {
        dispatch(receiveFailedEditUsername(e.message));
    }
}
export const performEditEmail = (email, username, currentPassword, firstName, lastName, birthdate, newsletter) => async (dispatch, getState) => {

    dispatch(editEmail());
    const state = getState();
    try {
        const userAccount = await Api.editUserAccountInfo(state.auth.login.accessToken, email, username, currentPassword, firstName, lastName, birthdate, newsletter);

        const tokenData = await UserSession.getTokenData();
        tokenData.userAccount = userAccount;
        await UserSession.setTokenData(tokenData);

        dispatch(receiveEditEmail());
        dispatch(receiveUserAccount(userAccount))
    } catch (e) {
        dispatch(receiveFailedEditEmail(e.message));
    }
}
export const performChangePassword = (currentPassword, newPassword) => async (dispatch, getState) => {

    dispatch(changePassword());
    const state = getState();
    try {
        const userAccount = await Api.editUserAccountPassword(state.auth.login.accessToken, currentPassword, newPassword);

        const tokenData = await UserSession.getTokenData();
        tokenData.userAccount = userAccount;
        await UserSession.setTokenData(tokenData);

        dispatch(receiveChangePassword());
        dispatch(receiveUserAccount(userAccount))
    } catch (e) {
        dispatch(receiveFailedChangePassword(e.message));
    }
}

export const performGetViewerSubscription = (force = false) => async (dispatch, getState) => {
    try {
        const state = getState();
        if (!state.auth.login.accessToken) {
            return;
        }

        let subscription;
        const lastSubscriptionCheck = await UserSession.getLastSubscriptionCheck();
        if (force || lastSubscriptionCheck === null || Date.now() - lastSubscriptionCheck >= (20 * 1000)) {
            subscription = await Api.viewerSubscription(state.auth.login.accessToken);
            await UserSession.setLastSubscriptionCheck();
        } else {
            subscription = await UserSession.getSubscription();
        }

        dispatch(performReceiveSubscription(subscription));
    } catch (e) {
        console.log(e);
        await UserSession.setSubscription(null);
    }
};

export const performReceiveSubscription = (subscription) => async (dispatch, getState) => {
    dispatch(receiveSubscription(subscription));
    await UserSession.setSubscription(subscription);
};

export const performGetViewerSettings = () => async (dispatch, getState) => {
    const state = getState();
    try {
        const settings = await Api.viewerSettings(state.auth.login.accessToken);
        dispatch(receiveSettings(settings || {}));
    } catch (e) {
        console.log(e);
    }
};

export const performSetViewerSettings = (settings) => async (dispatch, getState) => {
    const state = getState();
    try {
        const newSettings = await Api.setViewerSettings(state.auth.login.accessToken, settings);
        dispatch(receiveSettings(newSettings));
    } catch (e) {
        console.log(e);
    }
};
