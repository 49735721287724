import React, {useEffect, useState} from "react";
import {createStackNavigator} from "@react-navigation/stack";
import {performLocalLogin} from "../../redux/auth/actions/login";
import {connect} from "react-redux";
import FacebookRedirectLoginScreen from "./FacebookRedirectLoginScreen";
import GoogleRedirectLoginScreen from "./GoogleRedirectLoginScreen";
import JoinGameScreen from "./JoinGameScreen";
import StartScreen from "./StartScreen";
import {StackActions, useNavigation} from "@react-navigation/native";
import RequestChangePasswordScreen from "./RequestChangePasswordScreen";
import ChangePasswordScreen from "./ChangePasswordScreen";
import Analytics, {EVENTS} from "../../services/Analytics";

const Stack = createStackNavigator();

const UnauthenticatedScreen = ({dispatch, navigation, userAccount, newUser, localLoginFailed}) => {

    useNavigation()

    const [attemptedLocalLogin, setAttemptedLocalLogin] = useState(false);

    useEffect(() => {
        if (!attemptedLocalLogin && !userAccount) {
            setAttemptedLocalLogin(true);
            dispatch(performLocalLogin());
        }
    }, [attemptedLocalLogin, userAccount]);

    useEffect(() => {
        if (userAccount && newUser !== null) {
            if (newUser) {
                Analytics.logEvent(EVENTS.USER_SIGNUP);
            }
            navigation.navigate('Authenticated', {screen: 'Home'})
        }
    }, [localLoginFailed, userAccount, newUser]);

    const defaultTitle = 'Hand & Foot Friends & Family Edition';

    return (
        <Stack.Navigator screenOptions={{headerShown: false}}
                         initialRouteName={'Start'}>
            <>
                <Stack.Screen name="Start" component={StartScreen}
                              options={{title: defaultTitle}}/>
                <Stack.Screen name="FacebookLogin" component={FacebookRedirectLoginScreen}
                              options={{title: defaultTitle}}/>
                <Stack.Screen name="GoogleLogin" component={GoogleRedirectLoginScreen}
                              options={{title: defaultTitle}}/>
                <Stack.Screen name="RequestChangePassword" component={RequestChangePasswordScreen}
                              options={{title: defaultTitle}}/>
                <Stack.Screen name="ChangePassword" component={ChangePasswordScreen}
                              options={{title: defaultTitle}}/>
                <Stack.Screen name="JoinGame" component={JoinGameScreen}
                              options={{title: defaultTitle}}/>
            </>
        </Stack.Navigator>
    )
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null,
        newUser: state.auth.login.newUser || false,
        localLoginFailed: state.auth.login.localLoginFailed
    }
};


export default connect(mapStateToProps)(UnauthenticatedScreen);
