import React, {useCallback, useContext, useState} from "react";
import {TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import Colors from "../../styles/colors";
import {SafeAreaView} from "react-native-safe-area-context";
import StyleContext from "../../StyleContext";
import Api from "../../services/Api";
import {connect} from "react-redux";
import Spacing from "../../styles/spacing";
import CreditCard from "../../sharedComponents/creditCard/CreditCard";
import PlainButton from "../../sharedComponents/plainButton/PlainButton";
import {performReceiveSubscription, receiveSubscription} from "../../redux/auth/reducers/profileSlice";
import IconButton from "../../sharedComponents/iconButton/IconButton";
import ChevronIcon from "../../icons/ChevronIcon";
import {useNavigation} from "@react-navigation/native";
import SubscriptionStatus from "../../sharedComponents/subscriptionStatus/SubscriptionStatus";
import ConfirmationDialog from "../../sharedComponents/confirmationDialog/ConfirmationDialog";
import {format, parseISO, add} from "date-fns";
import UserSession from "../../services/UserSession";
import Analytics, {EVENTS} from "../../services/Analytics";
import Tooltip from "../../sharedComponents/tooltip/Tooltip";

const SubscriptionScreen = ({dispatch, accessToken, subscription}) => {

    const navigation = useNavigation();
    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);
    const stripe = useStripe();
    const elements = useElements();

    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingCancelSubscription, setLoadingCancelSubscription] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [generalAppInfo, setGeneralAppInfo] = useState(null);
    const [requestedSubscriptionPlans, setRequestedSubscriptionPlans] = useState(false);
    const [selectedSubscriptionPlanPrice, setSelectedSubscriptionPlanPrice] = useState(null);
    const [cardNumberError, setCardNumberError] = useState(true);
    const [cardExpirationError, setCardExpirationError] = useState(true);
    const [cardCVCError, setCardCVCError] = useState(true);
    const [changingCreditCard, setChangingCreditCard] = useState(false);
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [saveResult, setSaveResult] = useState({error: false, message: ''});
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [showConfirmSwitchPriceModal, setShowConfirmSwitchPriceModal] = useState(false);
    const hasPaymentMethod = !!subscription?.cardBrand;
    const subscriptionInactiveOrScheduledToEnd = subscription?.status === 'canceled' || subscription?.status === 'incomplete_expired' || subscription?.cancelAt;

    useFocusEffect(useCallback(() => {
        setSaveResult({error: false, message: ''});
    }, []));

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getInfo(accessToken);
        }
    }, [accessToken]));

    useFocusEffect(useCallback(() => {
        if (accessToken && subscription && subscriptionPlans.length === 0 && !requestedSubscriptionPlans) {
            setRequestedSubscriptionPlans(true);
            getSubscriptionPlans();
        }
    }, [accessToken, subscription]));

    useFocusEffect(useCallback(() => {
        if (subscription) {
            setEnableSaveButton(
                (subscription.subscriptionDetails.isPaymentMethodVisible && !changingCreditCard && selectedSubscriptionPlanPrice?.id !== subscription?.subscriptionPlanPrice.id) ||
                (changingCreditCard && (!cardNumberError && !cardExpirationError && !cardCVCError)) ||
                (!subscription.subscriptionDetails.isPaymentMethodVisible && (!cardNumberError && !cardExpirationError && !cardCVCError))
            );
        }
    }, [cardNumberError, cardExpirationError, cardCVCError, changingCreditCard, subscription, selectedSubscriptionPlanPrice]));

    const getSubscriptionPlans = async () => {
        const plans = await Api.subscriptionPlans(accessToken);
        setSubscriptionPlans(plans);

        const wantsTosubscribe = await UserSession.getWantsToSubscribe();
        const selectedId = wantsTosubscribe?.id ?? subscription?.subscriptionPlanPrice.id;
        setSelectedSubscriptionPlanPrice(plans[plans.length - 1].subscriptionPlanPrices.find((planPrice) => planPrice.id === selectedId));
        await UserSession.clearWantsToSubscribe();

        loadingContext.setScreenLoading(false);
    };

    const getInfo = async (accessToken) => {
        const generalAppInfoData = await Api.generalAppInfo(accessToken);
        setGeneralAppInfo(generalAppInfoData);
    };

    const CARD_OPTIONS = {
        iconStyle: 'default',
        showIcon: true,
        style: {
            base: {
                iconColor: Colors.primary4,
                color: Colors.onBackground,
                fontWeight: 400,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: 'rgba(112, 121, 143, 0.6)',
                },
            },
            invalid: {
                iconColor: Colors.error,
                color: Colors.error,
            },
        },
    };

    const handleSavePress = async (checkConfirm = true) => {

        if (checkConfirm && ['active', 'active_pricing_transition'].includes(subscription.subscriptionDetails.state) && subscription.subscriptionPlanPrice.id !== selectedSubscriptionPlanPrice.id) {
            setShowConfirmSwitchPriceModal(true);
            return;
        }

        setShowConfirmSwitchPriceModal(false);
        setLoadingSave(true);
        setSaveResult({error: false, message: ''});
        let result = null;
        if (changingCreditCard || !subscription.subscriptionDetails.isPaymentMethodVisible) {
            result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
            });

            if (result?.paymentMethod?.id) {
                await Analytics.logEvent(EVENTS.PAYMENT_METHOD_CREATE, {stripe_id: result.paymentMethod.id});
            }
        }

        if (!result?.error) {
            try {
                const subscription = await Api.subscribeToSubscriptionPlan(accessToken, selectedSubscriptionPlanPrice.id, result?.paymentMethod?.id);
                dispatch(performReceiveSubscription(subscription));
                setChangingCreditCard(false);
                setSaveResult({error: false, message: 'Your subscription has been updated successfully'});
            } catch (e) {
                setSaveResult({error: true, message: e.message});
            }
        } else {
            setSaveResult({error: true, message: result?.error});
        }

        setLoadingSave(false);
    };

    const handleCancelSubscriptionPress = () => {
        setShowConfirmCancelModal(true);
    };

    const handleConfirmCancelSubscriptionPress = async () => {
        setShowConfirmCancelModal(false);
        setLoadingCancelSubscription(true);
        setSaveResult({error: false, message: ''});

        try {
            const subscription = await Api.cancelSubscription(accessToken);
            dispatch(receiveSubscription(subscription));
            setSaveResult({error: false, message: 'Your subscription has been canceled successfully'});
        } catch (e) {
            setSaveResult({error: true, message: e});
        }

        setLoadingCancelSubscription(false);
    };

    const handleConfirmSwitchPricePress = async () => {
        await handleSavePress(false);
    };

    const getSwitchPriceConfirmationText = () => {
        const currentSubscriptionPeriod = subscription.subscriptionPlanPrice.billingPeriod === 'month' ? 'monthly' : 'annual';
        const currentSubscriptionPeriodPrefix = subscription.subscriptionPlanPrice.billingPeriod === 'month' ? 'a' : 'an';
        const newSubscriptionPeriod = selectedSubscriptionPlanPrice.billingPeriod === 'month' ? 'monthly' : 'annual';
        const newSubscriptionPeriodPrefix = selectedSubscriptionPlanPrice.billingPeriod === 'month' ? 'a' : 'an';
        const currentPeriodEndDate = format(parseISO(subscription.currentPeriodEnd), "MMMM d, Y");
        const newPeriodStartDate = currentPeriodEndDate;
        const newPeriodEndDate = selectedSubscriptionPlanPrice.billingPeriod === 'year' ? format(add(parseISO(subscription.currentPeriodEnd), {years: 1}), "MMMM d, Y") :
            format(add(parseISO(subscription.currentPeriodEnd), {months: 1}), "MMMM d, Y");
        return `You are switching from ${currentSubscriptionPeriodPrefix} ${currentSubscriptionPeriod} to ${newSubscriptionPeriodPrefix} ${newSubscriptionPeriod} subscription. Your current subscription will end on ${currentPeriodEndDate}. Your new ${newSubscriptionPeriod} subscription will begin on ${newPeriodStartDate}, which is when your card on file will be charged. Your new subscription end date will be ${newPeriodEndDate}.\n\nWould you like to proceed?`;
    };

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch'
            }]}>

            <View style={{...ownStyles.topBar,}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{transform: [{rotate: '180deg'}], marginEnd: Spacing.base}}>
                        <IconButton icon={<ChevronIcon size={20} color={Colors.onBackground}/>}
                                    onPress={() => {
                                        navigation.navigate('Home', {screen: 'ManageProfile'})
                                    }}/>
                    </View>
                    <StandardText style={{...ownStyles.screenTitle}}>Subscription & Billing</StandardText>
                </View>
            </View>

            {subscription && (<View style={{padding: 0, alignItems: 'stretch'}}>

                <View style={{alignItems: 'stretch'}}>

                    <View style={{...ownStyles.section}}>
                        <View style={{...ownStyles.sectionContent}}>
                            <StandardText style={{...ownStyles.sectionTitle}}>Subscription details</StandardText>

                            <SubscriptionStatus subscription={subscription}/>
                        </View>
                    </View>

                    <View style={{...ownStyles.section}}>

                        <View style={{...ownStyles.sectionContent}}>

                            <StandardText style={{...ownStyles.sectionTitle}}>Pricing plan</StandardText>

                            <StandardText style={{...ownStyles.sectionDescription}}>Select the pricing plan that best
                                fits you. You can cancel any time. Save {generalAppInfo?.annualSave} with an annual subscription!</StandardText>

                            <View
                                style={{...ownStyles.plansContainer, ...(selectedSubscriptionPlanPrice ? {borderColor: Colors.onBackground} : {})}}>
                                {subscriptionPlans.length > 0 && subscriptionPlans[subscriptionPlans.length - 1].subscriptionPlanPrices.map((subscriptionPlanPrice, i) => (

                                    <TouchableOpacity key={subscriptionPlanPrice.id} onPress={() => {
                                        setSelectedSubscriptionPlanPrice(subscriptionPlanPrice)
                                    }}
                                                      style={{
                                                          ...ownStyles.planContainer,
                                                          ...(i === 0 ? ownStyles.planContainerLeft : {}),
                                                          ...(i === subscriptionPlans.length - 1 ? ownStyles.planContainerRight : {}),
                                                          ...(selectedSubscriptionPlanPrice?.id === subscriptionPlanPrice.id ? ownStyles.planContainerSelected : {})
                                                      }}>
                                        <StandardText
                                            style={{...ownStyles.price, ...(selectedSubscriptionPlanPrice?.id === subscriptionPlanPrice.id ? ownStyles.planContainerPriceSelected : {})}}>
                                            ${(subscriptionPlanPrice.price / 100)}
                                        </StandardText>

                                        <StandardText
                                            style={{...ownStyles.period, ...(selectedSubscriptionPlanPrice?.id === subscriptionPlanPrice.id ? ownStyles.planContainerPeriodSelected : {})}}>
                                            / {subscriptionPlanPrice.billingPeriod === 'month' ? 'month' : 'year'}
                                        </StandardText>
                                    </TouchableOpacity>

                                ))}

                            </View>

                        </View>
                    </View>
                </View>

                <View style={{...ownStyles.section, ...(hasPaymentMethod ? {paddingBottom: 0} : {})}}>

                    <View style={{...ownStyles.sectionContent}}>

                        <StandardText
                            style={{...ownStyles.sectionTitle}}>Credit card details</StandardText>

                        {subscription.subscriptionDetails.isPaymentMethodVisible && !changingCreditCard ? (
                            <View>
                                <StandardText style={{...ownStyles.sectionDescription}}>Below are the details of the
                                    credit card that is used to renew your subscription.</StandardText>

                                <CreditCard brand={subscription.cardBrand} last4={subscription.cardLast4}
                                            expMonth={subscription.cardExpMonth} expYear={subscription.cardExpYear}/>

                                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>

                                    <Tooltip leftOffset={10} children={<StandardText style={{fontSize: 14, color: Colors.primary}}>Is this safe?</StandardText>} content={'We use Stripe to process our credit card payments. Stripe uses industry-leading technology to keep your credit card information secure, just like they do for millions of other companies, such as Google, Amazon, and small businesses like ours. HFFE does not store, or have access to, your full credit card number; we just keep the credit card type, last four digits, country and expiration date so that we can show you what card you are using for your subscription.'} customWidth={300}/>

                                    <PlainButton label={'Change credit card'} onPress={() => {
                                        setChangingCreditCard(true)
                                    }}/>
                                </View>
                            </View>
                        ) : (
                            <View>
                                <StandardText style={{...ownStyles.sectionDescription}}>Fill the credit card details
                                    that will
                                    be used to pay for the subscription.</StandardText>

                                <View style={{
                                    borderWidth: 1,
                                    borderColor: Colors.onBackground,
                                    borderRadius: 5,
                                    overflow: 'visible',
                                    marginBottom: subscriptionInactiveOrScheduledToEnd ? Spacing.extraLarge : 0
                                }}>
                                    <View>
                                        <View style={{
                                            ...ownStyles.paymentInput,
                                            borderBottomWidth: 1,
                                        }}>
                                            <CardNumberElement options={CARD_OPTIONS} onChange={(event) => {
                                                setCardNumberError(!!event.error)
                                            }}/>
                                        </View>
                                    </View>

                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{
                                            ...ownStyles.paymentInput,
                                            flex: 1,
                                            borderRightWidth: 1,
                                        }}>
                                            <CardExpiryElement options={CARD_OPTIONS} onChange={(event) => {
                                                setCardExpirationError(!!event.error)
                                            }}/>
                                        </View>

                                        <View style={{
                                            ...ownStyles.paymentInput,
                                            flex: 1,
                                        }}>
                                            <CardCvcElement options={CARD_OPTIONS} onChange={(event) => {
                                                setCardCVCError(!!event.error)
                                            }}/>
                                        </View>
                                    </View>
                                </View>

                                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>

                                    <View style={{height: 36, justifyContent: 'center'}}>
                                        <Tooltip leftOffset={10} children={<StandardText style={{fontSize: 14, color: Colors.primary}}>Is this safe?</StandardText>} content={'We use Stripe to process our credit card payments. Stripe uses industry-leading technology to keep your credit card information secure, just like they do for millions of other companies, such as Google, Amazon, and small businesses like ours. HFFE does not store, or have access to, your full credit card number; we just keep the credit card type, last four digits, country and expiration date so that we can show you what card you are using for your subscription.'} customWidth={300}/>
                                    </View>
                                    
                                    {changingCreditCard && <View style={{alignItems: 'flex-end'}}>
                                        <PlainButton label={'Cancel'} onPress={() => {
                                            setChangingCreditCard(false)
                                        }}/>
                                    </View>}
                                </View>
                            </View>
                        )}

                    </View>
                </View>

                {selectedSubscriptionPlanPrice?.id &&
                <View style={{...ownStyles.section}}>

                    <View style={{...ownStyles.sectionContent}}>

                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{flex: 2}}>
                                <LoadingButton
                                    label={`Save`}
                                    onPress={() => {handleSavePress(true)}} loading={loadingSave} showChevron={false}
                                    backgroundColor={Colors.primary6}
                                    disabled={!enableSaveButton}/>
                            </View>

                            {(subscription.subscriptionDetails.canCancel) &&
                            <View style={{marginStart: Spacing.base, marginEnd: Spacing.end}}>
                                <StandardText style={{color: Colors.onBackground3, fontSize: 14}}>or</StandardText>
                            </View>}

                            {(subscription.subscriptionDetails.canCancel) &&
                            <View style={{flex: 1}}>
                                <PlainButton
                                    label={`Cancel Subscription`}
                                    labelColor={Colors.error}
                                    onPress={handleCancelSubscriptionPress}
                                    showChevron={false}
                                    loading={loadingCancelSubscription}
                                    disabled={false}/>
                            </View>}
                        </View>

                        {saveResult.message !== '' && (
                            <View style={{...ownStyles.resultMessageContainer}}>
                                <StandardText
                                    style={{...ownStyles.resultMessage, ...(saveResult.error ? ownStyles.resultMessageError : ownStyles.resultMessageSuccess)}}>{saveResult.message}</StandardText>
                            </View>
                        )}
                    </View>

                </View>
                }

            </View>)}

            <ConfirmationDialog title={'Confirmation'}
                                content={'Are you sure you want to cancel your subscription?'}
                                onCancelPress={() => {
                                    setShowConfirmCancelModal(false)
                                }}
                                onConfirmPress={handleConfirmCancelSubscriptionPress}
                                visible={showConfirmCancelModal}/>

            {subscription && selectedSubscriptionPlanPrice && <ConfirmationDialog title={'Confirmation'}
                                                                                  content={getSwitchPriceConfirmationText()}
                                                                                  onCancelPress={() => {
                                                                                      setShowConfirmSwitchPriceModal(false)
                                                                                  }}
                                                                                  onConfirmPress={handleConfirmSwitchPricePress}
                                                                                  visible={showConfirmSwitchPriceModal}/>}

        </SafeAreaView>
    );
};

const ownStyles = {
    topBar: {
        width: '100%',
        height: 82,
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 20,
        weight: 500,
        color: Colors.onBackground4,
    },
    section: {
        borderBottomWidth: 1,
        borderBottomColor: Colors.onBackgroundBorder2,
        padding: Spacing.large,
        paddingTop: Spacing.extraLarge,
        paddingBottom: Spacing.extraLarge,
        alignItems: 'center'
    },
    sectionContent: {
        maxWidth: 400,
        width: '100%'
    },
    sectionTitle: {
        fontSize: 18,
        weight: 500,
        color: Colors.onBackground,
        marginTop: Spacing.base,
        marginBottom: Spacing.base
    },
    sectionDescription: {
        fontSize: 14,
        lineHeight: 18,
        weight: 400,
        color: Colors.onBackgroundBorder,
        marginBottom: Spacing.large
    },
    plansContainer: {
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: Colors.onBackgroundBorder2,
        width: '100%',
        backgroundColor: Colors.background,
        overflow: 'hidden'
    },
    planContainer: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20,
        backgroundColor: Colors.background,
        borderColor: Colors.onBackgroundBorder2,
    },
    planContainerLeft: {
        borderRightWidth: 1,
        borderRightColor: Colors.onBackgroundBorder2,
    },
    planContainerRight: {},
    planContainerSelected: {
        backgroundColor: Colors.onBackground
    },
    price: {
        fontSize: 26,
        weight: 500
    },
    period: {
        marginStart: 5,
        color: Colors.blackCard
    },
    planContainerPriceSelected: {
        color: Colors.background
    },
    planContainerPeriodSelected: {
        color: Colors.onBackgroundBorder
    },
    paymentInput: {
        padding: 15,
        borderColor: Colors.onBackground,
        backgroundColor: 'transparent'
    },
    resultMessageContainer: {
        minHeight: 30,
        padding: Spacing.base,
        backgroundColor: Colors.backgroundSecondary,
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center'
    },
    resultMessage: {
        fontSize: 14,
    },
    resultMessageError: {
        color: Colors.error
    },
    resultMessageSuccess: {
        color: Colors.success4
    }
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.login.accessToken,
        userAccount: state.auth.login.userAccount,
        subscription: state.auth.profile.subscription,
    }
};

export default connect(mapStateToProps)(SubscriptionScreen);
