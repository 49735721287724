import React, {useState, useEffect} from "react";
import {View} from "react-native";
import Checkbox from "../checkbox/Checkbox";
import Colors from "../../styles/colors";
import Spacing from "../../styles/spacing";

const SelectableList = ({items, onSelectedItemsChange, defaultSelected}) => {

    defaultSelected = defaultSelected || [];
    const [itemsState, setItemsState] = useState({
        notify: false,
        items: []
    });

    useEffect(() => {
        setItemsState(sortItemsState({
            notify: true,
            items: items.map((item, i) => ({
                id: item.id,
                isSelected: (defaultSelected.find((selectedItemId) => selectedItemId === item.id)),
                label: item.label,
                sublabel: item?.sublabel,
                originalPosition: i
            }))
        }));
    }, [items]);

    useEffect(() => {
        if (onSelectedItemsChange && itemsState.notify) {
            onSelectedItemsChange(items.filter((item) =>
                itemsState.items.filter((itemState) =>
                    itemState.id === item.id && itemState.isSelected).length > 0
                )
            );
        }
    }, [itemsState.items]);

    const handleCheckboxValueChange = (id) => {
        setItemsState((previousItemsState) => {
            return {
                notify: false,
                items: previousItemsState.items.map((item) => ({
                    id: item.id,
                    isSelected: item.id === id ? !item.isSelected : item.isSelected,
                    label: item.label,
                    sublabel: item?.sublabel,
                    originalPosition: item.originalPosition
                }))
            };
        });

        setTimeout(() => {
            setItemsState((previousItemsState) => sortItemsState(previousItemsState));
        }, 0);
    };

    const sortItemsState = (itemsState) => {
        const selectedItems = itemsState.items
            .filter((item) => item.isSelected);
        const notSelectedItems = itemsState.items
            .filter((item) => !item.isSelected)
            .sort((a, b) => a.originalPosition - b.originalPosition);
        return {
            notify: true,
            items: selectedItems.concat(notSelectedItems)
        };
    };

    return (
        <View style={[ownStyles.itemsContainer, {flex: 1, flexDirection: 'column', width: '100%'}]}>
            {itemsState.items.map((item) =>
                <View key={item.id} style={{...ownStyles.item, flexDirection: 'row', ...(item.isSelected ? ownStyles.itemSelected : {})}}>
                    <View style={[ownStyles.checkboxWrapper]}>
                        <Checkbox onValueChange={() => handleCheckboxValueChange(item.id)}
                                  value={item.isSelected} label={item.label} sublabel={item.sublabel}/>
                    </View>
                </View>
            )}
        </View>
    );
};

export default SelectableList;

const ownStyles = {
    itemsContainer: {},
    item: {
        backgroundColor: Colors.background,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.onBackgroundBorder,
        marginTop: 3,
        marginBottom: 3,
        padding:8
    },
    itemSelected: {
        borderColor: Colors.primary4,
    },
    checkboxWrapper: {
        width: '100%',
    }
};
