import OAuthRedirectLoginScreen from "./OAuthRedirectLoginScreen";
import React from "react";

const GoogleRedirectLoginScreen = () => {
    return (
        <OAuthRedirectLoginScreen provider={'google'} />
    );
};

export default GoogleRedirectLoginScreen;
