import React, { useEffect, useState } from "react";
import { View } from "react-native";
import StandardText from "../standardText/StandardText";
import Colors from "../../styles/colors";

const Timer = ({ duration, onExpire, withHours = false, fontSize = 25 }) => {

    const [remainingSeconds, setRemainingSeconds] = useState(duration);
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');
    const [seconds, setSeconds] = useState('0');
    const [onExpireEmmitted, setOnExpireEmmitted] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingSeconds((v) => v - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let h = Math.abs(parseInt(remainingSeconds / 60 / 60, 10));
        let m = Math.abs(parseInt(remainingSeconds % (60 * 60) / 60, 10));
        let s = Math.abs(parseInt(remainingSeconds % 60, 10));

        setHours(h);
        setMinutes(m < 10 ? "0" + m : m);
        setSeconds(s < 10 ? "0" + s : s);

        if (remainingSeconds < 0 && onExpire && !onExpireEmmitted) {
            onExpire();
            setOnExpireEmmitted(true);
        }
    }, [remainingSeconds, onExpire, onExpireEmmitted]);

    return (
        <View>
            <StandardText style={{ color: remainingSeconds >= 0 ? Colors.onBackground : Colors.redCard, fontSize: fontSize, weight: 500 }}>
                {(withHours ? `${hours}h : ` : '') + `${minutes}m : ${seconds}s`}
            </StandardText>
        </View>
    );
};

export default Timer;
