import {logEvent, setCurrentScreen, setUserId, setUserProperties} from "expo-firebase-analytics";

export default class Analytics {

    static logEvent = async (name, properties) => {
        console.log("GA event", name, properties);
        await logEvent(name, properties);
    };

    static setCurrentScreen = async (screen) => {
        await setCurrentScreen(screen);
    };

    static setUser = async (userAccount) => {
        if (userAccount) {
            await setUserId(userAccount.id);
            await setUserProperties({
                name: `${userAccount.user.firstName} ${userAccount.user.lastName}`.trim()
            });
        } else {
            await setUserId(null);
            await setUserProperties(null);
        }
    };
};

export const EVENTS = {
    BUTTON_PRESS: 'button_press',
    GAME_CREATE: 'game_create',
    GAME_JOIN: 'game_join',
    GAME_FINISH: 'game_finish',
    PAYMENT_METHOD_CREATE: 'payment_method_create',
    TUTORIAL_SCREEN_VIEW: 'tutorial_screen_view',
    USER_SIGNUP: 'user_signup',
};
