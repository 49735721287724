import React, {useCallback, useContext, useRef, useState} from "react";
import StyleContext from "../../StyleContext";
import {SafeAreaView} from "react-native-safe-area-context";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import Colors from "../../styles/colors";
import {ScrollView, TextInput, TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Spacing from "../../styles/spacing";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import Checkbox from "../../sharedComponents/checkbox/Checkbox";
import {performLogout} from "../../redux/auth/actions/login";
import {
    clearResultMessage,
    performChangePassword,
    performEditEmail,
    performEditProfile, performEditUsername, performSetViewerSettings
} from "../../redux/auth/reducers/profileSlice";
import {format, parseISO} from "date-fns";
import CreditCard from "../../sharedComponents/creditCard/CreditCard";
import SubscriptionStatus from "../../sharedComponents/subscriptionStatus/SubscriptionStatus";
import Tooltip from "../../sharedComponents/tooltip/Tooltip";

const ManageProfileScreen = ({
                                 dispatch, userAccount,
                                 loadingEditProfile, isErrorEditProfile, resultMessageEditProfile,
                                 loadingEditEmail, isErrorEditEmail, resultMessageEditEmail, 
                                 loadingEditUsername, isErrorEditUsername, resultMessageEditUsername,
                                 loadingChangePassword, isErrorChangePassword, resultMessageChangePassword,
                                 subscription, settings
                             }) => {
    const navigation = useNavigation();
    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(true);
    const [editProfileIsErrorLocal, setEditProfileIsErrorLocal] = useState(null);
    const [editProfileResultMessageLocal, setEditProfileResultMessageLocal] = useState(null);

    const [username, setUsername] = useState('');
    const [usernamePassword, setUsernamePassword] = useState('');
    const [editUsernameIsErrorLocal, setEditUsernameIsErrorLocal] = useState(null);
    const [editUsernameResultMessageLocal, setEditUsernameResultMessageLocal] = useState(null);

    const [emailAddress, setEmailAddress] = useState('');
    const [emailAddressPassword, setEmailAddressPassword] = useState('');
    const [editEmailIsErrorLocal, setEditEmailIsErrorLocal] = useState(null);
    const [editEmailResultMessageLocal, setEditEmailResultMessageLocal] = useState(null);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordCheck, setNewPasswordCheck] = useState('');
    const [changePasswordIsErrorLocal, setChangePasswordIsErrorLocal] = useState(null);
    const [changePasswordResultMessageLocal, setChangePasswordResultMessageLocal] = useState(null);

    const [showTutorial, setShowTutorial] = useState(false);
    const [showCoachingMarks, setShowCoachingMarks] = useState(false);
    const [showRankLabels, setShowRankLabels] = useState(false);

    const scrollViewRef = useRef();


    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false);

        return () => {
            clearTextInputs();
            dispatch(clearResultMessage());
        }
    }, []));

    useFocusEffect(useCallback(() => {
        setFirstName(userAccount?.user ? userAccount.user.firstName : '');
        setLastName(userAccount?.user ? userAccount.user.lastName : '');
        setBirthYear(userAccount?.user.birthyear ?? '');

        setUsername(userAccount?.username ? userAccount.username : '');
        setEmailAddress(userAccount?.user ? userAccount.user.email : '');
        setSubscribeToNewsletter(userAccount?.user ? userAccount.user.newsletter : true);
    }, [userAccount]));

    useFocusEffect(useCallback(() => {
        setShowTutorial(settings?.showTutorial ?? false);
        setShowCoachingMarks(settings?.showCoachingMarks ?? false);
        setShowRankLabels(settings?.showRankLabels ?? false);
    }, [settings]));

    useFocusEffect(useCallback(() => {
        setEditProfileResultMessageLocal(resultMessageEditProfile);
        setEditProfileIsErrorLocal(isErrorEditProfile);
        setEditUsernameResultMessageLocal(resultMessageEditUsername);
        setEditUsernameIsErrorLocal(isErrorEditUsername);
        setEditEmailResultMessageLocal(resultMessageEditEmail);
        setEditEmailIsErrorLocal(isErrorEditEmail);
        setChangePasswordResultMessageLocal(resultMessageChangePassword);
        setChangePasswordIsErrorLocal(isErrorChangePassword);

    }, [resultMessageEditProfile, isErrorEditProfile, resultMessageEditEmail, isErrorEditEmail, resultMessageEditUsername, isErrorEditUsername, resultMessageChangePassword, isErrorChangePassword]));

    const clearTextInputs = () => {
        setFirstName('');
        setLastName('');
        setBirthYear('');
        setUsername('');
        setUsernamePassword('');
        setEmailAddress('');
        setEmailAddressPassword('');
        setCurrentPassword('');
        setNewPassword('');
        setNewPasswordCheck('');
    };


    const handleLogoutPress = () => {
        dispatch(performLogout());
    };

    const handleEditProfilePress = () => {
        if (userAccount?.user.firstName === firstName && userAccount?.user.lastName === lastName && userAccount?.user.birthyear === birthYear && userAccount?.user.newsletter === subscribeToNewsletter) {
            return;
        }

        dispatch(performEditProfile(firstName, lastName, birthYear, subscribeToNewsletter));
    };

    const handleChangePassword = () => {
        if (!currentPassword || !newPassword || !newPasswordCheck || (newPassword !== newPasswordCheck)) {
            return;
        }

        dispatch(performChangePassword(currentPassword, newPassword))
    };

    const handleSetPassword = () => {
        if (!newPassword || !newPasswordCheck || (newPassword !== newPasswordCheck)) {
            return;
        }

        dispatch(performChangePassword(null, newPassword))
        setNewPassword('');
        setNewPasswordCheck('');
    };

    const handleEditUsernamePress = () => {
        if (username === userAccount?.username || (userAccount?.hasPassword && !usernamePassword)) {
            return;
        }

        dispatch(performEditUsername(emailAddress, username, usernamePassword, userAccount.user.firstName, userAccount.user.lastName, userAccount.user.birthdate, userAccount.user.newsletter))
    };
    
    const handleEditEmailPress = () => {
        if (emailAddress === userAccount?.user.email || (userAccount?.hasPassword && !emailAddressPassword)) {
            return;
        }

        dispatch(performEditEmail(emailAddress, username, emailAddressPassword, userAccount.user.firstName, userAccount.user.lastName, userAccount.user.birthdate, userAccount.user.newsletter))
    };

    const handleShowTutorialValueChange = () => {
        const newValue = !showTutorial;
        setShowTutorial(newValue);
        dispatch(performSetViewerSettings({showTutorial: newValue}));
    };

    const handleShowCoachingMarksValueChange = () => {
        const newValue = !showCoachingMarks;
        setShowCoachingMarks(newValue);
        dispatch(performSetViewerSettings({showCoachingMarks: newValue}));
    };

    const handleShowRankLabelsValueChange = () => {
        const newValue = !showRankLabels;
        setShowRankLabels(newValue);
        dispatch(performSetViewerSettings({showRankLabels: newValue}));
    };

    const handleCreatePasswordNow = () => {
        scrollViewRef.current.scrollTo({
            animated: true,
            y: 900
        })
    };

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch'
            }]}>

            <View style={{...ownStyles.topBar,}}>
                <View>
                    <StandardText style={{...ownStyles.screenTitle}}>Profile</StandardText>
                </View>
            </View>

            <ScrollView ref={scrollViewRef} contentContainerStyle={{alignItems: 'stretch'}} style={{padding: 0}}>
                <View style={{...ownStyles.section}}>

                    <View style={{...ownStyles.sectionContent}}>

                        {userAccount?.hasPassword === false && userAccount?.hasGoogleLogin === false && userAccount?.hasFacebookLogin === false &&
                        <View style={{...ownStyles.warningBox, marginBottom: Spacing.large}}>
                            <StandardText style={{...ownStyles.warningBoxText}}>
                                It looks like you do not yet have a password. Please{` `}
                                <TouchableOpacity onPress={handleCreatePasswordNow}><StandardText style={{color: Colors.primary4, fontSize: 14}}>create one now</StandardText></TouchableOpacity>
                                {` `}so you can
                                log in to HFFE in the future.
                            </StandardText>
                        </View>
                        }

                        <StandardText style={{...ownStyles.sectionTitle}}>Edit profile</StandardText>

                        <StandardText style={{...ownStyles.sectionDescription}}>Update your personal details here. All
                            information is treated consistent with our Privacy Policy. The first initial of your first
                            name is used to identify you when playing a game. Your birth year (optional) is used to
                            group together game statistics with other players of a similar age.</StandardText>

                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                   value={firstName}
                                   placeholder={'First Name *'}
                                   onChangeText={(text => {
                                       setFirstName(text)
                                   })} onSubmitEditing={handleEditProfilePress}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>

                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                   value={lastName}
                                   placeholder={'Last Name'}
                                   onChangeText={(text => {
                                       setLastName(text)
                                   })} onSubmitEditing={handleEditProfilePress}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>

                        <TextInput style={[styleContext.input, {flex: 1}]}
                                   placeholder={'Birth year (YYYY)'} onChangeText={(text) => {
                            setBirthYear(text)
                        }}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'} value={birthYear}/>

                        <View style={{marginTop: Spacing.base, marginBottom: Spacing.base, marginStart: -5}}>
                            <Checkbox
                                label={'Subscribe to newsletter about new game features and the Hand & Foot Friends & Family Edition member community'}
                                value={subscribeToNewsletter}
                                onValueChange={() => {
                                    setSubscribeToNewsletter((v => !v))
                                }}/>
                        </View>

                        <View style={{
                            ...ownStyles.resultMessageContainer,
                            opacity: editProfileResultMessageLocal ? 1 : 0
                        }}>
                            <StandardText style={{
                                ...ownStyles.resultMessage,
                                color: editProfileIsErrorLocal ? Colors.error : Colors.success4
                            }}>{editProfileResultMessageLocal}</StandardText>
                        </View>

                        <LoadingButton
                            label={'Save changes'}
                            backgroundColor={Colors.primary6}
                            padding={Spacing.base}
                            loading={loadingEditProfile}
                            showChevron={false}
                            disabled={!firstName || (userAccount?.user.firstName === firstName && userAccount?.user.lastName === lastName && userAccount?.user.birthyear === birthYear && userAccount?.user.newsletter === subscribeToNewsletter)}
                            onPress={handleEditProfilePress}/>
                    </View>


                </View>

                <View style={{...ownStyles.section}}>
                    <View style={{...ownStyles.sectionContent}}>

                        <StandardText style={{...ownStyles.sectionTitle}}>Change username</StandardText>

                        <StandardText style={{...ownStyles.sectionDescription}}>
                            <StandardText style={{...ownStyles.sectionDescription}}>Your username can be used to log in and for new game invitations. Your first name and username will be visible to other HFFE players. You can share your username with others so they can invite you to a game without you having to share your email address with them. By default, your username is made up of your first name and player number, but you can change it as long as 1) no other player already has that username and 2) the content of the username is not offensive, insensitive, or creepy.
                            </StandardText>
                        </StandardText>

                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                   value={username}
                                   placeholder={'Username'}
                                   onChangeText={(text => {
                                       setUsername(text)
                                   })} onSubmitEditing={handleEditUsernamePress}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>

                        {userAccount?.hasPassword &&
                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                   value={usernamePassword}
                                   placeholder={'Current password'}
                                   onChangeText={(text => {
                                       setUsernamePassword(text)
                                   })} onSubmitEditing={handleEditUsernamePress}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                   secureTextEntry/>}

                        <View
                            style={{...ownStyles.resultMessageContainer, opacity: editUsernameResultMessageLocal ? 1 : 0}}>
                            <StandardText style={{
                                ...ownStyles.resultMessage,
                                color: editUsernameIsErrorLocal ? Colors.error : Colors.success4
                            }}>{editUsernameResultMessageLocal}</StandardText>
                        </View>

                        <LoadingButton
                            label={'Change username'}
                            backgroundColor={Colors.primary6}
                            padding={Spacing.base}
                            loading={loadingEditUsername}
                            showChevron={false}
                            disabled={username === '' || username === userAccount?.username || (userAccount?.hasPassword && !usernamePassword)}
                            onPress={handleEditUsernamePress}/>
                    </View>
                </View>

                <View style={{...ownStyles.section}}>
                    <View style={{...ownStyles.sectionContent}}>

                        <StandardText style={{...ownStyles.sectionTitle}}>Change email</StandardText>

                        <StandardText style={{...ownStyles.sectionDescription}}>
                            <StandardText style={{...ownStyles.sectionDescription}}>To change your email
                                from </StandardText>
                            <StandardText style={{
                                ...ownStyles.sectionDescription,
                                weight: 500,
                                color: Colors.onBackground3
                            }}>{userAccount?.user.email}</StandardText>
                            <StandardText style={{...ownStyles.sectionDescription}}>, enter the new email
                                address{userAccount?.hasPassword ? ' and your current password' : ''}. You would then
                                use the
                                new email address
                                to{!userAccount?.hasFacebookLogin && !userAccount?.hasGoogleLogin ? ` log in and` : ``} receive
                                new game invitations.
                            </StandardText>
                        </StandardText>

                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                   value={emailAddress}
                                   placeholder={'Email address'}
                                   onChangeText={(text => {
                                       setEmailAddress(text)
                                   })} onSubmitEditing={handleEditEmailPress}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>

                        {userAccount?.hasPassword &&
                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                   value={emailAddressPassword}
                                   placeholder={'Current password'}
                                   onChangeText={(text => {
                                       setEmailAddressPassword(text)
                                   })} onSubmitEditing={handleEditEmailPress}
                                   placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                   secureTextEntry/>}

                        <View
                            style={{...ownStyles.resultMessageContainer, opacity: editEmailResultMessageLocal ? 1 : 0}}>
                            <StandardText style={{
                                ...ownStyles.resultMessage,
                                color: editEmailIsErrorLocal ? Colors.error : Colors.success4
                            }}>{editEmailResultMessageLocal}</StandardText>
                        </View>

                        <LoadingButton
                            label={'Change email'}
                            backgroundColor={Colors.primary6}
                            padding={Spacing.base}
                            loading={loadingEditEmail}
                            showChevron={false}
                            disabled={emailAddress === userAccount?.user.email || (userAccount?.hasPassword && !emailAddressPassword)}
                            onPress={handleEditEmailPress}/>
                    </View>
                </View>

                {userAccount?.hasPassword !== undefined && userAccount?.hasGoogleLogin === false && userAccount?.hasFacebookLogin === false && (
                    <View style={{...ownStyles.section}}>
                        <View style={{...ownStyles.sectionContent}}>

                            <StandardText style={{...ownStyles.sectionTitle}}>Change password</StandardText>

                            <StandardText style={{...ownStyles.sectionDescription}}>
                                {userAccount?.hasPassword ? 
                                    `Change your login password by entering your current password and then your new password.` :
                                    `Change your login password by entering your new password.`
                                }
                            </StandardText>

                            {userAccount?.hasPassword &&
                            <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                       value={currentPassword}
                                       placeholder={'Current password'}
                                       onChangeText={(text => {
                                           setCurrentPassword(text)
                                       })} onSubmitEditing={handleChangePassword}
                                       placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                       secureTextEntry/>
                            }


                            <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                       value={newPassword}
                                       placeholder={'New password'}
                                       onChangeText={(text => {
                                           setNewPassword(text)
                                       })} onSubmitEditing={userAccount?.hasPassword ? handleChangePassword : handleSetPassword}
                                       placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                       secureTextEntry/>

                            <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                       value={newPasswordCheck}
                                       placeholder={'Repeat new password'}
                                       onChangeText={(text => {
                                           setNewPasswordCheck(text)
                                       })} onSubmitEditing={userAccount?.hasPassword ? handleChangePassword : handleSetPassword}
                                       placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                       secureTextEntry/>

                            <View style={{
                                ...ownStyles.resultMessageContainer,
                                opacity: changePasswordResultMessageLocal ? 1 : 0
                            }}>
                                <StandardText style={{
                                    ...ownStyles.resultMessage,
                                    color: changePasswordIsErrorLocal ? Colors.error : Colors.success4
                                }}>{changePasswordResultMessageLocal}</StandardText>
                            </View>

                            <LoadingButton
                                label={'Change password'}
                                backgroundColor={Colors.primary6}
                                padding={Spacing.base}
                                loading={loadingChangePassword}
                                showChevron={false}
                                disabled={(userAccount?.hasPassword && !currentPassword) || !newPassword || !newPasswordCheck || (newPassword !== newPasswordCheck)}
                                onPress={userAccount?.hasPassword ? handleChangePassword : handleSetPassword}/>
                        </View>
                    </View>
                )}

                {(userAccount?.hasFacebookLogin || userAccount?.hasGoogleLogin) && (
                    <View style={{...ownStyles.section}}>
                        <View style={{...ownStyles.sectionContent}}>

                            <StandardText style={{...ownStyles.sectionTitle}}>Login</StandardText>

                            <StandardText style={{...ownStyles.sectionDescription}}>
                                <StandardText style={{...ownStyles.sectionDescription}}>You are currently
                                    using </StandardText>
                                <StandardText style={{
                                    ...ownStyles.sectionDescription,
                                    weight: 500,
                                    color: Colors.onBackground3
                                }}>{userAccount?.hasFacebookLogin ? `Facebook` : `Google`}</StandardText>
                                <StandardText style={{...ownStyles.sectionDescription}}> to log in.</StandardText>
                            </StandardText>


                        </View>
                    </View>
                )}

                {(subscription) && (
                    <View style={{...ownStyles.section}}>
                        <View style={{...ownStyles.sectionContent}}>

                            <StandardText style={{...ownStyles.sectionTitle}}>Subscription details</StandardText>

                            <SubscriptionStatus subscription={subscription} displayLinkInDescription={true} onLinkInDescriptionPress={() => {
                                navigation.navigate('Subscription')
                            }} />

                            {subscription.subscriptionDetails.isPaymentMethodVisible && (
                                <View>
                                    <StandardText style={{marginTop: 15, marginBottom: Spacing.base}}>Credit card
                                        details</StandardText>

                                    <View style={{marginBottom: Spacing.large}}>
                                        <CreditCard brand={subscription.cardBrand} last4={subscription.cardLast4}
                                                    expMonth={subscription.cardExpMonth}
                                                    expYear={subscription.cardExpYear}/>
                                    </View>
                                </View>
                            )}

                            {subscription.subscriptionDetails.isPaymentMethodVisible && (
                                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    
                                    <View style={{marginTop: Spacing.small, cursor: 'pointer'}}>
                                        <Tooltip leftOffset={10} children={<StandardText style={{fontSize: 14, color: Colors.primary}}>Is this safe?</StandardText>} content={'We use Stripe to process our credit card payments. Stripe uses industry-leading technology to keep your credit card information secure, just like they do for millions of other companies, such as Google, Amazon, and small businesses like ours. HFFE does not store, or have access to, your full credit card number; we just keep the credit card type, last four digits, country and expiration date so that we can show you what card you are using for your subscription.'} customWidth={300}/>
                                    </View>

                                    <TouchableOpacity style={{marginTop: Spacing.small}} onPress={() => {
                                        navigation.navigate('Subscription')
                                    }}>
                                        <StandardText style={{fontSize: 14, color: Colors.primary}}>Edit
                                            subscription</StandardText>
                                    </TouchableOpacity>
                                </View>
                            )}

                            
                        </View>
                    </View>
                )}

                <View style={{...ownStyles.section}}>
                    <View style={{...ownStyles.sectionContent}}>

                        <StandardText style={{...ownStyles.sectionTitle}}>Game Settings</StandardText>

                        <StandardText style={{...ownStyles.sectionDescription}}>
                            Update your settings here that affect how you play Hand & Foot Friends & Family Edition.
                        </StandardText>

                        <View style={{marginTop: Spacing.base, marginBottom: Spacing.base, marginStart: -5}}>
                            <Checkbox
                                label={'Show Welcome screens immediately on login'}
                                value={showTutorial}
                                onValueChange={handleShowTutorialValueChange}/>

                            <StandardText style={{marginStart: 35, color: Colors.onBackgroundBorder, fontSize: 14, lineHeight: 20,}}>To reset all Welcome screens you have already seen to show again, de-select this box and then select it again.</StandardText>

                        </View>

                        <View style={{marginTop: Spacing.base, marginBottom: Spacing.base, marginStart: -5}}>
                            <Checkbox
                                label={'Enable coaching tips'}
                                value={showCoachingMarks}
                                onValueChange={handleShowCoachingMarksValueChange}/>

                                <StandardText style={{marginStart: 35, color: Colors.onBackgroundBorder, fontSize: 14, lineHeight: 20,}}>To reset all coaching tips you have already seen to show again, de-select this box and then select it again.</StandardText>
                        </View>

                        <View style={{marginTop: Spacing.base, marginBottom: Spacing.base, marginStart: -5}}>
                            <Checkbox
                                label={'Show rank labels on meld placeholders'}
                                value={showRankLabels}
                                onValueChange={handleShowRankLabelsValueChange}/>

                            <StandardText style={{marginStart: 35, color: Colors.onBackgroundBorder, fontSize: 14, lineHeight: 20,}}>Show "Aces", "Kings", "Queens" etc. to make it easier to see where to meld your cards.</StandardText>
                        </View>
                    </View>
                </View>

                <View style={{...ownStyles.section, borderBottomWidth: 0}}>
                    <TouchableOpacity onPress={handleLogoutPress}>
                        <StandardText style={{color: Colors.primary}}>Logout</StandardText>
                    </TouchableOpacity>
                </View>

            </ScrollView>

        </SafeAreaView>
    );
};

const ownStyles = {
    topBar: {
        width: '100%',
        height: 82,
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 20,
        weight: 500,
        color: Colors.onBackground4,
    },
    section: {
        borderBottomWidth: 1,
        borderBottomColor: Colors.onBackgroundBorder2,
        padding: Spacing.large,
        paddingTop: Spacing.large,
        paddingBottom: Spacing.large,
        alignItems: 'center'
    },
    sectionTitle: {
        fontSize: 18,
        weight: 500,
        color: Colors.onBackground,
        marginTop: Spacing.base,
        marginBottom: Spacing.base
    },
    sectionDescription: {
        fontSize: 14,
        lineHeight: 20,
        weight: 400,
        color: Colors.onBackgroundBorder,
        marginBottom: Spacing.large
    },
    sectionContent: {
        maxWidth: 400,
        width: '100%'
    },
    resultMessageContainer: {
        minHeight: 30,
        padding: Spacing.base,
        backgroundColor: Colors.backgroundSecondary,
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center'
    },
    resultMessage: {
        fontSize: 14,
    },
    warningBox: {
        padding: Spacing.base,
        backgroundColor: Colors.warning2
    },
    warningBoxText: {
        color: Colors.warning3,
        fontSize: 14
    }
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        loadingEditProfile: state.auth.profile.loadingEditProfile,
        resultMessageEditProfile: state.auth.profile.resultMessageEditProfile,
        isErrorEditProfile: state.auth.profile.isErrorEditProfile,
        loadingEditEmail: state.auth.profile.loadingEditEmail,
        resultMessageEditEmail: state.auth.profile.resultMessageEditEmail,
        isErrorEditEmail: state.auth.profile.isErrorEditEmail,
        loadingEditUsername: state.auth.profile.loadingEditUsername,
        resultMessageEditUsername: state.auth.profile.resultMessageEditUsername,
        isErrorEditUsername: state.auth.profile.isErrorEditUsername,
        loadingChangePassword: state.auth.profile.loadingChangePassword,
        resultMessageChangePassword: state.auth.profile.resultMessageChangePassword,
        isErrorChangePassword: state.auth.profile.isErrorChangePassword,
        subscription: state.auth.profile.subscription,
        settings: state.auth.profile.settings
    }
};

export default connect(mapStateToProps)(ManageProfileScreen);
