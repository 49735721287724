import React, {useCallback, useContext, useState} from "react";
import {TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import StyleContext from "../../StyleContext";
import {SafeAreaView} from "react-native-safe-area-context";
import {MaterialIcons} from "@expo/vector-icons";
import {connect} from "react-redux";
import {useFocusEffect} from "@react-navigation/core";
import Picker from "react-native-web/dist/exports/Picker";
import Colors from "../../colors";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {fetchGameConfigs, performSaveGameConfig} from "../../redux/auth/actions/config";
import {ActivityIndicator} from "react-native";
import {LoadingContext} from "../../context/LoadingContext";

const GameOptionsScreen = ({navigation, dispatch, userAccount, gameConfigs, isSavingGameConfigs}) => {

    const defaultNumberOfCardsToDeal = 11;
    const availableNumberOfCardsToDeal = [11, 13, 15];
    const [currentGameConfig, setCurrentGameConfig] = useState(null);
    const [numberOfCardsToDeal, setNumberOfCardsToDeal] = useState(null);
    const [startNewGame, setStartNewGame] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(null);
    const loadingContext = useContext(LoadingContext);

    useFocusEffect(useCallback(() => {
        if (userAccount) {
            dispatch(fetchGameConfigs());
        }
    }, [userAccount]));

    useFocusEffect(useCallback(() => {
        if (gameConfigs) {
            if (gameConfigs.length > 0) {
                setCurrentGameConfig(gameConfigs[0]);
                setNumberOfCardsToDeal(gameConfigs[0].numberOfCardsToDeal);
            } else {
                setNumberOfCardsToDeal(defaultNumberOfCardsToDeal);
            }
            loadingContext.setScreenLoading(false);
        }
    }, [gameConfigs]));

    useFocusEffect(useCallback(() => {
        if (!isSavingGameConfigs && startNewGame === true) {
            setStartNewGame(false);
            navigation.navigate('NewGame');
        }
    }, [isSavingGameConfigs, startNewGame]));

    const handleSaveGameOptionsClick = () => {
        setButtonClicked('save');
        dispatch(performSaveGameConfig(currentGameConfig?.id ?? null, numberOfCardsToDeal));
    };

    const handleStartGameWithOptionsClick = () => {
        setButtonClicked('startGame');
        setStartNewGame(true);
        dispatch(performSaveGameConfig(currentGameConfig?.id ?? null, numberOfCardsToDeal));
    };

    return (
        <StyleContext.Consumer>
            {styles => (
                <SafeAreaView style={[styles.safeArea, styles.container, {flexDirection: 'column'}]}>
                    <View style={{position: 'absolute', top: 20, left: 20, width: 24, height: 24}}>
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('Home')
                        }}>
                            <MaterialIcons name="home" size={24} color={'#999999'}/>
                        </TouchableOpacity>
                    </View>
                    {gameConfigs ? (
                    <View style={{alignItems: 'center'}}>
                        <View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Number of Cards Dealt:</StandardText>
                                <Picker selectedValue={numberOfCardsToDeal ?? ''} onValueChange={(value) => {
                                    setNumberOfCardsToDeal(value)
                                }}>
                                    {availableNumberOfCardsToDeal.map((option) => (
                                        <Picker.Item key={option} label={option} value={option} />
                                    ))}
                                </Picker>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Number of Decks:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>5</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Number of Cards When Picking
                                    Up:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>6</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Wild Cards Allowed in Initial
                                    Meld?:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>Yes</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Number of Wild Cards in
                                    Melds:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>3</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Cards Can Be Added to Completed
                                    Canastas:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>Yes</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Red (Natural) Canastas Needed To Go
                                    Out:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>3</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Black (Unnatural) Canastas Needed To Go
                                    Out:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>4</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Allow Black 3s to be Played When Going
                                    Out?:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>Yes</StandardText>
                            </View>
                            <View style={ownStyles.optionRow}>
                                <StandardText style={ownStyles.optionRowText}>Require Partner's Permission to Go
                                    Out?:</StandardText>
                                <StandardText style={ownStyles.optionRowText}>Yes</StandardText>
                            </View>
                        </View>
                        <View style={ownStyles.buttonsContainer}>
                            <View style={{flex: 1, justifyContent: 'stretch'}}>
                                <LoadingButton label={'Save Game Options'}
                                               backgroundColor={Colors.background}
                                               labelColor={Colors.primary}
                                               borderColor={Colors.primary}
                                               loadingColor={Colors.primary}
                                               disabled={false}
                                               padding={5}
                                               loading={buttonClicked === 'save' && isSavingGameConfigs}
                                               onPress={handleSaveGameOptionsClick}
                                />
                            </View>
                            <View style={{flex: 1}}>
                                <LoadingButton label={'Start New Game With These Options'}
                                               backgroundColor={Colors.background}
                                               labelColor={Colors.primary}
                                               borderColor={Colors.primary}
                                               loadingColor={Colors.primary}
                                               disabled={false}
                                               padding={5}
                                               loading={buttonClicked === 'startGame' && isSavingGameConfigs}
                                               onPress={handleStartGameWithOptionsClick}
                                />
                            </View>
                        </View>
                        <View style={{width: 350}}>
                            <StandardText style={{fontSize: 12}}>NOTE: Changes saved here will not affect games currently in progress.</StandardText>
                        </View>
                    </View>
                    ) : (
                        <ActivityIndicator animating={true} />
                    )}
                </SafeAreaView>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => ({
    userAccount: state.auth.login.userAccount,
    gameConfigs: state.auth.config.gameConfigs,
    isSavingGameConfigs: state.auth.config.isSavingGameConfigs
});

export default connect(mapStateToProps)(GameOptionsScreen);

const ownStyles = {
    optionRow: {
        flexDirection: 'row'
    },
    optionRowText: {
        color: Colors.primary,
        marginRight: 10
    },
    buttonsContainer: {
        flexDirection: 'row',
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: 400,
    }
};
