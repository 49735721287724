import React, {useEffect, useState} from 'react';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import rootReducer from "./src/redux";
import RootContainer from "./RootContainer";
import {SafeAreaProvider} from "react-native-safe-area-context";
import Modal from "modal-react-native-web";
import {Roboto_400Regular, Roboto_500Medium, Roboto_700Bold, Roboto_900Black, useFonts} from "@expo-google-fonts/roboto";
import SplashScreen from "./src/screens/SplashScreen";
import {LoadingContext} from "./src/context/LoadingContext";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {performGetViewerSubscription} from "./src/redux/auth/reducers/profileSlice";
import {STRIPE_PUBLISHABLE_KEY} from "./src/env.json";
import {ScreenSizeContext} from "./src/context/ScreenSizeContext";
import {useWindowDimensions} from "react-native-web";
import {MenuProvider} from 'react-native-popup-menu';

const checkSubscription = store => next => action => {
    let result = next(action)
    if (!action.payload?.skipSubscriptionMiddleware) {
        store.dispatch(performGetViewerSubscription());
    }

    return result
}

// https://www.npmjs.com/package/@expo/webpack-config#fully-disabling-the-service-worker
const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware, // lets us dispatch() functions
        checkSubscription
    )
);

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const App = () => {

    const [isLoadingValue, setIsLoadingValue] = useState({
        globalLoading: true,
        screenLoading: true,
        setGlobalLoading: (v) => setIsLoadingValue((prev) => {
            return {...prev, globalLoading: v, isLoading: v || prev.screenLoading};
        }),
        setScreenLoading: (v) => setIsLoadingValue((prev) => {
            return {...prev, screenLoading: v, isLoading: v || prev.globalLoading};
        }),
        isLoading: true
    });

    const windowDimensions = useWindowDimensions();
    const [screenSizeValue, setScreenSizeValue] = useState({isMobile: false, isRetina: false});
    const [splashTimedout, setSplashTimedout] = useState(false);

    let [fontsLoaded] = useFonts({
        Roboto_400Regular,
        Roboto_500Medium,
        Roboto_700Bold,
        Roboto_900Black
    });

    useEffect(() => {
        Modal.setAppElement('body');
        setSplashTimedout(true);
    }, []);

    useEffect(() => {
        setScreenSizeValue({isMobile: windowDimensions.width <= 900, isRetina: windowDimensions.scale === 2});
    }, [windowDimensions]);

    useEffect(() => {
        isLoadingValue.setGlobalLoading(!fontsLoaded || !splashTimedout);
    }, [fontsLoaded, splashTimedout])

    return (
        <Elements stripe={stripePromise}>
            <Provider store={store}>
                <SafeAreaProvider>
                    <ScreenSizeContext.Provider value={screenSizeValue}>
                        <MenuProvider>
                            <LoadingContext.Provider value={isLoadingValue}>
                                {(isLoadingValue.isLoading) &&
                                <SplashScreen/>
                                }
                                <RootContainer/>
                            </LoadingContext.Provider>
                        </MenuProvider>
                    </ScreenSizeContext.Provider>
                </SafeAreaProvider>
            </Provider>
        </Elements>
    )
}

export default App;
