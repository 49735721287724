import {Keyboard, TextInput, View} from "react-native";
import React, {useCallback, useContext, useState} from "react";
import StyleContext from "../../StyleContext";
import LogoLarge from "../../icons/LogoLarge";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import {connect} from "react-redux";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {performResetPassword} from "../../redux/auth/actions/login";
import {useNavigation} from "@react-navigation/native";
import PlainButton from "../../sharedComponents/plainButton/PlainButton";

const RequestChangePasswordScreen = ({
                                         dispatch,
                                         route,
                                         userAccount,
                                         localLoginFailed,
                                         loadingChangePassword,
                                         changePasswordError
                                     }) => {

    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);
    const navigation = useNavigation();

    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [localError, setLocalError] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);

    useFocusEffect(useCallback(() => {
        if (!userAccount && localLoginFailed) {
            loadingContext.setScreenLoading(false);
        }
    }, [userAccount, localLoginFailed]));

    useFocusEffect(
        useCallback(() => {
            if (!route.params.token) {
                navigation.navigate('Start');
            } else {
                setToken(route.params.token);
            }
        }, [dispatch, route])
    );

    useFocusEffect(useCallback(() => {
        if (passwordChanged && !loadingChangePassword) {
            setPassword('');
            setPasswordCheck('');
        }
    }, [loadingChangePassword, passwordChanged]));

    const handleSubmit = async () => {
        Keyboard.dismiss();

        if (!token || !password || password !== passwordCheck) {
            setPasswordChanged(false);
            return;
        }

        setLocalError('');
        setPasswordChanged(true);
        dispatch(performResetPassword(token, password));
    };

    return (
        (!loadingContext.isLoading) &&
        <View style={styleContext.safeArea}>
            <View style={{width: '100%', flex: 1, alignItems: 'center', justifyContent: 'center'}}>

                <View style={{...ownStyles.container}}>

                    <View style={{marginTop: -15}}>
                        <LogoLarge size={198}/>
                    </View>
                    <StandardText style={{...styleContext.subheader, color: Colors.onBackground3, marginTop: 10}}>When
                        you play,
                        you're family™</StandardText>

                    <StandardText style={{...ownStyles.buttonsTitle, color: Colors.onBackground4}}>
                        {passwordChanged && !loadingChangePassword ? 'Password changed' : 'Change your password'}
                    </StandardText>

                    <View style={{...ownStyles.buttonsContainer}}>

                        {(!passwordChanged || loadingChangePassword) &&
                        <View>
                            <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                       value={password}
                                       placeholder={'Password'}
                                       onChangeText={(text => {
                                           setPassword(text)
                                       })} onSubmitEditing={handleSubmit}
                                       placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                       secureTextEntry={true}/>

                            <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                       value={passwordCheck}
                                       placeholder={'Repeat Password'}
                                       onChangeText={(text => {
                                           setPasswordCheck(text)
                                       })} onSubmitEditing={handleSubmit}
                                       placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                       secureTextEntry={true}/>

                            <LoadingButton label={'Change Password'} onPress={handleSubmit}
                                           loading={loadingChangePassword}
                                           disabled={!token || !password || password !== passwordCheck}/>
                        </View>
                        }

                        <View style={{height: 20, justifyContent: 'center'}}>
                            {(localError || changePasswordError) && <StandardText
                                style={{...ownStyles.errorMessage}}>{localError ? localError : changePasswordError}</StandardText>}
                        </View>

                        <View style={{height: 20, justifyContent: 'center'}}>
                            {passwordChanged && !loadingChangePassword && (
                                <View>
                                    <StandardText style={{...ownStyles.resultMessage}}>Your password has been updated
                                        successfully.
                                    </StandardText>
                                    <PlainButton fontSize={14} onPress={() => navigation.navigate('Start')} label={'Go to Log In'}/>
                                </View>
                            )}
                        </View>

                    </View>

                </View>

            </View>
        </View>
    );
};

const ownStyles = {
    container: {
        width: 300
    },
    buttonsTitle: {
        fontSize: 24,
        weight: 500,
        marginTop: 35,
        marginBottom: 10
    },
    buttonsContainer: {},
    bottomTextContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    bottomText: {
        fontSize: 14,
        color: Colors.onBackground
    },
    errorMessage: {
        color: Colors.redCard,
        fontSize: 14,
        textAlign: 'center',
    },
    resultMessage: {
        fontSize: 14,
        color: Colors.onBackground
    }
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null,
        localLoginFailed: state.auth.login.localLoginFailed,
        loadingChangePassword: state.auth.login.isChangingPassword,
        changePasswordError: state.auth.login.changePasswordError,
    }
};

export default connect(mapStateToProps)(RequestChangePasswordScreen);
