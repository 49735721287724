import {createSlice} from "@reduxjs/toolkit";
import Api from "../../services/Api";

const computerPlayerSlice = createSlice({
    name: 'computerPlayer',
    initialState: {
        computerPlayerPersonas: [],
        loading: false,
        error: null
    },
    reducers: {
        requestComputerPlayerPersonas: {
            reducer(state) {
                state.error = null;
            }
        },
        receiveComputerPlayerPersonas: {
            reducer(state, action) {
                state.computerPlayerPersonas = action.payload.computerPlayerPersonas;
                state.loading = false;
                state.error = null;
            },
            prepare(computerPlayerPersonas) {
                return { payload: { computerPlayerPersonas } };
            }
        },
        receiveFailedComputerPlayerPersonas: {
            reducer(state, action) {
                state.computerPlayerPersonas = [];
                state.loading = false;
                state.error = action.payload.error;
            },
            prepare(error) {
                return { payload: { error } };
            }
        },
    }
});

export const {
    requestComputerPlayerPersonas,
    receiveComputerPlayerPersonas,
    receiveFailedComputerPlayerPersonas,
} = computerPlayerSlice.actions;

export default computerPlayerSlice.reducer;

export const fetchComputerPlayerPersonas = () => async (dispatch, getState) => {
    dispatch(requestComputerPlayerPersonas());

    const state = getState();
    try {
        const computerPlayerPersonas = await Api.computerPlayerPersonas(state.auth.login.accessToken);
        dispatch(receiveComputerPlayerPersonas(computerPlayerPersonas));
    } catch (e) {
        dispatch(receiveFailedComputerPlayerPersonas(e.message));
    }
};
