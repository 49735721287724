import React from 'react';
import { Svg, Path, Rect } from 'react-native-svg';

const FootIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 18 18" fill="none">
            <Rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="white" stroke={color} />
            <Path d="M5 13.9264V5.6543H8.86029V9.51459H8.96324C11.3998 9.51459 13.375 11.4898 13.375 13.9264H5Z" fill={color} />
            <Path d="M5 3.44922H8.86029V4.55216H5V3.44922Z" fill={color} />
        </Svg>
    );
};

export default FootIcon;