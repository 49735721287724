import * as React from 'react';
import {Svg, Path, Ellipse, Circle, Rect} from "react-native-svg";

const SpadesIcon = ({size, color}) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" >
            <Path d="M10 12.5L10 17.8125" stroke={color} strokeWidth="2"/>
            <Ellipse cx="13.75" cy="12.5" rx="3.75" ry="3.75" transform="rotate(180 13.75 12.5)" fill={color}/>
            <Rect x="8.33325" y="9.16699" width="3.33333" height="3.33333" fill={color}/>
            <Ellipse cx="6.25" cy="12.5" rx="3.75" ry="3.75" transform="rotate(180 6.25 12.5)" fill={color}/>
            <Path d="M10.7385 3.62132L16.5625 10L3.4375 10L9.26151 3.62132C9.65806 3.18701 10.3419 3.18701 10.7385 3.62132Z" fill={color}/>
        </Svg>
    );
};

export default SpadesIcon;
