import { format, utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import LogoNoText from "../../icons/LogoNoText";
import OptionPicker from "../../sharedComponents/optionPicker/OptionPicker";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";

const GameStatisticsBoards = ({ loading, rows, solo, onSoloChanged, byPlayers, onByPlayersChanged, canastasRequired, onCanastasRequiredChanged }) => {

    const showByPlayers = byPlayers !== 1;
    const headers = [{ name: 'displayName', label: 'METRIC' }, { name: 'value', label: 'YOU' }, { name: 'percentile', label: 'BETTER THAN' }];
    const [byPlayersData, setByPlayersData] = useState(null);

    useEffect(() => {
        if (!showByPlayers) {
            setByPlayersData(null);
        } else if (rows?.length > 0) {
            const metrics = rows[0].metrics.map((m) => ({ codeName: m.codeName, displayName: m.displayName }));
            const data = transformRows(metrics, rows, byPlayers === 3);
            console.log(data);
            setByPlayersData(data);
        } else {
            setByPlayersData(null);
        }
    }, [rows]);

    const transformRows = (metrics, rows, selectPartner) => {
        const transformedData = [];
        metrics.forEach((metric) => {
            const metricRow = { metric: metric, rows: [] };
            rows.forEach((row) => {
                const metricValue = row.metrics.find(m => m.codeName == metric.codeName)?.value;

                let teamValue = '';
                if (selectPartner) {
                    teamValue = 'You &\n' + (row.dimensions.find(d => d.codeName == 'partner_player_id')?.value);
                } else {
                     // if the team has two players
                    if (row.dimensions.find(d => d.codeName === 'opponent_two_player_id')?.value) {
                        // if player one is computer and player two is human we swap them
                        if (!row.dimensions.find(d => d.codeName === 'opponent_two_player_id')?.value?.endsWith(' (Computer)') && row.dimensions.find(d => d.codeName === 'opponent_one_player_id')?.value?.endsWith(' (Computer)')) {
                            teamValue = row.dimensions.find(d => d.codeName == 'opponent_two_player_id')?.value + ' &\n' + row.dimensions.find(d => d.codeName == 'opponent_one_player_id')?.value;
                        } else {
                            teamValue = row.dimensions.find(d => d.codeName == 'opponent_one_player_id')?.value + ' &\n' + row.dimensions.find(d => d.codeName == 'opponent_two_player_id')?.value;
                        }
                    } else {
                        teamValue = row.dimensions.find(d => d.codeName == 'opponent_one_player_id')?.value;
                    }
                }

                metricRow.rows.push({ value: metricValue, team: teamValue });
            });

            metricRow.rows.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
            transformedData.push(metricRow);
        });

        return transformedData;
    }

    const handleOnSoloOptionSelected = (optionId) => {
        const newSoloValue = optionId === 1 ? null : optionId === 2;
        onSoloChanged(newSoloValue);
        if (optionId === 1 && byPlayers === 3) {
            onByPlayersChanged(1)
        }
        if (canastasRequired !== null && canastasRequired !== '1-1') {
            onCanastasRequiredChanged(getCanastasRequiredBasedOnSolo(newSoloValue));
        }
    }

    const handleOnByPlayersOptionSelected = (optionId) => {
        onByPlayersChanged(optionId);
        if (optionId === 3) {
            onSoloChanged(false);
            if (canastasRequired !== null && canastasRequired !== '1-1') {
                onCanastasRequiredChanged(getCanastasRequiredBasedOnSolo(false));
            }
        }
    }

    const handleOnCanastasRequiredOptionSelected = (optionId) => {
        onCanastasRequiredChanged(
            optionId === 1 ?
                null :
                (optionId === 2 ? '1-1' : getCanastasRequiredBasedOnSolo(solo)))
    }

    const getCanastasRequiredBasedOnSolo = (soloValue) => {
        return soloValue === null ? '3-4,4-5' : (soloValue ? '3-4' : '4-5');
    }

    const getSoloOptionId = () => {
        switch (solo) {
            case null:
                return 1;
            case true:
                return 2;
            case false:
                return 3;
            default:
                return 1;
        }
    }

    const getCanastasRequiredOptionId = () => {
        switch (canastasRequired) {
            case null:
                return 1;
            case '1-1':
                return 2;
            case '3-4':
            case '4-5':
            case '3-4,4-5':
                return 3;
            default:
                return 1;
        }
    }

    const addThousandsCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <View style={{ width: '100%', alignItems: 'stretch', padding: 10 }}>
            <View style={{ ...ownStyles.filterRowContainer }}>
                <OptionPicker options={[{ id: 1, label: 'ALL' }, { id: 2, label: 'SOLO', disabled: byPlayers === 3 }, { id: 3, label: 'TEAM' }]} selectedId={getSoloOptionId()} onOptionSelected={handleOnSoloOptionSelected} color={Colors.tournamentColor} />
            </View>

            <View style={{ ...ownStyles.filterRowContainer }}>
                <OptionPicker options={[{ id: 1, label: 'ALL' }, { id: 2, label: '1/1' }, { id: 3, label: solo === null ? '3/4 or 4/5' : (solo ? '3/4' : '4/5') }]} selectedId={getCanastasRequiredOptionId()} onOptionSelected={handleOnCanastasRequiredOptionSelected} color={Colors.tournamentColor} />
            </View>

            <View style={{ ...ownStyles.filterRowContainer }}>
                <OptionPicker options={[{ id: 1, label: 'ALL' }, { id: 2, label: 'BY OPPONENT' }, { id: 3, label: 'BY PARTNER', disabled: solo === true }]} selectedId={byPlayers} onOptionSelected={handleOnByPlayersOptionSelected} color={Colors.tournamentColor} />
            </View>

            {!showByPlayers && (
                <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'stretch', opacity: (loading ? 0.4 : 1) }}>
                    {headers.map((header, i) => (
                        <View key={i} style={{ ...ownStyles.column, ...(header.name === 'displayName' ? { flex: 1, minWidth: 95, textAlign: 'center' } : {}), ...(header.name === 'value' ? { minWidth: 150, } : {}) }}>
                            <View style={{ height: 40, justifyContent: 'end', marginBottom: 15 }}>
                                <StandardText style={{ fontSize: 14, weight: 700 }}>{header.label}</StandardText>
                            </View>

                            {rows?.length > 0 && rows[0]?.metrics.map((row, j) => (
                                <View key={j} style={{ ...ownStyles.cell, ...(j % 2 === 0 ? ownStyles.evenCell : ownStyles.oddCell), ...ownStyles.column, ...(header.name === 'displayName' ? { textAlign: 'left' } : {}) }}>
                                    {row[header.name] === null ? (
                                        <StandardText numberOfLines={2} style={{ fontSize: 15 }}>N/A</StandardText>
                                    ) : (
                                        <StandardText numberOfLines={2} style={{ fontSize: 15 }}>{addThousandsCommas(row[header.name])}{header.name === 'percentile' ? '%\nof other players' : '\n'}</StandardText>
                                    )}
                                </View>
                            ))}
                        </View>
                    ))}
                </View>
            )}

            {showByPlayers && byPlayersData && (
                <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'stretch', opacity: (loading ? 0.4 : 1) }}>
                    <View style={{ ...ownStyles.column, flex: 1 }}>
                        {byPlayersData.map(m => (
                            <View key={m.metric.codeName} style={{ ...ownStyles.column, flex: 1, minWidth: 95, textAlign: 'left', marginBottom: byPlayers === 2 ? 30 : 10 }}>
                                <View style={{ height: 40, justifyContent: 'end', marginBottom: 15 }}>
                                    <StandardText style={{ fontSize: 14, weight: 700 }}>{m.metric.displayName}</StandardText>
                                </View>
                                {m.rows.map((row, i) => (
                                    <View key={m.metric.codeName + '-' + i} style={{ ...ownStyles.cell, ...(i % 2 === 0 ? ownStyles.evenCell : ownStyles.oddCell), textAlign: 'left' }}>
                                        <StandardText numberOfLines={2} style={{ fontSize: 15, lineHeight: 22 }}>{row.team}</StandardText>
                                    </View>
                                ))}
                            </View>
                        ))}
                    </View>
                    <View style={{ ...ownStyles.column, flex: 1, }}>
                        {byPlayersData.map(m => (
                            <View key={m.metric.codeName} style={{ ...ownStyles.column, flex: 1, minWidth: 95, textAlign: 'center' }}>
                                <View style={{ height: 40, justifyContent: 'end', marginBottom: 15 }}>
                                    <StandardText style={{ fontSize: 14, weight: 700 }}>{byPlayers === 3 ? 'VS. ALL OPPONENTS' : (!solo ? 'YOU & ALL PARTNERS' : 'YOU')}</StandardText>
                                </View>
                                {m.rows.map((row, i) => (
                                    <View key={m.metric.codeName + '-' + i} style={{ ...ownStyles.cell, ...(i % 2 === 0 ? ownStyles.evenCell : ownStyles.oddCell), ...ownStyles.column }}>
                                        <StandardText>{row.value}</StandardText>
                                    </View>
                                ))}
                            </View>
                        ))}
                    </View>
                </View>
            )}

            {rows !== null && rows.length === 0 && (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 40, }}>
                    <LogoNoText size={178} />
                    <StandardText style={{
                        color: Colors.onBackground3,
                        marginBottom: 20,
                        fontSize: 16,
                        textAlign: 'center'
                    }}>
                        {solo === null && `Complete your first game in order to see your stats!`}
                        {solo === true && `Complete your first game against one opponent to see your Solo stats!`}
                        {solo === false && `Complete your first game with another player to see your Team stats!`}
                    </StandardText>
                </View>
            )}

        </View>
    );
};

const ownStyles = {
    filterRowContainer: {
        marginBottom: 10
    },
    column: {
        textAlign: 'center'
    },
    cell: {
        padding: 7,
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
        height: 60,
        justifyContent: 'center'
    },
    evenCell: {
        backgroundColor: 'rgba(196, 196, 196, 0.3)'
    },
    oddCell: {},
};

export default GameStatisticsBoards;