import {
    RECEIVE_FAILED_GAME_CONFIGS, RECEIVE_FAILED_SAVE_GAME_CONFIG,
    RECEIVE_GAME_CONFIGS, RECEIVE_SAVE_GAME_CONFIG,
    REQUEST_GAME_CONFIGS,
    SUBMIT_SAVE_GAME_CONFIG
} from "../actions/config";
import {LOGOUT} from "../actions/login";

const config = (
    state = {
        gameConfigs: null,
        isFetchingGameConfigs: false,
        isSavingGameConfigs: false,
        error: null
    }
    , action) => {
    switch (action.type) {
        case REQUEST_GAME_CONFIGS:
            return Object.assign({}, state, {
                isFetchingGameConfigs: true,
                isSavingGameConfigs: false,
            });
        case RECEIVE_GAME_CONFIGS:
            return Object.assign({}, state, {
                gameConfigs: action.payload.gameConfigs,
                isFetchingGameConfigs: false,
                isSavingGameConfigs: false,
            });
        case RECEIVE_FAILED_GAME_CONFIGS:
            return Object.assign({}, state, {
                gameConfigs: null,
                isFetchingGameConfigs: false,
                isSavingGameConfigs: false,
                error: action.payload.error
            });
        case SUBMIT_SAVE_GAME_CONFIG:
            return Object.assign({}, state, {
                isFetchingGameConfigs: false,
                isSavingGameConfigs: true,
            });
        case RECEIVE_SAVE_GAME_CONFIG:
            return Object.assign({}, state, {
                gameConfigs: action.payload.gameConfigs,
                isFetchingGameConfigs: false,
                isSavingGameConfigs: false,
            });
        case RECEIVE_FAILED_SAVE_GAME_CONFIG:
            return Object.assign({}, state, {
                gameConfigs: null,
                isFetchingGameConfigs: false,
                isSavingGameConfigs: false,
                error: action.payload.error
            });
        case LOGOUT:
            return Object.assign({}, state, {
                gameConfigs: null,
                isFetchingGameConfigs: false,
                isSavingGameConfigs: false,
                error: null
            });
        default:
            return state;
    }
};

export default config;
