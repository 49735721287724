import React from 'react';
import {Svg, Path} from 'react-native-svg';

const ChevronIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 7 13" fill="none">
            <Path fillRule="evenodd" clipRule="evenodd" d="M0.200412 1.16718C-0.0665973 1.43419 -0.0665962 1.8671 0.200413 2.13411L4.55153 6.48522L0.200257 10.8365C-0.0667526 11.1035 -0.0667522 11.5364 0.200257 11.8034L1.16718 12.7703C1.43419 13.0374 1.8671 13.0374 2.13411 12.7703L6.92063 7.98382C6.93806 7.96954 6.95495 7.95426 6.97122 7.93799L7.93814 6.97106C7.97845 6.93075 8.01267 6.88666 8.04081 6.83994C8.20774 6.57571 8.17607 6.22202 7.94579 5.99174L6.97886 5.02482C6.94515 4.9911 6.90879 4.96165 6.87046 4.93645L2.13427 0.200257C1.86726 -0.0667526 1.43435 -0.0667521 1.16734 0.200257L0.200412 1.16718Z" fill={color}/>
        </Svg>
    );
};

export default ChevronIcon;
