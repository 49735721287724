import React from 'react';
import {Svg, Path, Rect, Circle} from 'react-native-svg';

const ProfileIcon = ({ size, color }) => {
    return (
        <Svg width={size} viewBox="0 0 32 32" fill="none">
            <Rect width="32" height="32" rx="4.8" fill="none"/>
            <Circle cx="16.0004" cy="9.6" r="5.6" fill={color} />
            <Path d="M19.1996 18.7998H12.7996C8.82316 18.7998 5.59961 22.0234 5.59961 25.9998V27.1998C5.59961 27.6416 5.95778 27.9998 6.39961 27.9998H25.5996C26.0414 27.9998 26.3996 27.6416 26.3996 27.1998V25.9998C26.3996 22.0234 23.1761 18.7998 19.1996 18.7998Z" fill={color}/>
        </Svg>
    );
};

export default ProfileIcon;
