import { parseISO, add } from 'date-fns'

export default class GameHelper {

    static allGamePlayersJoined = (game) => {
        return game.gameTeams.reduce((acc, gameTeam) => acc && GameHelper.allTeamPlayersJoined(gameTeam), true);
    };

    static allGamePlayersReady = (game) => {
        return game.gameTeams.reduce((acc, gameTeam) => acc && GameHelper.allTeamPlayersReady(gameTeam), true);
    };

    static allGamePlayersJoinedRound = (game) => {
        return game.gameTeams.reduce((acc, gameTeam) => acc && GameHelper.allTeamPlayersJoinedRound(gameTeam), true);
    };

    static allTeamPlayersJoined = (team) => {
        return team.teamPlayers.reduce((acc, teamPlayer) => acc && teamPlayer.joinedGame, true);
    };

    static allTeamPlayersReady = (team) => {
        return team.teamPlayers.reduce((acc, teamPlayer) => acc && teamPlayer.ready, true);
    };

    static allTeamPlayersJoinedRound = (team) => {
        return team.teamPlayers.reduce((acc, teamPlayer) => acc && teamPlayer.joinedRound, true);
    };

    static getAllPlayers = (game) => {
        return game.gameTeams.reduce((acc, gameTeam) => {
            return acc.concat(gameTeam.teamPlayers);
        }, []);
    };

    static getPlayerByUserAccountId = (game, userAccountId) => {
        return GameHelper.getAllPlayers(game).find(tp => tp.userAccount.id === userAccountId);
    };

    static getPlayerOnSeat = (game, seat) => {
        const teamPlayer = GameHelper.getAllPlayers(game).find(teamPlayer => teamPlayer.seat === seat);
        if (!teamPlayer) {
            throw Error(`Invalid seat ${seat}`);
        }

        return teamPlayer;
    };

    static getViewerPlayer = (game) => {
        return GameHelper.getViewerPlayerInTeam(GameHelper.getViewerTeam(game));
    }

    static getViewerTeam = (game) => {
        return game.gameTeams.find(gameTeam => !!GameHelper.getViewerPlayerInTeam(gameTeam));
    }

    static getViewerPlayerInTeam = (gameTeam) => {
        return gameTeam?.teamPlayers.find(teamPlayer => teamPlayer.isViewer);
    };

    static getViewerPartnerPlayer = (game) => {
        return GameHelper.getViewerTeam(game)?.teamPlayers.find(teamPlayer => !teamPlayer.isViewer);
    };

    static getOpponentTeam = (game) => {
        return game.gameTeams.find(gameTeam => !GameHelper.getViewerPlayerInTeam(gameTeam));
    }

    static getPlayerCards = (teamPlayer) => {
        let handCards = teamPlayer.hand?.handCards?.slice().sort((a, b) => {
            const rankOrderMap = {
                '2': 0,
                'Joker Black': 1,
                'Joker Red': 2,
                '3': 3,
                '4': 4,
                '5': 5,
                '6': 6,
                '7': 7,
                '8': 8,
                '9': 9,
                '10': 10,
                'J': 11,
                'Q': 12,
                'K': 13,
                'A': 14,
            };

            const suitOrderMap = { 'c': 0, 'd': 1, 'h': 2, 's': 3 };

            const aOrder = (rankOrderMap[a.card.cardRank.value] * 10) + (a.card.cardSuit ? suitOrderMap[a.card.cardSuit.letter] : 0);
            const bOrder = (rankOrderMap[b.card.cardRank.value] * 10) + (b.card.cardSuit ? suitOrderMap[b.card.cardSuit.letter] : 0);
            return aOrder - bOrder;
        });

        return handCards?.map((handCard) => (
            handCard.card.cardRank.value === 'Joker Red' || handCard.card.cardRank.value === 'Joker Black' ?
                {
                    id: handCard.card.id,
                    rank: handCard.card.cardRank.value.includes('Red') ? 'red' : 'black',
                    suit: 'joker',
                    meldValue: handCard.card.meldValue,
                    isRed: handCard.card.cardRank.value.includes('Red')
                }
                :
                {
                    id: handCard.card.id,
                    rank: handCard.card.cardRank.value,
                    suit: handCard.card.cardSuit.letter,
                    meldValue: handCard.card.meldValue,
                    isRed: handCard.card.cardSuit.isRed
                }
        )
        ) ?? [];
    };

    static getPlayerCardsByRank = (teamPlayer) => {
        let cardsByRank = [];

        teamPlayer.hand?.handCards?.forEach((handCard) => {
            const rank = handCard.card.cardRank.value === 'Joker Red' || handCard.card.cardRank.value === 'Joker Black' ?
                'joker' :
                (handCard.card.cardRank.value === '3' ? handCard.card.cardRank.value + (handCard.card.cardSuit.isRed ? 'r' : 'b') : handCard.card.cardRank.value);

            let cardsGroup = cardsByRank.find((cardsGroup) => cardsGroup.rank === rank);
            if (!cardsGroup) {
                cardsGroup = {
                    rank: rank,
                    cards: []
                };
                cardsByRank.push(cardsGroup);
            }

            cardsGroup.cards.push(handCard.card.cardRank.value === 'Joker Red' || handCard.card.cardRank.value === 'Joker Black' ?
                {
                    id: handCard.card.id,
                    rank: handCard.card.cardRank.value.includes('Red') ? 'red' : 'black',
                    suit: 'joker',
                    meldValue: handCard.card.meldValue,
                    inHandValue: handCard.card.inHandValue,
                    isRed: handCard.card.cardRank.value.includes('Red')
                }
                :
                {
                    id: handCard.card.id,
                    rank: handCard.card.cardRank.value,
                    suit: handCard.card.cardSuit.letter,
                    meldValue: handCard.card.meldValue,
                    inHandValue: handCard.card.inHandValue,
                    isRed: handCard.card.cardSuit.isRed
                });

            cardsGroup.cards.sort((a, b) => {
                const suitOrderMap = { 'c': 0, 'd': 1, 'h': 2, 's': 3 };
                const aOrder = a.suit ? suitOrderMap[a.suit] : 0;
                const bOrder = b.suit ? suitOrderMap[b.suit] : 0;

                return aOrder - bOrder;
            })
        });

        return cardsByRank;
    };

    static sortCardGroupsbyRank = (cardGroups, asc = false) => {
        return cardGroups.slice().sort((a, b) => {
            const rankOrderMap = {
                '2': 1,
                'joker': 2,
                '3b': 3,
                '3r': 4,
                '4': 5,
                '5': 6,
                '6': 7,
                '7': 8,
                '8': 9,
                '9': 10,
                '10': 11,
                'J': 12,
                'Q': 13,
                'K': 14,
                'A': 15,
            };

            return asc ? rankOrderMap[a.rank] - rankOrderMap[b.rank] : rankOrderMap[b.rank] - rankOrderMap[a.rank];
        });
    };

    static cardIsWild = (card) => {
        return card?.suit === 'joker' || card?.rank === '2';
    };

    static getTeamMeld = (game, cardRank) => {
        return GameHelper.getViewerTeam(game).melds.find(meld => meld.cardRank.value === cardRank);
    };

    static meldHasUncommittedCards = (meld) => {
        return meld?.meldCards.reduce((acc, meldCard) => acc || !meldCard.committed, false);
    };

    static viewerTeamHasUncommittedCards = (game) => {
        return GameHelper.getViewerTeam(game).melds.reduce((acc, meld) => acc || GameHelper.meldHasUncommittedCards(meld), false);
    };

    static viewerIsPickingUpPile = (game) => {
        return GameHelper.getViewerPlayer(game).isPickingUpPile;
    };

    static getRoundPoints = (round) => {
        switch (round) {
            case 1:
                return 50;
            case 2:
                return 90;
            case 3:
                return 120;
            case 4:
                return 150;
        }

        return 0;
    }

    static isDiscardPileEnabled = (game) => {
        const player = GameHelper.getViewerPlayer(game);
        const topCardRankValue = game.discardPileTopCard?.cardRank.value;

        const isTopCardValid = topCardRankValue !== '3';
        const teamHasCanastaOfTopCard = GameHelper.getViewerTeam(game).melds.find(meld => meld.cardRank.value === topCardRankValue)?.meldCards.length >= 7;
        const playerHasCardsInHand = player.hand?.handCards.filter(handCard => handCard.card.cardRank.value === topCardRankValue).length >= 2;

        return player.isCurrentTurn &&
            !player.drawnInTurn &&
            !player.pickedUpPileInTurn &&
            !player.isPickingUpPile &&
            isTopCardValid &&
            !teamHasCanastaOfTopCard &&
            playerHasCardsInHand;
    };

    static isDrawPileEnabled = (game) => {
        const player = GameHelper.getViewerPlayer(game);

        return player.isCurrentTurn &&
            !player.drawnInTurn &&
            !player.pickedUpPileInTurn &&
            !player.isPickingUpPile;
    };

    static getBreakEndDate = (game) => {
        if (game.breakDuration && game.breakRequesterTeamPlayer && game.breakStartDate) {
            const startDate = parseISO(game.breakStartDate);
            return add(startDate, { seconds: game.breakDuration });
        }

        return null;
    };

    static getIsSolo = (game) => {
        return [2, 3].includes(GameHelper.getAllPlayers(game).length);
    }

    static getIsShort = (game) => {
        return game.gameConfig.naturalCanastasNeeded === 1 && game.gameConfig.unnaturalCanastasNeeded === 1;
    }
}
