import React, {useCallback, useEffect, useState} from "react";
import {ActivityIndicator, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import {connect} from "react-redux";
import {performJoinFromGameToken} from "../../redux/auth/actions/login";
import StyleContext from "../../StyleContext";
import {useFocusEffect} from "@react-navigation/core";
import Colors from "../../colors";
import Analytics, {EVENTS} from "../../services/Analytics";

const JoinGameScreen = ({dispatch, navigation, route, joinedGameId, error}) => {

    const [gameToken, setGameToken] = useState(null);

    useFocusEffect(
        useCallback(() => {
            return () => navigation.setParams({screen: undefined, params: undefined});
        }, [navigation])
    );

    useEffect(() => {
        if (!route.params.gameToken) {
            navigation.navigate('Unauthenticated');
        } else {
            setGameToken(route.params.gameToken);
        }
    }, [route]);

    useEffect(() => {
        if (gameToken) {
            dispatch(performJoinFromGameToken(gameToken));
        }
    }, [gameToken]);

    useEffect(() => {
        if (joinedGameId) {
            Analytics.logEvent(EVENTS.GAME_JOIN, {alphanumeric_id: joinedGameId});
            navigation.navigate('Authenticated', {
                screen: 'GameTable',
                params: {
                    id: joinedGameId
                }
            });
        }
    }, [joinedGameId]);

    return (
        <StyleContext.Consumer>
            {styles => (
                <View style={styles.safeArea}>
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', width: 600}}>
                        {
                            !error ? (
                                <ActivityIndicator color={'#000000'} size={'small'}/>
                            ) : (
                                <StandardText style={{color: Colors.error}}>{error}</StandardText>
                            )
                        }
                    </View>
                </View>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null,
        joinedGameId: state.auth.login.joinedGameId,
        error: state.auth.login.error
    }
};

export default connect(mapStateToProps)(JoinGameScreen);
