import {StatusBar, StyleSheet, Text, View} from "react-native";
import React from "react";
import Colors from "../styles/colors";
import LogoLarge from "../icons/LogoLarge";

const SplashScreen = () => {
    return (
        <View style={[ownStyles.container]}>
            <StatusBar barStyle={'light-content'}/>
            <LogoLarge size={198} lettersColor={'white'}/>
        </View>
    );
}

const ownStyles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        backgroundColor: Colors.onBackground,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    }
});

export default SplashScreen;
