import React from 'react';
import { Svg, Path } from 'react-native-svg';

const PlusIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
            <Path d="M7.74268 15.1414V0.570312" stroke={color} strokeWidth="1.63925" />
            <Path d="M15.0286 7.85644L0.45752 7.85645" stroke={color} strokeWidth="1.63925" />
        </Svg>
    );
};

export default PlusIcon;