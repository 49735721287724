import { format, utcToZonedTime } from "date-fns-tz";
import { startOfWeek, addDays } from "date-fns/esm";
import { enUS } from 'date-fns/locale';
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import OptionPicker from "../../sharedComponents/optionPicker/OptionPicker";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";

const DailyTournamentLeaderboards = ({ solo, short, mode, date, rows, onSoloChanged, onShortChanged, onModeChanged, onDateChanged, loading, showPreviousDate = true, showNextDate = true }) => {

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (mode === 1) {
            setColumns([{ name: 'rank', label: 'Rank' }, { name: 'firstName', label: 'Player' }, { name: 'duplicateScore', label: 'Score' }, { name: 'winMargin', label: "Win\nMargin" }, { name: 'teamScore', label: 'Game\nScore' }]);
        } else if (mode === 2) {
            setColumns([{ name: 'rank', label: 'Rank' }, { name: 'firstName', label: 'Player' }, { name: 'points', label: 'Points' }, { name: 'duplicateScore', label: 'Avg.\nScore' }, { name: 'games', label: 'Games' }]);
        } else if (mode === 3) {
            setColumns([{ name: 'rank', label: 'Rank' }, { name: 'firstName', label: 'Player' }, { name: 'percentageTop', label: '% Top 5' }, { name: 'duplicateScore', label: 'Avg.\nScore' }, { name: 'games', label: 'Games' }]);
        }
    }, [rows]);

    const handleOnSoloOptionSelected = (optionId) => {
        onSoloChanged(optionId === 1)
    }

    const handleOnShortOptionSelected = (optionId) => {
        onShortChanged(optionId === 1)
    }

    const handleOnModeOptionSelected = (optionId) => {
        onModeChanged(optionId)
    }

    const handleOnDateChanged = (next) => {
        onDateChanged(next)
    }

    const addThousandsCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <View style={{ width: '100%', alignItems: 'stretch', padding: 10 }}>
            <View style={{ ...ownStyles.filterRowContainer }}>
                <OptionPicker options={[{ id: 1, label: 'SOLO' }, { id: 2, label: 'TEAM' }]} selectedId={solo ? 1 : 2} onOptionSelected={handleOnSoloOptionSelected} color={Colors.tournamentColor} />
            </View>

            <View style={{ ...ownStyles.filterRowContainer }}>
                <OptionPicker options={[{ id: 1, label: '1/1 (Short)' }, { id: 2, label: (solo ? '3/4' : '4/5') + ' (Long)' }]} selectedId={short ? 1 : 2} onOptionSelected={handleOnShortOptionSelected} color={Colors.tournamentColor} />
            </View>

            <View style={{ ...ownStyles.filterRowContainer, marginBottom: 20 }}>
                <OptionPicker options={[{ id: 1, label: 'DAILY' }, { id: 2, label: 'WEEKLY' }, { id: 3, label: 'ALL-TIME' }]} selectedId={mode} onOptionSelected={handleOnModeOptionSelected} color={Colors.tournamentColor} />
            </View>

            {mode === 3 ? (
                <View style={{ flexDirection: 'column', alignItems: 'center', height: 30 }}>
                    <StandardText style={{ width: 300, marginStart: 20, marginEnd: 20, textAlign: 'center', weight: 700 }}>ALL-TIME</StandardText>
                    <StandardText style={{ width: 300, marginStart: 20, marginEnd: 20, textAlign: 'center', fontSize: 14 }}>(min. 10 Daily Tournament games)</StandardText>
                </View>
            ) : (
                <View style={{ flexDirection: 'row', justifyContent: 'center', height: 30 }}>
                    <TouchableOpacity onPress={() => { handleOnDateChanged(false) }} disabled={!showPreviousDate} style={{opacity: showPreviousDate ? 1 : 0.2}}><StandardText style={{weight: 700}}>{'<'}</StandardText></TouchableOpacity>
                    <StandardText style={{ width: 175, marginStart: 20, marginEnd: 20, textAlign: 'center', weight: 700 }}>{mode === 1 ? format(date, "MMMM d, yyyy", { locale: enUS }) : `Week of ${format(startOfWeek(date, { weekStartsOn: 0 }), "MMM d, Y", { locale: enUS })}`}</StandardText>
                    <TouchableOpacity onPress={() => { handleOnDateChanged(true) }} disabled={!showNextDate} style={{opacity: showNextDate ? 1 : 0.2}}><StandardText style={{weight: 700}}>{'>'}</StandardText></TouchableOpacity>
                </View>
            )}


            <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'stretch', opacity: (loading ? 0.4 : 1) }}>
                {columns.map((column, i) => (
                    <View key={i} style={{ ...ownStyles.column, ...(column.name === 'firstName' ? { flex: 1, minWidth: 60 } : {}), ...(column.name === 'rank' ? {} : {}) }}>
                        <View style={{ height: 40, justifyContent: 'end', ...ownStyles.cell, ...(column.name === 'firstName' ? { textAlign: 'left' } : {}) }}>
                            <StandardText style={{ fontSize: 14, weight: 700 }}>{column.label}</StandardText>
                        </View>

                        {rows.map((row, j) => (
                            <View key={j} style={{ ...ownStyles.cell, ...(j % 2 === 0 ? ownStyles.evenCell : ownStyles.oddCell), ...(column.name === 'firstName' ? { minWidth: 60, textAlign: 'left' } : {}) }}>
                                <StandardText numberOfLines={1} style={{ flex: 1 }}>{(row[column.name] !== null && column.name !== 'firstName' ? addThousandsCommas(row[column.name]) : row[column.name]) + (column.name === 'percentageTop' ? '%' : '')}</StandardText>
                            </View>
                        ))}
                    </View>
                ))}
            </View>

            {rows.length === 0 && (
                <StandardText style={{ textAlign: 'center', fontSize: 14, marginTop: 20 }}>No data available</StandardText>
            )}

        </View>
    );
};

const ownStyles = {
    filterRowContainer: {
        marginBottom: 10
    },
    column: {},
    cell: {
        padding: 7,
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
    },
    evenCell: {
        backgroundColor: 'rgba(196, 196, 196, 0.3)'
    },
    oddCell: {},
};

export default DailyTournamentLeaderboards;