import React from 'react';
import { ScrollView } from 'react-native';
import { View } from 'react-native';
import StandardText from '../../sharedComponents/standardText/StandardText';
import Colors from '../../styles/colors';
import Spacing from '../../styles/spacing';
import PlayerBadge from './PlayerBadge';

const TurnsSummary = ({ turnsSummary, viewerPlayerId, mobileDisplay = false }) => {

    const renderTurn = (turnSummary) => {

        return (
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                <View style={{ marginTop: 12, marginEnd: 10, width: 65 }}>
                    <StandardText style={{ fontSize: 12, weight: 500 }}>
                        Turn #{turnSummary.turn}
                    </StandardText>
                </View>
                <View style={{ marginEnd: 10 }}>
                    <PlayerBadge player={turnSummary.teamPlayer}
                        bare={true}
                        size={40}
                        fontSize={18}
                        gapSize={3}
                        inactivePlainColor={Colors.onBackgroundBorder}
                        showBackgroundOnActive={true}
                        showBackgroundOnInactive={false}
                        active={false} />
                </View>
                <View style={{ marginTop: 12, maxWidth: mobileDisplay ? 180 : null }}>
                    <StandardText>{turnSummary.teamPlayer.id === viewerPlayerId ? 'You' : turnSummary.teamPlayer.firstName}:</StandardText>

                    {turnSummary.messages.map((message, i) => (
                        <View key={i} style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                            <StandardText style={{ lineHeight: 25, marginStart: 10 }}>• </StandardText>
                            <StandardText style={{ lineHeight: 25, marginStart: 10 }}>{message}</StandardText>
                        </View>
                    ))}
                </View>

            </View>
        );
    };

    return (
        <ScrollView style={{ width: '100%' }} contentContainerStyle={{ padding: Spacing.large, paddingTop: 20, width: '100%', alignItems: 'center' }}>
            {turnsSummary.length === 0 ?
                <View style={{ maxWidth: 300 }}>
                    <StandardText style={{ textAlign: 'center', fontSize: 16, weight: 400, lineHeight: 32, marginBottom: 20 }}>
                        The game hasn't{'\n'}started yet.
                    </StandardText>
                </View>
                :
                <View style={{ maxWidth: 300 }}>
                    <StandardText style={{ textAlign: 'center', fontSize: 20, weight: 500, lineHeight: 32, marginBottom: 20 }}>
                        Below is a summary{'\n'}of recent turns{'\n'}(most recent turn at top):
                    </StandardText>
                    <View style={{ marginBottom: 20 }}>
                        {turnsSummary.map((turnSummary, i) => (
                            <View key={i}>
                                {renderTurn(turnSummary)}
                            </View>
                        ))}
                    </View>
                </View>
            }

        </ScrollView>
    );
};

export default TurnsSummary;