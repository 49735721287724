import React, {useCallback, useContext, useEffect, useState} from "react";
import {TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import StyleContext from "../../StyleContext";
import {SafeAreaView} from "react-native-safe-area-context";
import {MaterialIcons} from "@expo/vector-icons";
import {useNavigation} from "@react-navigation/native";
import {Input} from "react-native-elements";
import Colors from "../../colors";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import Api from "../../services/Api";
import {connect} from "react-redux";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";

const CreateDebugGameScreen = ({accessToken}) => {
    const navigation = useNavigation();
    const loadingContext = useContext(LoadingContext);
    const [debugGameData, setDebugGameData] = useState('');
    const [jsonValid, setJsonValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false);
    }, []));

    const handleCreateGamePress = async () => {
        setLoading(true);
        try {
            JSON.parse(debugGameData);
            setJsonValid(true);
        } catch (e) {
            setJsonValid(false);
            setLoading(false);
            return;
        }

        try {
            const game = await Api.createDebugGame(accessToken, debugGameData);
            setErrorMessage('');
            setLoading(false);
            navigation.navigate('GameTable', {
                id: game.alphanumericId
            });
        } catch (e) {
            setErrorMessage(e.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        setErrorMessage(jsonValid ? "" : "Invalid JSON");
    }, [jsonValid]);

    return (
        <StyleContext.Consumer>
            {styles => (
                <SafeAreaView style={[styles.safeArea, styles.container, {flexDirection: 'column'}]}>
                    <View style={{position: 'absolute', top: 20, left: 20, width: 24, height: 24}}>
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('Home')
                        }}>
                            <MaterialIcons name="home" size={24} color={'#999999'}/>
                        </TouchableOpacity>
                    </View>
                    <View>
                        <StandardText style={styles.header}>Create Debug Game</StandardText>
                    </View>
                    <View style={{marginTop: 30}}>
                        <Input multiline={true} style={{
                            width: 600,
                            height: 200,
                            borderWidth: 1,
                            borderColor: Colors.onBackgroundBorder,
                            borderRadius: 5
                        }} onChangeText={(text) => {
                            setDebugGameData(text)
                        }}/>
                        <LoadingButton label={'Create Game'} onPress={handleCreateGamePress} loading={loading}/>

                        <StandardText style={{color: Colors.error, textAlign: 'center'}} >{errorMessage !== '' ? errorMessage : ' '}</StandardText>
                    </View>
                    <StandardText style={{marginBottom: 10, marginTop: 20}}>Sample JSON:</StandardText>
                    <View style={{width: 700, backgroundColor: Colors.backgroundSecondary, padding: 20}}>
                        <StandardText style={{fontFamily: 'Monospace', fontSize: 13}}>
                            {"{\n" +
                            "  \"draw\": [\"Joker Red\",\"4\"],\n" +
                            "  \"discard\": [\"Q\",\"10\"],\n" +
                            "  \"teams\": [\n" +
                            "    {\n" +
                            "      \"melds\": [\n" +
                            "        [\"6\", \"6\", \"6\"],\n" +
                            "        [\"7\", \"7\", \"7\", \"7\", \"7\", \"2\", \"7\"]\n" +
                            "      ],\n" +
                            "      \"players\": [\n" +
                            "        {\n" +
                            "          \"id\": \"2\",\n" +
                            "          \"drawn\": false,\n" +
                            "          \"playingFoot\": false,\n" +
                            "          \"hand\": [\"4\",\"Q\"],\n" +
                            "          \"foot\": [\"K\",\"J\", \"2\", \"2\", \"5\", \"A\", \"3\", \"3\", \"9\", \"9\", \"10\"]\n" +
                            "        }\n" +
                            "      ]\n" +
                            "    },\n" +
                            "    {\n" +
                            "      \"melds\": [\n" +
                            "        [\"5\", \"5\", \"5\"],\n" +
                            "        [\"K\", \"K\", \"K\", \"K\", \"K\"]\n" +
                            "      ],\n" +
                            "      \"players\": [\n" +
                            "        {\n" +
                            "          \"id\": \"4\",\n" +
                            "          \"drawn\": false,\n" +
                            "          \"playingFoot\": true,\n" +
                            "          \"hand\": [],\n" +
                            "          \"foot\": [\"10\", \"2\", \"7\"]\n" +
                            "        }\n" +
                            "      ]\n" +
                            "    }\n" +
                            "  ]\n" +
                            "}"}
                        </StandardText>
                    </View>
                </SafeAreaView>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.login.accessToken
    }
};

export default connect(mapStateToProps)(CreateDebugGameScreen);
