import React from "react";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import ClubsIcon from "./suits/ClubsIcon";
import DiamondsIcon from "./suits/DiamondsIcon";
import HeartsIcon from "./suits/HeartsIcon";
import JokerIcon from "./suits/JokerIcon";
import SpadesIcon from "./suits/SpadesIcon";

const Pile = ({ type, totalCards = 0, topCard, onPress = () => { }, loading = false, disabled = false }) => {

    if (topCard) {
        topCard = topCard.cardRank.value === 'Joker Red' || topCard.cardRank.value === 'Joker Black' ?
            {
                rank: topCard.cardRank.value.includes('Red') ? 'red' : 'black',
                suit: 'joker',
                isRed: topCard.cardRank.value.includes('Red')
            }
            :
            {
                rank: topCard.cardRank.value,
                suit: topCard.cardSuit.letter,
                isRed: topCard.cardSuit.isRed
            };
    }

    const renderIcon = (card) => {
        switch (card.suit) {
            case 'c':
                return (<ClubsIcon size={20} color={Colors.blackCard} />);
            case 'd':
                return (<DiamondsIcon size={20} color={Colors.redCard} />);
            case 'h':
                return (<HeartsIcon size={20} color={Colors.redCard} />);
            case 's':
                return (<SpadesIcon size={20} color={Colors.blackCard} />);
            case 'joker':
                return;
        }
    }

    const renderJoker = () => {
        return (<JokerIcon size={20} color={'white'} />)
    }



    return (
        <TouchableOpacity onPress={onPress} disabled={disabled}>
            <View style={[ownStyles.pileBox, { opacity: disabled ? 0.5 : 1 }, {zIndex: 100}]}>
                <View style={{ position: 'absolute', top: 3, left: 5 }}>
                    <StandardText style={[ownStyles.pileBoxText]}>
                        {type === 'draw' ? 'Draw' : (disabled ? 'Discard\nPile' : 'Pick\nUp')}
                    </StandardText>
                </View>
                {loading &&
                    <ActivityIndicator animating={true} color={Colors.onPrimary} />
                }

                <View style={[ownStyles.pileCard]}></View>
                <View style={[ownStyles.pileCard, { bottom: 9 }]}></View>
                <View style={[ownStyles.pileCard, { bottom: 13, backgroundColor: Colors.background }]}>
                    <View style={{ position: 'absolute', bottom: 0, right: 3 }}>
                        <StandardText style={{ color: 'rgba(110, 110, 131, 1)' }}>{(type === 'discard' ? 'x' : '') + totalCards}</StandardText>
                    </View>
                    {type === 'discard' && !!topCard && (
                        <View>
                            <StandardText weight={500} style={[{
                                color: topCard.isRed ? Colors.redCard : Colors.blackCard,
                                position: 'absolute',
                                top: 0,
                                left: 4
                            }]}>{topCard.suit === 'joker' ? renderJoker() : topCard.rank}</StandardText>
                            <View style={{ position: 'absolute', top: 0, right: 0 }}>
                                {renderIcon(topCard)}
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default Pile;

const ownStyles = {
    pileBox: {
        backgroundColor: Colors.primary,
        borderRadius: 6,
        height: 40,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 5,
        boxShadow: '0px 12px 16px rgba(87, 136, 200, 0.25)'
    },
    pileBoxText: {
        color: Colors.onPrimary,
        fontSize: 14
    },
    pileCard: {
        position: 'absolute',
        backgroundColor: Colors.primary2,
        bottom: 5,
        right: 5,
        width: 60,
        height: 40,
        borderRadius: 6,
        borderWidth: 2,
        borderColor: Colors.primary,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        userSelect: 'none',
    },
};
