import React, {useCallback, useContext, useEffect, useState} from "react";
import {TextInput, View} from "react-native";
import StyleContext from "../../StyleContext";
import {SafeAreaView} from "react-native-safe-area-context";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {connect} from "react-redux";
import StandardText from "../../sharedComponents/standardText/StandardText";
import {performCompleteUserAccountInfo} from "../../redux/auth/actions/login";
import {useFocusEffect} from "@react-navigation/core";
import Colors from "../../styles/colors";
import LogoLarge from "../../icons/LogoLarge";
import {LoadingContext} from "../../context/LoadingContext";

const CompleteUserAccountInfoScreen = ({dispatch, navigation, accessToken, userAccount, loadingCompleteUserAccopuntInfo, completeUserAccountInfoError}) => {

    const [screenReady, setScreenReady] = useState(false);
    const [emailAddress, setEmailAddress] = useState(null);
    const loadingContext = useContext(LoadingContext);

    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false);
    }, []));

    useEffect(() => {
        if (userAccount?.user.email) {
            navigation.navigate('Home');
        }
    }, [userAccount]);

    useFocusEffect(useCallback(() => {
        if (!screenReady && userAccount) {
            setScreenReady(true);
        }
    }, [userAccount]));

    const handleCompleteEmailAddress = () => {
        dispatch(performCompleteUserAccountInfo(accessToken, emailAddress));
    };

    return (
        <StyleContext.Consumer>
            {styles => (
                <SafeAreaView style={[styles.safeArea, styles.container, {flexDirection: 'column'}]}>
                    {screenReady &&
                    <View style={{width: 350, alignItems: 'stretch'}}>

                        <LogoLarge size={198} />

                        <StandardText style={{fontSize: 14, color: Colors.onBackground, textAlign: 'center', marginTop: 35,  marginBottom: 20}}>
                            Please add an email address so that you can receive game invitations from family and
                            friends.
                        </StandardText>
                        <TextInput style={[styles.input, {fontFamily: 'Roboto_400Regular'}]} placeholder={'Email address'}
                                   onChangeText={(text => {
                                       setEmailAddress(text)
                                   })} onSubmitEditing={handleCompleteEmailAddress}
                                   placeholderTextColor={'#999999'}/>
                        <LoadingButton loading={loadingCompleteUserAccopuntInfo} label={'Save'}
                                       onPress={handleCompleteEmailAddress}
                                       backgroundColor={Colors.primary}
                                       disabled={!emailAddress} outlined={true}/>
                        <StandardText
                            style={[styles.errorMessage, {
                                margin: 5,
                                textAlign: 'center'
                            }]}>{completeUserAccountInfoError ? completeUserAccountInfoError : ' '}</StandardText>
                    </View>
                    }
                </SafeAreaView>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null,
        accessToken: state.auth.login.accessToken,
        loadingCompleteUserAccopuntInfo: state.auth.login.isCompletingUserAccountInfo,
        completeUserAccountInfoError: state.auth.login.error
    }
};

export default connect(mapStateToProps)(CompleteUserAccountInfoScreen);
