import {Image, TouchableOpacity, View} from "react-native";
import React, {useState} from "react";
import facebookLogo from './../../../assets/facebook_logo.png';
import googleLogo from './../../../assets/google_logo.png';
import appleLogo from './../../../assets/apple_logo.png';
import emailLogo from './../../../assets/email_logo.png';
import * as Linking from "expo-linking";
import {FACEBOOK_REDIRECT_URL, GOOGLE_REDIRECT_URL} from "../../env.json"
import Colors from "../../styles/colors";
import StandardText from "../standardText/StandardText";

const SocialLoginButton = ({loading = false, disabled = false, provider, signUpMode = false, onPress = () => {}}) => {

    let providerName;
    let logo;
    let OAuthUrl;
    switch (provider) {
        case 'google':
            providerName = 'Google';
            logo = googleLogo;
            OAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=1082253422372-82qchqvnhd7ibe0u2vl702eggrlui5n0.apps.googleusercontent.com&redirect_uri=${GOOGLE_REDIRECT_URL}&response_type=code&scope=email+profile`;
            break;
        case 'facebook':
            providerName = 'Facebook';
            logo = facebookLogo;
            OAuthUrl = `https://www.facebook.com/v8.0/dialog/oauth?client_id=2666569713597006&redirect_uri=${FACEBOOK_REDIRECT_URL}&response_type=code`;
            break;
        case 'apple':
            providerName = 'Apple';
            logo = appleLogo;
            OAuthUrl = ``;
            break;
        case 'email':
        default:
            providerName = 'Email';
            logo = emailLogo;
            OAuthUrl = ``;
            break;
    }

    const handleLogin = async () => {
        onPress();

        if (OAuthUrl !== '') {
            await Linking.openURL(OAuthUrl);
        }
    };

    return (
        <TouchableOpacity style={{...ownStyles.button, opacity: loading || disabled ? 0.3 : 1}} disabled={loading || disabled} activeOpacity={0.4} onPress={handleLogin}>
            <Image source={{uri: logo}} style={{...ownStyles.icon, ...(provider === 'email' ? {width: 24, height: 24, top: 7} : {})}}/>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <StandardText style={{color: Colors.onBackground, fontSize: 16}}>Sign {signUpMode ? 'up' : 'in'} with {providerName}</StandardText>
            </View>
        </TouchableOpacity>
    );
};

const ownStyles = {
    button: {
        padding: 10,
        marginTop: 8,
        marginBottom: 8,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: Colors.onBackground,
        alignItems: 'center',
    },
    icon: {
        position: 'absolute',
        top: 9,
        left: 20,
        width: 20,
        height: 20,
    }
};

export default SocialLoginButton;
