import { ActivityIndicator, FlatList, View } from "react-native";
import LogoNoText from "../../icons/LogoNoText";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import React from "react";
import GameItem from "../../sharedComponents/gameItem/GameItem";

const GamesList = ({ games, onGameItemPress, onGameItemArchiveToogglePress, onGameItemRemoveToogglePress, onEndReached, loading, paddingBottom = 92, activityIndicatorColor = Colors.onBackground, emptyListMessage = '' }) => {

    const renderGameItem = ({ item, i }) => {
        return <GameItem game={item.node}
            onPress={() => {
                onGameItemPress(item.node)
            }}
            onArchiveTogglePress={() => {
                onGameItemArchiveToogglePress(item.node)
            }}
            onRemoveTogglePress={() => {
                onGameItemRemoveToogglePress(item.node)
            }}
        />;
    };

    return (
        loading ? (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator animating={true} color={activityIndicatorColor} />
            </View>
        ) : (
            games?.length > 0 ? (
                <View style={{
                    flex: 1,
                    padding: 12,
                    paddingBottom: 0,
                    paddingTop: 0,
                    width: '100%',
                    maxWidth: 800
                }}>
                    <FlatList style={{ height: 1, paddingBottom: paddingBottom }} data={games}
                        renderItem={renderGameItem}
                        keyExtractor={(item) => item.node.alphanumericId}
                        onEndReachedThreshold={0.5} onEndReached={onEndReached} />
                </View>
            ) : (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', maxWidth: 400 }}>
                    <LogoNoText size={178} />
                    <StandardText style={{
                        color: Colors.onBackground3,
                        marginBottom: 20,
                        fontSize: 16,
                        textAlign: 'center'
                    }}>
                        {emptyListMessage}
                    </StandardText>
                </View>
            ))
    );
};

export default GamesList;
