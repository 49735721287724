import React from 'react';
import { Svg, Path, Rect } from 'react-native-svg';

const CloseIcon = ({ size }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
            <Rect width="24" height="24" rx="0.6" fill="#11204A" />
            <Path
                d="M6.48481 8.18208C6.2505 7.94776 6.2505 7.56786 6.48481 7.33355L7.33334 6.48502C7.56765 6.25071 7.94755 6.25071 8.18187 6.48502L17.5157 15.8188C17.75 16.0531 17.75 16.433 17.5157 16.6674L16.6671 17.5159C16.4328 17.7502 16.0529 17.7502 15.8186 17.5159L6.48481 8.18208Z"
                fill="white" />
            <Path
                d="M15.8189 6.48481C16.0532 6.2505 16.4331 6.2505 16.6674 6.48481L17.516 7.33334C17.7503 7.56765 17.7503 7.94755 17.516 8.18187L8.18215 17.5157C7.94783 17.75 7.56793 17.75 7.33362 17.5157L6.48509 16.6671C6.25077 16.4328 6.25077 16.0529 6.48509 15.8186L15.8189 6.48481Z"
                fill="white" />
        </Svg>

    );
};

export default CloseIcon;
