import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from "react-native";
import {Animated} from 'react-native';
import GameHelper from '../GameHelper';
import Card from "./Card";
import Colors from "../../styles/colors";

const PlayerCards = ({
                         cardsGrouped,
                         selectedCard,
                         asc = false,
                         compact = false,
                         onCardSelected,
                         disableInteractions = false
                     }) => {

    const [playerCards, setPlayerCards] = useState([]);
    const [showStackIndicator, setShowStackIndicator] = useState(false);

    useEffect(() => {
        const cards = GameHelper.sortCardGroupsbyRank(cardsGrouped, asc);
        setPlayerCards(cards);
        setShowStackIndicator(cards.length < 15);
    }, [cardsGrouped, asc]);

    const getCardGroupLength = (rank) => {
        return playerCards.find((cardsGroup) => cardsGroup.rank === rank)?.cards.length;
    };

    const renderCards = (cardsGroup, heap) => {
        const g = compact ? cardsGroup.cards.slice(0, 1) : cardsGroup.cards;
        return g.map((card, index) => {
            const rank = card.rank === '3' ? `${card.rank}${card.isRed ? 'r' : 'b'}` : (card.suit === 'joker' ? 'joker' : card.rank);
            const length = compact ? getCardGroupLength(rank) : 0;

            return (
                <View key={card.id} style={{
                    margin: compact && showStackIndicator ? 4 : 2,
                    marginRight: 2,
                    marginLeft: heap && index > 0 ? -28 : 2,
                }}>
                    <TouchableOpacity onPress={() => {
                        onCardSelected(card);
                    }} disabled={disableInteractions}>
                        {compact && showStackIndicator && length > 2 &&
                        <View style={{
                            ...ownStyles.pileCard,
                            top: 10,
                            backgroundColor: card.isRed ? Colors.redCard : Colors.blackCard
                        }}/>
                        }
                        {compact && showStackIndicator && length > 1 &&
                        <View style={{
                            ...ownStyles.pileCard,
                            top: 5,
                            backgroundColor: card.isRed ? Colors.redCard : Colors.blackCard
                        }}/>
                        }
                        <Card card={card} isSelected={selectedCard && card.id === selectedCard.id}
                              totalStacked={compact ? length : null}/>
                    </TouchableOpacity>
                </View>
            )
        })
    };

    return (
        <View style={{...ownStyles.cardsContainer, ...(compact ? {maxHeight: '100%', flexWrap: 'wrap'} : {})}}>
            {playerCards.map((cardsGroup) => {
                const heap = cardsGroup.cards.length > 1;
                return (
                    <View key={cardsGroup.rank} style={{flexDirection: 'row'}}>
                        {
                            renderCards(cardsGroup, heap)
                        }
                    </View>
                )
            })}
        </View>
    );
};

const ownStyles = {
    cardsContainer: {
        flexDirection: 'column',
        justifyContent: 'start'
    },
    pileCard: {
        position: 'absolute',
        backgroundColor: Colors.primary2,
        width: 66,
        height: 46,
        borderRadius: 6,
        borderWidth: 3,
        borderColor: Colors.background,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        userSelect: 'none',
    },
};

export default React.memo(PlayerCards); 
