import {RECEIVE_FAILED_VIEWER_GAMES, RECEIVE_VIEWER_GAMES, REQUEST_VIEWER_GAMES} from "../actions/games";

const games = (
    state = {
        data: [],
        cursor: null,
        loading: false
    }
    , action) => {
    switch (action.type) {
        case REQUEST_VIEWER_GAMES:
            return Object.assign({}, state, {
                loading: action.payload.reset
            });
        case RECEIVE_VIEWER_GAMES:
            return Object.assign({}, state, {
                data: [...(action.payload.reset ? []: state.data), ...action.payload.games.edges],
                cursor: action.payload.games.pageInfo.endCursor,
                loading: false
            });
        case RECEIVE_FAILED_VIEWER_GAMES:
            return Object.assign({}, state, {
                loading: false
            });
    }

    return state;
};

export default games;
