import React from 'react';
import { Svg, Path } from 'react-native-svg';

const CheckIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 15 12" fill="none">
            <Path d="M13.6677 1.07884L4.73782 10.0087L0.678509 5.94941" stroke={color} strokeWidth="2" />
        </Svg>
    );
};

export default CheckIcon;