import {Keyboard, TextInput, TouchableOpacity, View} from "react-native";
import React, {useCallback, useContext, useState} from "react";
import StyleContext from "../../StyleContext";
import LogoLarge from "../../icons/LogoLarge";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import SocialLoginButton from "../../sharedComponents/facebookLoginButton/SocialLoginButton";
import {clearLoginError, performLogin} from "../../redux/auth/actions/login";
import {connect} from "react-redux";
import {clearSignupError, performSignup} from "../../redux/signup/actions";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import {useNavigation} from "@react-navigation/native";
import UserSession from "../../services/UserSession";

const StartScreen = ({dispatch, route, loginError, signupError, loading, userAccount, localLoginFailed}) => {

    const [signUpMode, setSignUpMode] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [localError, setLocalError] = useState('');
    const loadingContext = useContext(LoadingContext);
    const navigation = useNavigation();

    useFocusEffect(useCallback( () => {
        if (route.params?.signup) {
            setSignUpMode(true);
            if (route.params.signup !== 'free') {
                UserSession.setWantsToSubscribe(route.params.signup).then();
            }
        }
    }, [route]));

    useFocusEffect(useCallback(() => {
        if (!userAccount && localLoginFailed) {
            loadingContext.setScreenLoading(false);
        }
    }, [userAccount, localLoginFailed]));

    const handleSubmit = async () => {
        Keyboard.dismiss();

        if (!signUpMode) {
            if (email && password) {
                setLocalError('');
                dispatch(performLogin(email, password));
            } else {
                setLocalError("Please fill your email and password");
            }
        } else {
            if (firstName && email && password) {
                setLocalError('');
                dispatch(performSignup(firstName, null, email, password));
            } else {
                setLocalError("Please fill your first name, email and password");
            }
        }
    };

    const toggleSignUpMode = () => {
        setLocalError('');
        dispatch(clearLoginError());
        dispatch(clearSignupError());

        setEmail('');
        setPassword('');
        setFirstName('');

        setSignUpMode((v) => !v);
    };

    return (
        (!loadingContext.isLoading) &&
        <StyleContext.Consumer>
            {styles => (
                <View style={styles.safeArea}>
                    <View style={{width: '100%', flex: 1, alignItems: 'center', justifyContent: 'center'}}>

                        <View style={{...ownStyles.container}}>

                            <View style={{marginTop: -15}}>
                                <LogoLarge size={198}/>
                            </View>
                            <StandardText style={{...styles.subheader, color: Colors.onBackground3, marginTop: 10}}>When
                                you play,
                                you're family™</StandardText>

                            <StandardText style={{...ownStyles.buttonsTitle, color: Colors.onBackground4}}>
                                {signUpMode ? 'Join us' : 'Welcome back'}
                            </StandardText>

                            <View style={{...ownStyles.buttonsContainer}}>

                                {signUpMode &&
                                <TextInput style={[styles.input, {fontFamily: 'Roboto_400Regular'}]}
                                           value={firstName}
                                           placeholder={'First Name'}
                                           onChangeText={(text => {
                                               setFirstName(text)
                                           })} onSubmitEditing={handleSubmit}
                                           placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>
                                }

                                <TextInput style={[styles.input, {fontFamily: 'Roboto_400Regular'}]}
                                           value={email}
                                           placeholder={signUpMode ? 'Email' : 'Username or Email'}
                                           onChangeText={(text => {
                                               setEmail(text)
                                           })} onSubmitEditing={handleSubmit}
                                           placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>

                                <TextInput style={[styles.input, {fontFamily: 'Roboto_400Regular'}]}
                                           value={password}
                                           placeholder={'Password'}
                                           onChangeText={(text => {
                                               setPassword(text)
                                           })} onSubmitEditing={handleSubmit}
                                           placeholderTextColor={'rgba(112, 121, 143, 0.6)'}
                                           secureTextEntry/>

                                <SocialLoginButton provider={'email'} signUpMode={signUpMode}
                                                   onPress={handleSubmit} loading={loading}/>
                                <View style={{height: 20, justifyContent: 'center'}}>
                                    {(localError || (!signUpMode && loginError) || (signUpMode && signupError)) && <StandardText
                                        style={[ownStyles.errorMessage, {margin: 5}]}>{localError !== '' ? localError : (signUpMode ? signupError : loginError)}</StandardText>}
                                </View>

                                <SocialLoginButton provider={'google'} signUpMode={signUpMode}/>

                                <SocialLoginButton provider={'facebook'} signUpMode={signUpMode} disabled={false}/>

                                <SocialLoginButton provider={'apple'} signUpMode={signUpMode} disabled={true}/>


                            </View>

                            <View style={{...ownStyles.bottomTextContainer}}>
                                <StandardText
                                    style={{...ownStyles.bottomText}}>{signUpMode ? 'Already have an account?' : 'Not a member yet?'}</StandardText>
                                <TouchableOpacity style={{marginLeft: 10}} onPress={toggleSignUpMode}>
                                    <StandardText
                                        style={{
                                            ...ownStyles.bottomText,
                                            color: Colors.primary
                                        }}>{signUpMode ? 'Sign in' : 'Sign up'}</StandardText>
                                </TouchableOpacity>
                            </View>

                            <View style={{...ownStyles.bottomTextContainer}}>
                                <TouchableOpacity style={{marginLeft: 10}} onPress={() => {navigation.navigate('RequestChangePassword')}}>
                                    <StandardText
                                        style={{
                                            ...ownStyles.bottomText,
                                            color: Colors.primary
                                        }}>Forgot Password?</StandardText>
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                </View>
            )}
        </StyleContext.Consumer>
    );
};

const ownStyles = {
    container: {
        width: 300
    },
    buttonsTitle: {
        fontSize: 24,
        weight: 500,
        marginTop: 35,
        marginBottom: 10
    },
    buttonsContainer: {},
    bottomTextContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10
    },
    bottomText: {
        fontSize: 14,
        color: Colors.onBackground
    },
    errorMessage: {
        color: Colors.redCard,
        fontSize: 14,
        textAlign: 'center',
    }
};

const mapStateToProps = (state) => {
    return {
        loading: state.auth.login.isLoggingIn,
        userAccount: state.auth.login.userAccount || null,
        loginError: state.auth.login.error || null,
        signupError: state.signup.error || null,
        localLoginFailed: state.auth.login.localLoginFailed
    }
};

export default connect(mapStateToProps)(StartScreen);
