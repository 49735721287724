import React from "react";
import { Text } from "react-native";
import Colors from "../../styles/colors";

const StandardText = ({ style, children, weight = null , numberOfLines = null}) => {

    let family = 'Roboto_400Regular';
    const w = weight || style?.weight || null;
    switch (w) {
        case 900:
            family = 'Roboto_900Black'
            break;
        case 700:
            family = 'Roboto_700Bold'
            break;
        case 500:
            family = 'Roboto_500Medium'
            break;
        case 400:
        default:
            family = 'Roboto_400Regular';
            break;

    }

    if (style?.weight) {
        delete style.weight
    }

    return (
        <Text numberOfLines={numberOfLines} style={[{ color: Colors.onBackground, fontFamily: family, fontSize: 16 }, style]}>{children}</Text>
    );
};

export default StandardText;
