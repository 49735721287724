import React, {useCallback, useContext, useEffect, useState} from "react";
import {ActivityIndicator, Image, Text, TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import StyleContext from "../../StyleContext";
import {SafeAreaView} from "react-native-safe-area-context";
import {connect} from "react-redux";
import Colors from "../../styles/colors";
import {useFocusEffect} from "@react-navigation/core";
import {LoadingContext} from "../../context/LoadingContext";
import blurredSuits from '../../../assets/blurred_suits_bg2.png';
import close from '../../../assets/close.png';
import {fetchViewerTutorialScreens, performReadTutorial} from "../../redux/viewer/tutorialSlice";
import Analytics, {EVENTS} from "../../services/Analytics";
import {performGetViewerSettings, performSetViewerSettings} from "../../redux/auth/reducers/profileSlice";
import {ScreenSizeContext} from "../../context/ScreenSizeContext";

const TutorialScreenV2 = ({
                              dispatch,
                              navigation,
                              route,
                              userAccount,
                              tutorialScreens,
                              settings,
                          }) => {
    const loadingContext = useContext(LoadingContext);
    const screenSizeContext = useContext(ScreenSizeContext);
    const [visibleTutorialScreens, setVisibleTutorialScreens] = useState([]);
    const [step, setStep] = useState(null);
    const [initialStepSet, setInitialStepSet] = useState(false);
    const [currentTutorialScreen, setCurrentTutorialScreen] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const skipFirstTimeChecks = route.params && route.params.reset;
    const backScreen = route.params && route.params.back ? route.params.back : 'Home';
    const backScreenParams = route.params && route.params.params ? route.params.params : undefined;
    const fromInGameScore = route.params && route.params.from_score;
    const gameId = route.params && route.params.game_id ? route.params.game_id : undefined;
    const frameSizeWidth = screenSizeContext.isMobile ? 375 : 1024;
    const frameSizeHeight = screenSizeContext.isMobile ? 600 : 680;
    const imageUrlPrefix = `https://s3.amazonaws.com/assets.hffe.fun/tutorial_screens/`;

    useFocusEffect(
        useCallback(() => {
            zE('webWidget', 'show');
            return () => {
                navigation.setParams({from_score: undefined, game_id: undefined});
                setVisibleTutorialScreens([]);
            };
        }, [navigation])
    );

    useFocusEffect(
        useCallback(() => {
            loadingContext.setScreenLoading(false);
        }, [])
    );

    useFocusEffect(
        useCallback(() => {
            if (userAccount && !skipFirstTimeChecks) {
                dispatch(fetchViewerTutorialScreens());
                dispatch(performGetViewerSettings());
            }
        }, [userAccount])
    );

    useFocusEffect(
        useCallback(() => {
            if (skipFirstTimeChecks) {
                setStep(0);
            }
        }, [skipFirstTimeChecks, route, route.params])
    );

    useFocusEffect(
        useCallback(() => {
            if (tutorialScreens && visibleTutorialScreens.length === 0) {
                setVisibleTutorialScreens(tutorialScreens.filter(ts => {
                    const sv = JSON.parse(ts.tutorialScreen.screenVisibility);
                    return skipFirstTimeChecks ? sv?.includes(2) : sv?.includes(1);
                }));
            }
        }, [skipFirstTimeChecks, tutorialScreens])
    );

    useEffect(() => {
        if (skipFirstTimeChecks) return;
        if (visibleTutorialScreens.length === 0 || initialStepSet) return;
        const index = visibleTutorialScreens.findIndex(t => !t.read);

        if (step === null || !initialStepSet) {
            if (index < 0 || index > visibleTutorialScreens.length) {
                navigation.navigate(backScreen, backScreenParams);
                return;
            } else {
                setStep(index);
                setInitialStepSet(true);
            }
        }
        if (settings && settings.showTutorial === false) {
            navigation.navigate(backScreen, backScreenParams);
        }
    }, [visibleTutorialScreens, settings]);

    useEffect(() => {
        if (step > visibleTutorialScreens.length) {
            navigation.navigate(backScreen, backScreenParams);
            return;
        }

        if (!skipFirstTimeChecks && visibleTutorialScreens && visibleTutorialScreens[step]) {
            dispatch(performReadTutorial(visibleTutorialScreens[step].tutorialScreen.id));
        }

        if (visibleTutorialScreens && visibleTutorialScreens[step]) {
            setCurrentTutorialScreen(visibleTutorialScreens[step].tutorialScreen);
            const tutorialScreen = visibleTutorialScreens[step].tutorialScreen;
            Analytics.logEvent(EVENTS.TUTORIAL_SCREEN_VIEW, {
                id: tutorialScreen.id,
                name: tutorialScreen.name,
            }).then();
        }

    }, [visibleTutorialScreens, step]);

    const handleClosePress = () => {
        if (fromInGameScore && gameId) {
            navigation.navigate('GameTable', {from_score: fromInGameScore, id: gameId});
        } else if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate('Home', {screen: 'ContinueGame'});
        }
    };

    const handleBackPress = () => {
        setImageLoaded(false);
        if (step > 0) {
            setStep(step - 1);
        }
    }

    const handleNextPress = () => {
        setImageLoaded(false);
        if (step < visibleTutorialScreens.length - 1) {
            setStep(step + 1);
        } else {
            if (fromInGameScore && gameId) {
                navigation.navigate('GameTable', {from_score: fromInGameScore, id: gameId});
            } else {
                navigation.navigate('Home', {screen: 'ContinueGame'});
            }
        }
    };

    return (
        <StyleContext.Consumer>
            {styles => (
                <SafeAreaView
                    style={[styles.safeArea, styles.container, {flexDirection: 'column', alignItems: 'stretch'}]}>
                    <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                        <Image source={blurredSuits} style={{width: '100%', height: '100%'}} resizeMode={'repeat'}/>
                    </View>
                    <View style={{...ownStyles.frameContainer, minWidth: 320, minHeight: frameSizeHeight}}>
                        <View style={{...ownStyles.frame, maxWidth: frameSizeWidth, height: frameSizeHeight,}}>

                            {currentTutorialScreen &&
                            <Image
                                source={{uri: `${imageUrlPrefix}${currentTutorialScreen.name}_${frameSizeWidth}x${frameSizeHeight}@2x.png`}}
                                style={{
                                    width: frameSizeWidth,
                                    height: frameSizeHeight,
                                    visibility: imageLoaded ? 'visible' : 'hidden'
                                }}
                                onLoad={() => setImageLoaded(true)}
                            />
                            }

                            {(!currentTutorialScreen || !imageLoaded) &&
                            <View style={{...ownStyles.loadingContainer}}>
                                <ActivityIndicator animating={true} color={Colors.onBackgroundBorder}/>
                            </View>
                            }

                            <TouchableOpacity style={ownStyles.close} onPress={handleClosePress}>
                                <Image source={close} style={ownStyles.closeImg}/>
                            </TouchableOpacity>

                            {currentTutorialScreen?.isFirst && imageLoaded &&
                            <View style={{position: 'absolute', bottom: 80, alignItems: 'center'}}>
                                <TouchableOpacity style={ownStyles.button} onPress={handleNextPress}>
                                    <Text style={{color: 'white'}}>Start now!</Text>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => {
                                    if (!skipFirstTimeChecks)
                                        dispatch(performSetViewerSettings({...settings, showTutorial: false}));
                                    handleClosePress();
                                }}>
                                    <View style={ownStyles.bottomContainer}>
                                        <StandardText style={{weight: 700}}>I'm an expert, </StandardText>
                                        <StandardText>I don't need this.</StandardText>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            }

                            {currentTutorialScreen?.isFirst === false && imageLoaded &&
                            <View style={{...ownStyles.backNextButtonsContainer}}>

                                {visibleTutorialScreens[0]?.tutorialScreen.id !== currentTutorialScreen?.id && (
                                    <TouchableOpacity style={[ownStyles.button, ownStyles.back]}
                                                  onPress={handleBackPress}>
                                        <StandardText>Back</StandardText>
                                    </TouchableOpacity>
                                )}

                                <TouchableOpacity style={ownStyles.button} onPress={handleNextPress}>
                                    <StandardText
                                        style={{color: 'white'}}>{currentTutorialScreen?.isLast ? 'Let\'s Play': 'Next'}</StandardText>
                                </TouchableOpacity>
                            </View>}
                        </View>
                    </View>
                </SafeAreaView>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        tutorialScreens: state.viewer.tutorial.tutorialScreens,
        settings: state.auth.profile.settings,
    }
};

export default connect(mapStateToProps)(TutorialScreenV2);

const ownStyles = {
    frameContainer: {
        minWidth: 320,
        alignItems: 'center',
        justifyContent: 'center'
    },
    frame: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        borderWidth: 1,
        backgroundColor: '#F0F0F0',
        borderColor: Colors.onBackgroundBorder2,
        borderRadius: 8,
        boxShadow: '0px 12px 16px rgba(87, 136, 200, 0.25)',
        alignItems: 'center', justifyContent: 'center'
    },
    loadingContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    close: {
        height: 40,
        zIndex: 40,
        position: 'absolute',
        top: 10,
        left: 10,
    },
    closeImg: {
        width: 40,
        height: 40,
        resizeMode: 'contain'
    },
    button: {
        backgroundColor: Colors.primary4,
        height: 40,
        width: 150,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        marginHorizontal: 20,
        boxShadow: '0px 12px 16px rgba(87, 136, 200, 0.25)'
    },
    backNextButtonsContainer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    back: {
        backgroundColor: 'white',
    },
    bottomContainer: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#76A1E4',
        paddingBottom: 5
    },
};
