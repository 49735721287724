import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Svg, { Path, Rect } from 'react-native-svg';
import LoadingButton from '../../sharedComponents/loadingButton/LoadingButton';
import StandardText from '../../sharedComponents/standardText/StandardText';
import Colors from '../../styles/colors';
import Spacing from '../../styles/spacing';

const AskPartnerToGoOut = ({ viewer, partner, opponent1, opponent2, onKeepPlayingPress, onAcceptPress, dismissable, onDismissPress }) => {

    const renderWaitingAnswer = (p) => {
        return <StandardText style={{ ...ownStyles.statusText }}>You asked {p.firstName} to go out.{'\n\n'}Waiting for response...</StandardText>
    }

    const renderGotAnswer = (askStatus, p) => {
        if (askStatus === 2) {
            return <StandardText style={{ ...ownStyles.statusText }}>{p.firstName} requests that you go out.{'\n\n'}It is up to you if you want to keep playing or go out.</StandardText>
        } else if (askStatus === 3) {
            return <StandardText style={{ ...ownStyles.statusText }}>{p.firstName} would like to keep playing.{'\n\n'}It is up to you if you want to keep playing or go out.</StandardText>
        }
    }

    const renderAnswerButtons = (p) => {
        return (
            <View>
                <StandardText style={{ ...ownStyles.statusText, marginBottom: 20 }}>{p.firstName} is asking to go out.</StandardText>
                <LoadingButton label={'Keep playing'} onPress={onKeepPlayingPress} showChevron={false} />
                <LoadingButton label={'Accept, go out'} onPress={onAcceptPress} backgroundColor={Colors.primary6} />
            </View>
        )
    }

    const renderViewerAnswer = (askStatus, p) => {
        if (askStatus === 2) {
            return <StandardText style={{ ...ownStyles.statusText }}>You said you would like {p.firstName} to go out.{'\n\n'}It is up to {p.firstName} to keep playing or go out.</StandardText>
        } else if (askStatus === 3) {
            return <StandardText style={{ ...ownStyles.statusText }}>You said you would like to keep playing.{'\n\n'}It is up to {p.firstName} to keep playing or go out.</StandardText>
        }
    }

    const renderWaitingAnswerOpponent = (playerAsking, playerAnswering) => {
        return <StandardText style={{ ...ownStyles.statusText }}>{playerAsking.firstName} asked {playerAnswering.firstName} to go out.{'\n\n'}Waiting for response...</StandardText>
    }

    const renderGotAnswerOpponent = (askStatus, playerAsking, playerAnswering) => {
        if (askStatus === 2) {
            return <StandardText style={{ ...ownStyles.statusText }}>{playerAnswering.firstName} requests that {playerAsking.firstName} goes out.{'\n\n'}It is up to {playerAsking.firstName} to keep playing or go out.</StandardText>
        } else if (askStatus === 3) {
            return <StandardText style={{ ...ownStyles.statusText }}>{playerAnswering.firstName} would like to keep playing.{'\n\n'}It is up to {playerAsking.firstName} to keep playing or go out.</StandardText>
        }
    }

    const viewerAskStatus = viewer.askPartnerToGoOutStatus;
    const partnerAskStatus = partner.askPartnerToGoOutStatus;
    const opponent1AskStatus = opponent1.askPartnerToGoOutStatus;
    const opponent2AskStatus = opponent2.askPartnerToGoOutStatus;

    return (
        <View style={{ padding: Spacing.large, paddingTop: 40, width: '100%', alignItems: 'center' }}>
            {dismissable &&
                <View style={{ position: 'absolute', right: 10, top: 10 }}>
                    <TouchableOpacity onPress={onDismissPress}>
                        <Svg width="26" height="26" viewBox="0 0 24 24" fill="none">
                            <Rect width="24" height="24" rx="0.6" fill="#11204A" />
                            <Path
                                d="M6.48481 8.18208C6.2505 7.94776 6.2505 7.56786 6.48481 7.33355L7.33334 6.48502C7.56765 6.25071 7.94755 6.25071 8.18187 6.48502L17.5157 15.8188C17.75 16.0531 17.75 16.433 17.5157 16.6674L16.6671 17.5159C16.4328 17.7502 16.0529 17.7502 15.8186 17.5159L6.48481 8.18208Z"
                                fill="white" />
                            <Path
                                d="M15.8189 6.48481C16.0532 6.2505 16.4331 6.2505 16.6674 6.48481L17.516 7.33334C17.7503 7.56765 17.7503 7.94755 17.516 8.18187L8.18215 17.5157C7.94783 17.75 7.56793 17.75 7.33362 17.5157L6.48509 16.6671C6.25077 16.4328 6.25077 16.0529 6.48509 15.8186L15.8189 6.48481Z"
                                fill="white" />
                        </Svg>
                    </TouchableOpacity>
                </View>
            }

            {/* Viewer is asking */}
            {viewerAskStatus === 1 && renderWaitingAnswer(partner)}
            {[2, 3].includes(viewerAskStatus) && renderGotAnswer(viewerAskStatus, partner)}

            {/* Viewer has been asked */}
            {viewerAskStatus === 0 && partnerAskStatus === 1 && renderAnswerButtons(partner)}
            {viewerAskStatus === 0 && [2, 3].includes(partnerAskStatus) && renderViewerAnswer(partnerAskStatus, partner)}

            {/* Opponent 1 is asking */}
            {opponent1AskStatus === 1 && renderWaitingAnswerOpponent(opponent1, opponent2)}
            {[2, 3].includes(opponent1AskStatus) && renderGotAnswerOpponent(opponent1AskStatus, opponent1, opponent2)}

            {/* Opponent 1 has been asked */}
            {opponent2AskStatus === 1 && renderWaitingAnswerOpponent(opponent2, opponent1)}
            {[2, 3].includes(opponent2AskStatus) && renderGotAnswerOpponent(opponent2AskStatus, opponent2, opponent1)}
        </View>
    );
};

const ownStyles = {
    statusText: {
        fontSize: 20,
        lineHeight: 32,
        weight: 500,
        textAlign: 'center'
    }
};

export default AskPartnerToGoOut;