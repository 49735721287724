import {
    CLEAR_LOGIN_ERROR,
    FAIL_LOCAL_LOGIN,
    LOGOUT, RECEIVE_CHANGE_PASSWORD,
    RECEIVE_COMPLETE_USER_ACCOUNT_INFO, RECEIVE_FAILED_CHANGE_PASSWORD,
    RECEIVE_FAILED_COMPLETE_USER_ACCOUNT_INFO,
    RECEIVE_FAILED_JOIN_FROM_GAME_TOKEN,
    RECEIVE_FAILED_LOGIN, RECEIVE_FAILED_REQUEST_CHANGE_PASSWORD,
    RECEIVE_JOIN_FROM_GAME_TOKEN,
    RECEIVE_LOCAL_LOGIN,
    RECEIVE_LOGIN, RECEIVE_REQUEST_CHANGE_PASSWORD,
    RECEIVE_USER_ACCOUNT, SUBMIT_CHANGE_PASSWORD,
    SUBMIT_COMPLETE_USER_ACCOUNT_INFO,
    SUBMIT_JOIN_FROM_GAME_TOKEN,
    SUBMIT_LOCAL_LOGIN,
    SUBMIT_LOGIN, SUBMIT_REQUEST_CHANGE_PASSWORD
} from "../actions/login";

const login = (
    state = {
        isLoggingIn: false,
        userAccount: null,
        accessToken: null,
        refreshToken: null,
        newUser: null,
        error: null,
        localLoginFailed: false,
        joinedGameId: null,
        isCompletingUserAccountInfo: false,
        isRequestingChangePassword: false,
        isChangingPassword: false,
        changePasswordError: null,
    }
    , action) => {
    switch (action.type) {
        case SUBMIT_LOGIN:
            return Object.assign({}, state, {
                isLoggingIn: true,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: null,
                joinedGameId: null,
            });
        case RECEIVE_LOGIN:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: action.payload.userAccount,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                newUser: action.payload.newUser,
                error: null,
                localLoginFailed: false,
                joinedGameId: null,
            });
        case RECEIVE_USER_ACCOUNT:
            return Object.assign({}, state, {
                userAccount: action.payload.userAccount,
            });
        case RECEIVE_FAILED_LOGIN:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: action.payload.error,
                joinedGameId: null,
            });
        case LOGOUT:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: null,
                localLoginFailed: true,
                joinedGameId: null,
            });
        case SUBMIT_LOCAL_LOGIN:
            return Object.assign({}, state, {
                isLoggingIn: true,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: null,
                joinedGameId: null,
            });
        case RECEIVE_LOCAL_LOGIN:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: action.payload.userAccount,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                newUser: action.payload.newUser,
                error: null,
                localLoginFailed: false,
                joinedGameId: null,
            });
        case FAIL_LOCAL_LOGIN:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: null,
                localLoginFailed: true,
                joinedGameId: null,
            });
        case SUBMIT_REQUEST_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isRequestingChangePassword: true,
            });
        case RECEIVE_REQUEST_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isRequestingChangePassword: false,
            });
        case RECEIVE_FAILED_REQUEST_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isRequestingChangePassword: false,
            });
        case SUBMIT_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isChangingPassword: true,
            });
        case RECEIVE_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isChangingPassword: false,
            });
        case RECEIVE_FAILED_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isChangingPassword: false,
                changePasswordError: action.payload.error
            });
        case SUBMIT_JOIN_FROM_GAME_TOKEN:
            return Object.assign({}, state, {
                isLoggingIn: true,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: null,
                joinedGameId: null,
            });
        case RECEIVE_JOIN_FROM_GAME_TOKEN:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: action.payload.userAccount,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                newUser: action.payload.newUser,
                error: null,
                localLoginFailed: false,
                joinedGameId: action.payload.joinedGameId,
            });
        case RECEIVE_FAILED_JOIN_FROM_GAME_TOKEN:
            return Object.assign({}, state, {
                isLoggingIn: false,
                userAccount: null,
                accessToken: null,
                refreshToken: null,
                newUser: null,
                error: action.payload.error,
                joinedGameId: null,
            });
        case SUBMIT_COMPLETE_USER_ACCOUNT_INFO:
            return Object.assign({}, state, {
                isCompletingUserAccountInfo: true,
                error: null
            });
        case RECEIVE_COMPLETE_USER_ACCOUNT_INFO:
            return Object.assign({}, state, {
                isCompletingUserAccountInfo: false,
                userAccount: action.payload.userAccount,
                error: null
            });
        case RECEIVE_FAILED_COMPLETE_USER_ACCOUNT_INFO:
            return Object.assign({}, state, {
                isCompletingUserAccountInfo: false,
                error: action.payload.error
            });
        case CLEAR_LOGIN_ERROR:
            return Object.assign({}, state, {
                error: null
            });
        default:
            return state;
    }
};

export default login;
