import React, { useEffect, useRef, useState } from 'react';
import { View } from "react-native";
import GameHelper from '../GameHelper';
import FlagIcon from '../../icons/FlagIcon';
import StandardText from '../../sharedComponents/standardText/StandardText';
import Colors from '../../styles/colors';
import Spacing from '../../styles/spacing';
import PlayerBadge from './PlayerBadge';
import CanastasIndicator from "./CanastasIndicator";
import CheckIcon from "../../icons/CheckIcon";

const ScoreTable = ({ game, bareBadges = true, showCanastas = false }) => {

    const rounds = [1, 2, 3, 4];
    const sortedTeams = game.gameTeams.slice().sort((a, b) => a.id - b.id);

    let leadingTeamScore = null;
    let leadingTeamIndex = null;
    let previousTeamScore = null;
    let tied = true;
    game.gameTeams.forEach((team, i) => {
        const teamTotalScore = team.gameTeamScores.reduce((acc, gts) => acc + gts.baseCount + gts.secondCount + gts.bonusCount, 0);
        if (leadingTeamScore === null || teamTotalScore > leadingTeamScore) {
            leadingTeamScore = teamTotalScore;
            leadingTeamIndex = i;
        }

        tied = tied && (previousTeamScore === null || (teamTotalScore === previousTeamScore));
        previousTeamScore = teamTotalScore;

        return teamTotalScore;
    });

    const addThousandsCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <View style={{ ...ownStyles.table }}>
            <View>
                <View style={{ ...ownStyles.tableRow }}>
                    <View style={{ ...ownStyles.tableHeaderColumn, alignItems: 'start' }}>
                        <StandardText style={{ ...ownStyles.tableHeaderText }}>SCORE</StandardText>
                        <StandardText style={{ ...ownStyles.tableHeaderText }}>BOARD</StandardText>
                    </View>
                    {
                        sortedTeams.map((team) => (
                            <View key={team.id} style={{...ownStyles.tableHeaderColumn, alignItems: 'center'}}>
                                <View style={{flexDirection: 'row', justifyContent: 'center' }} >
                                    {team.teamPlayers.map((player) => (
                                        <View key={player.id} style={{marginLeft: 5, marginRight: 5}}>
                                            <PlayerBadge player={player} bare={bareBadges} customPlainColor={game.isRoundFinished ? Colors.onBackground : null} active={(game.isRoundFinished && !player.joinedRound) || player.isCurrentTurn} next={player.isNextTurn} />
                                            {showCanastas && (
                                                <View style={{marginTop: 10}}>
                                                    <StandardText style={{fontSize: 10, textAlign: 'center', color: 'rgba(160, 166, 183, 1)'}}>{game.hasStarted && player.isNextTurn ? 'NEXT' : ' '}</StandardText>
                                                </View>
                                            )}
                                            {game.isRoundFinished && player.joinedRound &&
                                            <View style={{...ownStyles.joinedCheckContainer}}>
                                                <CheckIcon color={Colors.onBackground} size={10} />
                                            </View>
                                            }
                                        </View>
                                    ))}
                                </View>
                                {showCanastas && (
                                    <View style={{marginTop: 0}}>
                                        <CanastasIndicator totalPlayers={GameHelper.getAllPlayers(game).length} team={team} gameConfig={game.gameConfig} />
                                    </View>
                                )}
                            </View>
                        ))
                    }
                </View>
            </View>
            {
                rounds.map((round) => {
                    const baseCounts = sortedTeams.map((team) => {
                        const roundScore = team.gameTeamScores.find((teamScore) => teamScore.round === round)
                        return roundScore ? roundScore.baseCount : '-';
                    });

                    const secondCounts = sortedTeams.map((team) => {
                        const roundScore = team.gameTeamScores.find((teamScore) => teamScore.round === round)
                        return roundScore ? roundScore.secondCount : '-';
                    });

                    const bonusCounts = sortedTeams.map((team) => {
                        const roundScore = team.gameTeamScores.find((teamScore) => teamScore.round === round)
                        return roundScore ? roundScore.bonusCount : '-';
                    });

                    let highestScore = null;
                    let highestScoreIndex = null;
                    const roundTotalCounts = sortedTeams.map((team, j) => {
                        const roundScore = team.gameTeamScores.find((teamScore) => teamScore.round === round);

                        const totalScore = roundScore ? roundScore.bonusCount + roundScore.baseCount + roundScore.secondCount : '-';

                        if (totalScore !== '-' && (highestScore === null || totalScore > highestScore)) {
                            highestScore = totalScore;
                            highestScoreIndex = j;
                        }

                        return roundScore ? roundScore.bonusCount + roundScore.baseCount + roundScore.secondCount : '-';
                    });

                    return (
                        <View key={round} style={{ ...ownStyles.tableRoundGroup }}>
                            {(round <= game.previousRound || game.hasEnded) &&
                                <View style={{ ...ownStyles.tableRow }}>
                                    <View style={{ ...ownStyles.tableColumn, ...ownStyles.labelColumn }}>
                                        <StandardText style={{ ...ownStyles.cellText }}>Base</StandardText>
                                    </View>
                                    {
                                        baseCounts.map((baseCount, i) => (
                                            <View style={{ ...ownStyles.tableColumn }} key={`${round}-${i}`}>
                                                <StandardText style={{ ...ownStyles.cellText }}>{addThousandsCommas(baseCount)}</StandardText>
                                            </View>
                                        ))
                                    }
                                </View>
                            }
                            {(round <= game.previousRound || game.hasEnded) &&
                                <View style={{ ...ownStyles.tableRow }}>
                                    <View style={{ ...ownStyles.tableColumn, ...ownStyles.labelColumn }}>
                                        <StandardText style={{ ...ownStyles.cellText }}>Count</StandardText>
                                    </View>
                                    {
                                        secondCounts.map((secondCount, i) => (
                                            <View style={{ ...ownStyles.tableColumn }} key={`${round}-${i}`}>
                                                <StandardText style={{ ...ownStyles.cellText }}>{addThousandsCommas(secondCount)}</StandardText>
                                            </View>
                                        ))
                                    }
                                </View>
                            }
                            {(round <= game.previousRound || game.hasEnded) &&
                                <View style={{ ...ownStyles.tableRow }}>
                                    <View style={{ ...ownStyles.tableColumn, ...ownStyles.labelColumn, }}>
                                        <StandardText style={{ ...ownStyles.cellText }}>Bonus</StandardText>
                                    </View>
                                    {
                                        bonusCounts.map((bonusCount, i) => (
                                            <View style={{ ...ownStyles.tableColumn }} key={`${round}-${i}`}>
                                                <StandardText style={{ ...ownStyles.cellText }}>
                                                    {addThousandsCommas(bonusCount)}
                                                    {bonusCounts.reduce((acc, bc) => acc && bc == 0, true) && ' (stalemate)'}
                                                    </StandardText>
                                            </View>
                                        ))
                                    }
                                </View>
                            }
                            <View style={{ ...ownStyles.tableRow, ...(round <= game.previousRound || game.hasEnded ? ownStyles.previousRoundRow : {}) }}>
                                <View style={{ ...ownStyles.tableColumn, ...ownStyles.labelColumn }}>
                                    <StandardText style={{ ...ownStyles.cellText, ...ownStyles.labelText, ...(round <= game.previousRound || game.hasEnded ? ownStyles.previousRoundText : {}), ...(round > game.previousRound && !game.hasEnded ? ownStyles.futureRoundText : {}) }}>{`Round ${GameHelper.getRoundPoints(round)}`}</StandardText>
                                </View>
                                {
                                    roundTotalCounts.map((roundTotalCount, i) => (
                                        <View style={{ ...ownStyles.tableColumn }} key={`${round}-${i}`}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                <StandardText style={{ ...ownStyles.cellText, ...(round <= game.previousRound || game.hasEnded ? ownStyles.previousRoundText : {}), ...(round > game.previousRound && !game.hasEnded ? ownStyles.futureRoundText : {}), ...(!tied && highestScoreIndex === i ? ownStyles.highestScoreText : {}) }}>{addThousandsCommas(roundTotalCount)}</StandardText>
                                                {!tied && highestScoreIndex === i && (
                                                    <View style={{ marginLeft: 5 }}>
                                                        <FlagIcon size={16} color={leadingTeamIndex === i ? Colors.success2 : Colors.success3} />
                                                    </View>
                                                )}
                                            </View>
                                        </View>
                                    ))
                                }
                            </View>
                        </View>
                    );
                })
            }
        </View>
    );
};

const ownStyles = {
    table: {
        flexDirection: 'column',
        alignSelf: 'stretch'
    },
    tableRoundGroup: {
        alignItems: 'stretch',
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: Spacing.small,
        paddingBottom: Spacing.small,
        paddingLeft: Spacing.base,
        paddingRight: Spacing.base,
        borderRadius: 2
    },
    tableHeaderText: {
        weight: 700
    },
    previousRoundRow: {
        backgroundColor: 'rgba(113, 128, 170, 1)'
    },
    tableHeaderColumn: {
        flex: 1,
        alignItems: 'center'
    },
    tableColumn: {
        flex: 1,
        textAlign: 'center',
    },
    labelColumn: {
        textAlign: 'start'
    },
    cellText: {
        fontSize: 16,
        weight: 400,
        color: 'rgba(77, 77, 77, 1)'
    },
    labelText: {
        weight: 500
    },
    previousRoundText: {
        color: Colors.background,
        weight: 500
    },
    futureRoundText: {
        color: 'rgba(152, 152, 152, 1)'
    },
    highestScoreText: {
        color: Colors.success2,
        marginLeft: 21
    },
    joinedCheckContainer: {
        width: 17,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: -2,
        right: -2,
        padding: 2,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: Colors.success2,
        backgroundColor: Colors.success2
    }
};

export default React.memo(ScoreTable); 
