import {Keyboard, TextInput, TouchableOpacity, View} from "react-native";
import React, {useCallback, useContext, useState} from "react";
import StyleContext from "../../StyleContext";
import LogoLarge from "../../icons/LogoLarge";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import {connect} from "react-redux";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {performRequestChangePassword} from "../../redux/auth/actions/login";
import {useNavigation} from "@react-navigation/native";
import PlainButton from "../../sharedComponents/plainButton/PlainButton";

const RequestChangePasswordScreen = ({dispatch, userAccount, localLoginFailed, loadingRequestChangePassword}) => {

    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);
    const navigation = useNavigation();

    const [email, setEmail] = useState('');
    const [localError, setLocalError] = useState('');
    const [emailRequested, setEmailRequested] = useState(null);

    useFocusEffect(useCallback(() => {
        if (!userAccount && localLoginFailed) {
            loadingContext.setScreenLoading(false);
        }
    }, [userAccount, localLoginFailed]));

    useFocusEffect(useCallback(() => {
        if (emailRequested && !loadingRequestChangePassword) {
            setEmail('');
        }
    }, [loadingRequestChangePassword, emailRequested]));

    const handleSubmit = async () => {
        Keyboard.dismiss();

        if (email) {
            setLocalError('');
            setEmailRequested(email);
            dispatch(performRequestChangePassword(email));
        } else {
            setLocalError("Please fill your email address");
            setEmailRequested(null);
        }
    };

    return (
        (!loadingContext.isLoading) &&
        <View style={styleContext.safeArea}>
            <View style={{width: '100%', flex: 1, alignItems: 'center', justifyContent: 'center'}}>

                <View style={{...ownStyles.container}}>

                    <View style={{marginTop: -15}}>
                        <LogoLarge size={198}/>
                    </View>
                    <StandardText style={{...styleContext.subheader, color: Colors.onBackground3, marginTop: 10}}>When
                        you play,
                        you're family™</StandardText>

                    <StandardText style={{...ownStyles.buttonsTitle, color: Colors.onBackground4}}>
                        Request Password Reset
                    </StandardText>

                    <View style={{...ownStyles.buttonsContainer}}>

                        {(!emailRequested || loadingRequestChangePassword) &&
                        <View>
                            <StandardText style={{marginBottom: 20, lineHeight: 24}}>
                                Did you forget your password? No worries, just enter your email address below we will
                                email you directions.
                            </StandardText>

                            <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular'}]}
                                       value={email}
                                       placeholder={'Email'}
                                       onChangeText={(text => {
                                           setEmail(text)
                                       })} onSubmitEditing={handleSubmit}
                                       placeholderTextColor={'rgba(112, 121, 143, 0.6)'}/>

                            <LoadingButton label={'Request Password Reset'} onPress={handleSubmit}
                                           loading={loadingRequestChangePassword} disabled={!email}/>

                            <View style={{justifyContent: 'center'}}>
                                {(localError) && <StandardText
                                    style={[ownStyles.errorMessage, {margin: 5}]}>{localError}</StandardText>}
                            </View>
                        </View>
                        }

                        <View style={{justifyContent: 'center'}}>
                            {!!emailRequested && !loadingRequestChangePassword && <StandardText
                                style={{}}>If the email address you entered has an account
                                in our system we will send you an email that contains a link you must press to reset
                                your password.</StandardText>}
                        </View>

                        <PlainButton fontSize={14} onPress={() => navigation.navigate('Start')} label={'Go to Log In'}/>

                    </View>

                </View>

            </View>
        </View>
    );
};

const ownStyles = {
    container: {
        width: 300
    },
    buttonsTitle: {
        fontSize: 24,
        weight: 500,
        marginTop: 35,
        marginBottom: 10
    },
    buttonsContainer: {
        height: 400,
        alignItems: 'center'
    },
    bottomTextContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    bottomText: {
        fontSize: 14,
        color: Colors.onBackground
    },
    errorMessage: {
        color: Colors.redCard,
        fontSize: 14,
        textAlign: 'center',
    },
    resultMessage: {
        fontSize: 14,
        color: Colors.onBackground
    }
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null,
        localLoginFailed: state.auth.login.localLoginFailed,
        loadingRequestChangePassword: state.auth.login.isRequestingChangePassword,
    }
};

export default connect(mapStateToProps)(RequestChangePasswordScreen);
