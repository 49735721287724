import React, {useEffect, useRef, useState} from 'react';
import {Animated, Easing, TouchableOpacity, View} from "react-native";
import Colors from '../../styles/colors';
import StandardText from '../../sharedComponents/standardText/StandardText';
import FootIcon from '../../icons/FootIcon';

const PlayerBadge = ({
                         player,
                         size = 36,
                         fontSize = 16,
                         gapSize = 2,
                         active = false,
                         next = false,
                         bare = false,
                         showBottomInfo = true,
                         activePlainColor = null,
                         inactivePlainColor = null,
                         showBackgroundOnActive = false,
                         showBackgroundOnInactive = true,
                     }) => {

    const animationValue = useRef(new Animated.Value(0)).current;
    const animationRef = useRef(null);
    const rotateInterpolation = animationValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    });

    const animation = () => {
        animationRef.current = Animated.loop(
            Animated.timing(animationValue,
                {
                    toValue: 1,
                    duration: 1000,
                    delay: 0,
                    easing: Easing.linear,
                }
            ),
        );

        animationRef.current.start(() => {
            if (animation.current && animation.current.isFinished()) {
                animation();
            }
        });
    };

    useEffect(() => {
        if (active) {
            animation();
        } else {
            if (animationRef.current) {
                animationRef.current.stop();
            }
        }
    }, [player, active, animationRef.current]);

    return (
        <View style={{}}>

            <Animated.View style={{
                ...ownStyles.badgeBorder,
                width: size,
                height: size,
                ...(active ? ownStyles.badgeBorderActive : (next ? ownStyles.badgeBorderNext : {})),
                ...(bare ? ownStyles.badgeBorderBare : {}),
                ...(active && activePlainColor ? {background: 'conic-gradient(from -45deg at 50% 50%, #FFFFFF 0deg, ' + activePlainColor + ' 360deg)'} : {}),
                ...(!active && inactivePlainColor ? {background: inactivePlainColor} : {}),
                transform: [{rotate: rotateInterpolation}]
            }}>
            </Animated.View>

            <View style={{
                ...ownStyles.badge,
                borderWidth: gapSize,
                width: size - 4,
                height: size - 4,
                ...(active ? ownStyles.badgeActive : (next ? ownStyles.badgeNext : {})),
                ...(active && activePlainColor ? {background: 'white'} : {}),
                ...(showBackgroundOnActive && active && activePlainColor ? {background: `linear-gradient(180deg, ${activePlainColor} 0%, white 140%)`} : {}),
                ...(showBackgroundOnInactive && !active && inactivePlainColor ? {background: `linear-gradient(180deg, ${inactivePlainColor} 0%, white 100%)`} : {})
            }}>
                <StandardText style={{
                    fontSize: fontSize,
                    color: active || next ? Colors.background : 'rgba(160, 166, 183, 1)',
                    ...(bare ? {color: Colors.onBackground} : {}),
                    ...(active && activePlainColor ? {color: activePlainColor} : {}),
                    ...(!active && inactivePlainColor ? {color: Colors.onPrimary} : {}),
                    ...(showBackgroundOnActive && active && activePlainColor ? {color: Colors.onPrimary} : {}),
                    ...(!showBackgroundOnInactive && !active && inactivePlainColor ? {color: inactivePlainColor} : {})
                }} weight={500}>
                    {player.firstName.substring(0, 1).toUpperCase()}
                    {player.isNameInitialRepeated &&
                    <StandardText style={{
                        fontSize: 10,
                        verticalAlign: 'super',
                        color: active || next ? Colors.background : 'rgba(160, 166, 183, 1)',
                        ...(bare ? {color: Colors.onBackground} : {}),
                        ...(active && activePlainColor ? {color: activePlainColor} : {}),
                        ...(!active && inactivePlainColor ? {color: Colors.onPrimary} : {}),
                        ...(showBackgroundOnActive && active && activePlainColor ? {color: Colors.onPrimary} : {}),
                        ...(!showBackgroundOnInactive && !active && inactivePlainColor ? {color: inactivePlainColor} : {})
                    }}>{player.seat}</StandardText>}
                </StandardText>
            </View>

            {!bare && showBottomInfo &&
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                position: 'absolute',
                bottom: -8,
                right: -4,
                borderWidth: 1,
                borderColor: Colors.onBackground,
                borderRadius: 100,
                height: 18,
                width: 45,
                backgroundColor: Colors.background
            }}>
                <View style={{width: 30, alignItems: 'center', top: -1}}>
                    <StandardText style={{fontSize: 15}}
                                  weight={500}>{player.hand?.handCards?.length ?? 0}</StandardText></View>
                <View
                    style={{position: 'absolute', top: -1, right: -1, ...(player.isPlayingFoot ? {opacity: 0.3} : {})}}>
                    <FootIcon size={18} color={Colors.onBackground}/>
                </View>
            </View>
            }
        </View>
    );
};

const ownStyles = {
    badgeBorder: {
        borderWidth: 0,
        background: 'rgba(160, 166, 183, 1)',
        borderRadius: '50%',
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center'
    },
    badgeBorderActive: {
        background: 'conic-gradient(from -45deg at 50% 50%, #FF4A6B 0deg, rgba(252, 189, 112, 0.3) 360deg)',
    },
    badgeBorderNext: {
        borderWidth: 2,
        borderColor: 'rgba(160, 166, 183, 0.5)',
        borderStyle: 'dashed',
        background: Colors.background
    },
    badgeBorderBare: {
        background: Colors.onBackground
    },
    badge: {
        position: 'absolute',
        top: 2,
        left: 2,
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.background,
        borderColor: Colors.background
    },
    badgeActive: {
        background: 'linear-gradient(180deg, #F3982A 0%, #FFCA89 100%)'
    },
    badgeNext: {
        background: 'rgba(160, 166, 183, 1)',
    }
};

export default React.memo(PlayerBadge); 
