import Api from "../../../services/Api";

export const REQUEST_VIEWER_FRIENDS = 'REQUEST_VIEWER_FRIENDS';
export const requestViewerFriends = (reset = false) => ({
    type: REQUEST_VIEWER_FRIENDS,
    payload: {
        reset: reset
    }
});

export const RECEIVE_VIEWER_FRIENDS = 'RECEIVE_VIEWER_FRIENDS';
export const receiveViewerFriends = (friends, reset = false) => ({
    type: RECEIVE_VIEWER_FRIENDS,
    payload: {
        friends: friends,
        reset: reset
    }
});

export const fetchViewerFriends = (cursor) => async (dispatch, getState) => {
    dispatch(requestViewerFriends(!cursor));

    const state = getState();
    try {
        const friends = await Api.viewerFriends(state.auth.login.accessToken, cursor);
        dispatch(receiveViewerFriends(friends, !cursor));
    } catch (e) {
        dispatch(receiveViewerFriends({edges: []}));
    }
};
