import React from 'react';
import {Svg, Path, Rect} from "react-native-svg";

const InfoIcon = ({ size, color }) => {
    return (
        <Svg width={size} viewBox="0 0 24 24" fill="none">
            <Rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" fill={color} stroke="white" strokeWidth="1.5"/>
            <Path d="M13.125 18H10.8594V9.54688H13.125V18ZM10.7266 7.35938C10.7266 7.02083 10.8385 6.74219 11.0625 6.52344C11.2917 6.30469 11.6016 6.19531 11.9922 6.19531C12.3776 6.19531 12.6849 6.30469 12.9141 6.52344C13.1432 6.74219 13.2578 7.02083 13.2578 7.35938C13.2578 7.70312 13.1406 7.98438 12.9062 8.20312C12.6771 8.42188 12.3724 8.53125 11.9922 8.53125C11.612 8.53125 11.3047 8.42188 11.0703 8.20312C10.8411 7.98438 10.7266 7.70312 10.7266 7.35938Z" fill="white"/>
        </Svg>

    );
};

export default InfoIcon;
