const Colors = {
    background: '#ffffff',
    inverseBackground: '#000000',
    backgroundSecondary: '#eeeeee',
    primary: '#5a80fb',
    primarySoft: '#98a7d7',
    error: '#ec5757',
    success: '#54c342',

    redCard: '#ee1506',
    blackCard: '#000000',
    disabled: '#e0e0e0',

    onBackground: '#000000',
    onInverseBackground: '#ffffff',
    onBackgroundBorder: '#999999',
    onBackgroundBorderSoft: '#CCCCCC',
    onPrimary: '#ffffff',
    onError: '#ffffff',
    onSuccess: '#ffffff'
};

export default Colors;
