import React from 'react';
import {Ellipse, G, Path, Rect, Svg} from 'react-native-svg';

const LogoNoText = ({ size }) => {
    return (
        <Svg width={size} viewBox="0 0 178 126" fill="none">
            <G>
                <Rect x="32.4277" y="106.115" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-106.497 32.4277 106.115)" fill="white" stroke="#FF4A6B" strokeWidth="1.20485"/>
                <Ellipse cx="24.147" cy="40.7341" rx="4.8298" ry="4.8298" transform="rotate(-16.4966 24.147 40.7341)" fill="#FF4A6B"/>
                <Ellipse cx="33.4087" cy="37.991" rx="4.8298" ry="4.8298" transform="rotate(-16.4966 33.4087 37.991)" fill="#FF4A6B"/>
                <Path d="M31.9679 51.1129L21.5882 44.8499L28.6257 38.848L37.7966 40.0498L32.5012 50.955C32.4044 51.1542 32.1575 51.2273 31.9679 51.1129Z" fill="#FF4A6B"/>
                <Path d="M54.8808 68.2742L56.1601 67.8953L57.6952 73.0788C57.9047 73.7861 57.8494 74.4136 57.5293 74.9612C57.2126 75.5078 56.6921 75.8884 55.9678 76.1029C55.1961 76.3314 54.5428 76.3132 54.0079 76.0483C53.4729 75.7833 53.1027 75.304 52.8972 74.6101L54.1766 74.2312C54.2928 74.6239 54.477 74.8914 54.7291 75.034C54.9835 75.1722 55.2952 75.1866 55.6641 75.0774C56.0093 74.9751 56.2499 74.7806 56.3858 74.4936C56.5241 74.2024 56.5336 73.8553 56.4143 73.4526L54.8808 68.2742Z" fill="#FF4A6B"/>
                <Rect x="45.6094" y="90.9688" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-87.0513 45.6094 90.9688)" fill="white" stroke="#11204A" strokeWidth="1.20485"/>
                <Path d="M64.0586 33.2393L63.7066 40.0724" stroke="#2F3C60" strokeWidth="1.00404"/>
                <Ellipse cx="68.8801" cy="33.4877" rx="4.8298" ry="4.8298" transform="rotate(-177.051 68.8801 33.4877)" fill="#2F3C60"/>
                <Rect x="62.1348" y="28.8418" width="4.29315" height="4.29315" transform="rotate(2.94867 62.1348 28.8418)" fill="#2F3C60"/>
                <Ellipse cx="59.2336" cy="32.9906" rx="4.8298" ry="4.8298" transform="rotate(-177.051 59.2336 32.9906)" fill="#2F3C60"/>
                <Path d="M65.0487 21.2039L72.6641 30.459L55.7821 29.5894L64.3082 21.1658C64.5182 20.9583 64.8611 20.9759 65.0487 21.2039Z" fill="#2F3C60"/>
                <Path d="M79.3787 62.7609L80.7113 62.8296L80.4332 68.2284C80.3952 68.9651 80.1342 69.5384 79.65 69.9482C79.1694 70.3582 78.5519 70.5438 77.7976 70.5049C76.9938 70.4635 76.3838 70.2289 75.9676 69.801C75.5514 69.3731 75.3618 68.7978 75.3991 68.0751L76.7316 68.1437C76.7105 68.5527 76.7951 68.8663 76.9853 69.0846C77.1792 69.2996 77.4683 69.417 77.8526 69.4368C78.2121 69.4553 78.5038 69.3519 78.7274 69.1266C78.9548 68.898 79.0793 68.5739 79.1009 68.1545L79.3787 62.7609Z" fill="#11204A"/>
                <Rect x="57.1406" y="79.9736" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-66.7461 57.1406 79.9736)" fill="white" stroke="#FF4A6B" strokeWidth="1.20485"/>
                <Path d="M89.5837 25.5295L99.4462 20.9809C99.6492 20.8873 99.8886 20.9902 99.9604 21.2019L103.449 31.4874C103.696 32.2171 103.353 33.016 102.653 33.3387L92.7905 37.8872C92.5875 37.9809 92.3481 37.878 92.2763 37.6663L88.7881 27.3808C88.5407 26.651 88.8839 25.8522 89.5837 25.5295Z" fill="#FF4A6B"/>
                <Path d="M98.6016 65.2371L99.8275 65.7639L97.6932 70.7307C97.4019 71.4085 96.9581 71.8556 96.3619 72.0719C95.7689 72.2897 95.1253 72.2494 94.4313 71.9512C93.6919 71.6334 93.2012 71.2017 92.9594 70.6559C92.7175 70.1102 92.7394 69.5049 93.0251 68.84L94.251 69.3668C94.0893 69.743 94.0598 70.0665 94.1624 70.3373C94.2697 70.6062 94.5001 70.8166 94.8536 70.9685C95.1844 71.1107 95.4938 71.1149 95.7817 70.9812C96.0743 70.8457 96.3035 70.585 96.4694 70.1991L98.6016 65.2371Z" fill="#FF4A6B"/>
                <Rect x="76.9629" y="76.7588" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-47.3009 76.9629 76.7588)" fill="white" stroke="#11204A" strokeWidth="1.20485"/>
                <Ellipse cx="133.518" cy="38.2565" rx="4.8298" ry="4.8298" transform="rotate(42.6991 133.518 38.2565)" fill="#2F3C60"/>
                <Ellipse cx="124.509" cy="40.8961" rx="4.8298" ry="4.8298" transform="rotate(42.6991 124.509 40.8961)" fill="#2F3C60"/>
                <Ellipse cx="131.608" cy="47.4469" rx="4.8298" ry="4.8298" transform="rotate(42.6991 131.608 47.4469)" fill="#2F3C60"/>
                <Rect x="129.393" y="39.5615" width="4.29315" height="4.29315" transform="rotate(42.6991 129.393 39.5615)" fill="#2F3C60"/>
                <Path d="M128.059 44.1719L123.419 49.2004" stroke="#2F3C60" strokeWidth="1.00404"/>
            </G>

        </Svg>

    );
};

export default LogoNoText;
