import React, {useEffect, useState} from "react";
import {ActivityIndicator, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import * as Linking from "expo-linking";
import {connect} from "react-redux";
import {performOAuthCodeLogin} from "../../redux/auth/actions/login";
import StyleContext from "../../StyleContext";
import { useNavigation } from '@react-navigation/native';

const OAuthRedirectLoginScreen = ({dispatch, provider, loginError}) => {

    const [dispatchedOAuthLogin, setDispatchedOAuthLogin] = useState(false);
    const navigation = useNavigation();

    useEffect(() => {
        if (dispatchedOAuthLogin === false) {
            setDispatchedOAuthLogin(true);
            Linking.getInitialURL().then((url) => {
                let newURL = Linking.parse(url);
                const code = newURL.queryParams.code;

                if (code) {
                    dispatch(performOAuthCodeLogin(provider, code));
                }
            });
        }
    }, [dispatchedOAuthLogin]);

    useEffect(() => {
        if (loginError) {
            navigation.navigate('Start');
        }
    }, [loginError]);

    return (
        <StyleContext.Consumer>
            {styles => (
                <View style={styles.safeArea}>
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', width: 600}}>
                        <StandardText>
                            <ActivityIndicator color={'#000000'} size={'small'}/>
                        </StandardText>
                    </View>
                </View>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null,
        loginError: state.auth.login.error
    }
};

export default connect(mapStateToProps)(OAuthRedirectLoginScreen);
