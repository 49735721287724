import AsyncStorage from '@react-native-community/async-storage';

export default class UserSession {

    static getTokenData = async () => {
        const storedTokenData = await AsyncStorage.getItem('tokenData');
        return storedTokenData ? JSON.parse(storedTokenData) : null;
    };

    static setTokenData = async (login) => {
        await AsyncStorage.setItem('tokenData', JSON.stringify(login));
    }

    static clearTokenData = async () => {
        await AsyncStorage.removeItem('tokenData');
    };

    static getNewGameSetup = async () => {
        const storedGameSetup = await AsyncStorage.getItem('gameSetup');
        return storedGameSetup ? JSON.parse(storedGameSetup) : null;
    };

    static setNewGameSetup = async (gameSetup) => {
        await AsyncStorage.setItem('gameSetup', JSON.stringify(gameSetup));
    }

    static clearNewGameSetup = async () => {
        await AsyncStorage.removeItem('gameSetup');
    };

    static setSubscription = async (subscription) => {
        await AsyncStorage.setItem('subscription', JSON.stringify(subscription));
    };

    static getSubscription = async () => {
        const storedSubscription = await AsyncStorage.getItem('subscription');
        return storedSubscription ? JSON.parse(storedSubscription) : null;
    };

    static clearSubscription = async () => {
        await AsyncStorage.removeItem('subscription');
    };

    static setLastSubscriptionCheck = async () => {
        await AsyncStorage.setItem('lastSubscriptionCheck', Date.now());
    };

    static getLastSubscriptionCheck = async () => {
        return await AsyncStorage.getItem('lastSubscriptionCheck');
    };

    static clearLastSubscriptionCheck = async () => {
        await AsyncStorage.removeItem('lastSubscriptionCheck');
    };

    static setWantsToSubscribe = async (id, redirected = false) => {
        await AsyncStorage.setItem('wantsToSubscribe', JSON.stringify({ id: id, redirected: redirected }));
    };

    static getWantsToSubscribe = async () => {
        const wantsToSubscribe = await AsyncStorage.getItem('wantsToSubscribe');
        return wantsToSubscribe ? JSON.parse(wantsToSubscribe) : null;
    };

    static clearWantsToSubscribe = async () => {
        await AsyncStorage.removeItem('wantsToSubscribe');
    };
}
