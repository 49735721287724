import {View} from "react-native";
import React from "react";
import Colors from "../../styles/colors";

const CanastasIndicator = ({totalPlayers, team, gameConfig}) => {

    const naturalCanastasLeftArray = [...Array(gameConfig.naturalCanastasNeeded + 1).keys()].splice(-gameConfig.naturalCanastasNeeded).reverse();
    const unnaturalCanastasLeftArray = [...Array(gameConfig.unnaturalCanastasNeeded + 1).keys()].splice(-gameConfig.unnaturalCanastasNeeded).reverse();
    const extraNaturalCanastas = Math.max(team.melds.filter(meld => meld.isNatural && meld.meldCards.length >= 7).length - naturalCanastasLeftArray.length, 0);
    const extraUnnaturalCanastas = Math.max(team.melds.filter(meld => !meld.isNatural && meld.meldCards.length >= 7).length - unnaturalCanastasLeftArray.length, 0);

    return (
        <View style={{...ownStyles.canastasContainer}}>
            {unnaturalCanastasLeftArray.map((e) => (
                <View key={'v-un-' + e}
                      style={{...ownStyles.canastaIndicator, ...ownStyles.unnaturalCanastaIndicator, ...((team.unnaturalCanastasLeft - e < 0) ? ownStyles.unnaturalCanastaIndicatorComplete : {})}}/>
            ))}

            {[...Array(extraUnnaturalCanastas)].map((e, k) => (
                <View key={'v-un-e-' + k}
                    style={{...ownStyles.canastaIndicator, ...ownStyles.unnaturalCanastaIndicator, ...ownStyles.unnaturalCanastaIndicatorComplete}}/>
            ))}

            {naturalCanastasLeftArray.map((e) => (
                <View key={'v-n-' + e}
                      style={{...ownStyles.canastaIndicator, ...ownStyles.naturalCanastaIndicator, ...((team.naturalCanastasLeft - e < 0) ? ownStyles.naturalCanastaIndicatorComplete : {})}}/>
            ))}

            {[...Array(extraNaturalCanastas)].map((e, k) => (
                <View key={'v-n-e-' + k}
                    style={{...ownStyles.canastaIndicator, ...ownStyles.naturalCanastaIndicator, ...ownStyles.naturalCanastaIndicatorComplete}}/>
            ))}
        </View>
    );
};

const ownStyles = {
    canastasContainer: {
        flexDirection: 'row',
        marginTop: 0,
        marginBottom: 10
    },
    canastaIndicator: {
        width: 5,
        height: 10,
        marginRight: 1,
        marginLeft: 1,
        borderWidth: 1,
        borderRadius: 0
    },
    unnaturalCanastaIndicator: {
        borderColor: Colors.blackCard,
    },
    naturalCanastaIndicator: {
        borderColor: Colors.redCard,
    },
    unnaturalCanastaIndicatorComplete: {
        backgroundColor: Colors.blackCard
    },
    naturalCanastaIndicatorComplete: {
        backgroundColor: Colors.redCard
    }
};

export default CanastasIndicator;
