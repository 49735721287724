import React from 'react';
import { View } from "react-native";
import Colors from '../../styles/colors';
import GameHelper from '../GameHelper';
import StandardText from '../../sharedComponents/standardText/StandardText';
import PlayerBadge from './PlayerBadge';
import CanastasIndicator from "./CanastasIndicator";

const TeamsInfo = ({ game }) => {

    const viewerTeam = GameHelper.getViewerTeam(game);
    const opponentTeam = GameHelper.getOpponentTeam(game);

    const naturalCanastasLeftArray = GameHelper.getAllPlayers(game).length === 2 ? [3, 2, 1] : [4, 3, 2, 1];
    const unnaturalCanastasLeftArray = GameHelper.getAllPlayers(game).length === 2 ? [4, 3, 2, 1] : [5, 4, 3, 2, 1];

    const renderTeamInfo = (team, isViewerTeam) => {
        return (
            <View style={{ flex: 1, alignItems: isViewerTeam ? 'flex-start' : 'flex-end', marginTop: 15 }}>
                <View style={{ alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', ...(team.teamPlayers.length > 1 ? { width: 85 } : {}) }}>
                        {team.teamPlayers.slice().sort((a, b) => a.seat - b.seat).map((player) => (
                            <View key={player.id} style={ownStyles.playerBadge}>
                                <View style={{marginBottom: 0}}>
                                    <StandardText style={{fontSize: 10, textAlign: 'center', color: 'rgba(160, 166, 183, 1)'}}>{game.hasStarted && player.isNextTurn ? 'NEXT' : ' '}</StandardText>
                                </View>
                                <PlayerBadge player={player} active={player.isCurrentTurn} next={game.hasStarted && player.isNextTurn} />
                            </View>
                        ))}
                    </View>
                    <StandardText style={{marginTop: 10, fontSize: 12, textAlign: 'center', color: 'rgba(160, 166, 183, 1)'}}>{team.partialCurrentScore}</StandardText>
                    <CanastasIndicator totalPlayers={GameHelper.getAllPlayers(game).length} team={team} gameConfig={game.gameConfig}/>
                </View>
            </View>
        );
    }

    return (
        <View style={{ ...ownStyles.container }}>
            {renderTeamInfo(viewerTeam, true)}

            <View style={{ flex: 1, alignItems: 'center' }}>
            </View>

            {renderTeamInfo(opponentTeam, false)}
        </View>
    );
};

const ownStyles = {
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15
    },
    playerBadge: {

    },
    canastasContainer: {
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 10
    },
    canastaIndicator: {
        width: 5,
        height: 10,
        marginRight: 2,
        borderWidth: 1,
        borderRadius: 0
    },
    unnaturalCanastaIndicator: {
        borderColor: Colors.blackCard,
    },
    naturalCanastaIndicator: {
        borderColor: Colors.redCard,
    },
    unnaturalCanastaIndicatorComplete: {
        backgroundColor: Colors.blackCard
    },
    naturalCanastaIndicatorComplete: {
        backgroundColor: Colors.redCard
    }
};

export default TeamsInfo;
