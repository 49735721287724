const GameAction = {
    DRAW: 'DRAW',
    PICK_FROM_DISCARD_PILE: 'PICK_FROM_DISCARD_PILE',
    DISCARD: 'DISCARD',
    MELD: 'MELD',
    UNMELD: 'UNMELD',
    UNMELD_ALL: 'UNMELD_ALL',
    JOIN_ROUND: 'JOIN_ROUND',
    SET_READY: 'SET_READY',
    START_BREAK: 'START_BREAK',
    FINISH_BREAK: 'FINISH_BREAK',
    ASK_PARTNER_TO_GO_OUT: 'ASK_PARTNER_TO_GO_OUT',
    ANSWER_PARTNER_TO_GO_OUT: 'ANSWER_PARTNER_TO_GO_OUT',
    MARK_ROUND_STALEMATE: 'MARK_ROUND_STALEMATE',
    CANCEL_MARK_ROUND_STALEMATE: 'CANCEL_MARK_ROUND_STALEMATE',
};

export default GameAction;
