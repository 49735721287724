import StandardText from "../../sharedComponents/standardText/StandardText";
import {Animated, View} from "react-native";
import React, {useEffect, useRef, useState} from "react";
import Colors from "../../styles/colors";
import ClubsIcon from "./suits/ClubsIcon";
import DiamondsIcon from "./suits/DiamondsIcon";
import HeartsIcon from "./suits/HeartsIcon";
import SpadesIcon from "./suits/SpadesIcon";
import JokerIcon from "./suits/JokerIcon";

const Card = ({card, isSelected = false, totalStacked = null}) => {

    const renderIcon = () => {
        switch (card.suit) {
            case 'c':
                return (<ClubsIcon size={20} color={'white'}/>);
            case 'd':
                return (<DiamondsIcon size={20} color={'white'}/>);
            case 'h':
                return (<HeartsIcon size={20} color={'white'}/>);
            case 's':
                return (<SpadesIcon size={20} color={'white'}/>);
            case 'joker':
                return;
        }
    }

    const renderJoker = () => {
        return (<JokerIcon size={20} color={'white'}/>)
    }

    return (
        <Animated.View
            style={[{...ownStyles.cardContainer}, ...(isSelected ? [ownStyles.selectedCard] : [ownStyles.unselectedCard]), {backgroundColor: card.isRed ? Colors.redCard : Colors.blackCard}]}>

            {(card.suit === 'joker' || card.rank === '2') &&
            <View style={{position: 'absolute', bottom: 0, left: 0, right: 0, top: 0}}>
                <StandardText style={{
                    position: 'absolute',
                    top: -2,
                    left: -18,
                    fontSize: 21,
                    weight: 900,
                    color: 'rgba(64, 48, 0, 0.15)'
                }}>WILD</StandardText>
                <StandardText style={{
                    position: 'absolute',
                    top: -2,
                    right: -30,
                    fontSize: 21,
                    weight: 900,
                    color: 'rgba(64, 48, 0, 0.15)'
                }}>WILD</StandardText>
                <StandardText style={{
                    position: 'absolute',
                    bottom: -2,
                    left: 6,
                    fontSize: 21,
                    weight: 900,
                    color: 'rgba(64, 48, 0, 0.15)'
                }}>WILD</StandardText>
            </View>
            }

            <StandardText weight={500} style={[ownStyles.cardText, ownStyles.cardTextRank, {
                color: card.isRed ? Colors.onRedCard : Colors.onBlackCard,
                position: 'absolute',
                top: 0,
                left: 4
            }]}>{card.suit === 'joker' ? renderJoker() : card.rank}</StandardText>
            <View style={[{position: 'absolute', top: 2, right: 1}]}>
                {renderIcon()}
            </View>


            <StandardText weight={500} style={[ownStyles.cardText, ownStyles.cardTextPoints, {
                color: card.isRed ? Colors.onRedCardSoft : Colors.onBlackCardSoft,
                position: 'absolute',
                bottom: 1,
                left: 3
            }]}>{card.rank === '3' ? card.inHandValue : card.meldValue}P</StandardText>


            {totalStacked !== null && totalStacked > 1 &&
            <StandardText weight={400} style={[ownStyles.cardText, {
                color: card.isRed ? Colors.onRedCard : Colors.onBlackCard,
                fontSize: 16,
                position: 'absolute',
                bottom: 0,
                right: 2
            }]}>x{totalStacked}</StandardText>
            }

        </Animated.View>
    );
};

const ownStyles = {
    cardContainer: {
        position: 'relative',
        width: 66,
        height: 46,
        borderRadius: 6,
        borderWidth: 3,
        borderColor: Colors.background,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        userSelect: 'none',
        overflow: 'hidden'
    },
    selectedCard: {
        borderColor: Colors.select
    },
    unselectedCard: {
        borderColor: Colors.background
    },
    cardText: {},
    cardTextRank: {
        fontSize: 18
    },
    cardTextPoints: {
        fontSize: 12
    }
};

export default React.memo(Card);
