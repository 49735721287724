import React from 'react';
import Colors from "../../styles/colors";
import {TouchableOpacity, View} from "react-native";

const IconButton = ({icon, onPress, disabled = false}) => {
    return (
        onPress ?
            <TouchableOpacity onPress={onPress} disabled={disabled}>
                <View style={ownStyles.buttonBox}>
                    {icon}
                </View>
            </TouchableOpacity> :
            <View style={ownStyles.buttonBox}>
                {icon}
            </View>
    );
};

const ownStyles = {
    buttonBox: {
        borderWidth: 2,
        borderColor: Colors.onBackground,
        backgroundColor: Colors.background,
        padding: 7,
        borderRadius: 6,
        cursor: 'pointer'
    }
};

export default IconButton;
