import React, {useCallback, useContext, useEffect, useState} from "react";
import StyleContext from "../../StyleContext";
import StandardText from "../../sharedComponents/standardText/StandardText";
import {TextInput, TouchableOpacity, View} from "react-native";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import SelectableList from "../../sharedComponents/selectableList/SelectableList";
import Colors from "../../styles/colors";
import {connect} from "react-redux";
import {fetchViewerFriends} from "../../redux/viewer/actions/friends";
import {useNavigation} from "@react-navigation/native";
import Api from "../../services/Api";
import {setSelectedPlayers} from "../../redux/viewer/gameSetupSlice";
import {useFocusEffect} from "@react-navigation/core";
import {LoadingContext} from "../../context/LoadingContext";
import Spacing from "../../styles/spacing";
import Checkbox from "../../sharedComponents/checkbox/Checkbox";
import {fetchComputerPlayerPersonas} from "../../redux/viewer/computerPlayerSlice";
import Svg from "react-native-svg";

const GameSetupPlayers = ({
                              dispatch,
                              friends,
                              friendsLoading,
                              computerPlayerPersonas,
                              computerPlayerPersonasLoading,
                              userAccount,
                              accessToken,
                              gameSetup,
                              onContinuePress,
                              invitedFriends = [],
                              setInvitedFriends = () => {}
                          }) => {

    const MAX_FRIENDS_ALLOWED = 3;
    const navigation = useNavigation();
    const styleContext = useContext(StyleContext);
    const loadingContext = useContext(LoadingContext);

    const [selectedFriends, setSelectedFriends] = useState([]);
    const [defaultSelectedFriends, setDefaultSelectedFriends] = useState([]);
    const [availableFriends, setAvailableFriends] = useState([]);
    const [selectedComputerPlayers, setSelectedComputerPlayers] = useState(gameSetup.setup.computerPlayers.map(p => p.computerPlayer));
    const [newPlayerFirstName, setNewPlayerFirstName] = useState('');
    const [newPlayerEmail, setNewPlayerEmail] = useState('');
    const [newPlayerLoading, setNewPlayerLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false)
    }, []));

    useFocusEffect(useCallback(() => {
        if (userAccount) {
            dispatch(fetchViewerFriends(null));
            dispatch(fetchComputerPlayerPersonas());
        }
    }, [dispatch, userAccount]));

    useFocusEffect(useCallback(() => {
        const ids = [];
        const mergedFriends = [...friends, ...invitedFriends].map((edge) => {

            return ({
                id: edge.node.id,
                label: edge.node.user.firstName,
                sublabel: `(${edge.node.username})`,
            })
        }).filter(f => {
            if (!ids.includes(f.id)) {
                ids.push(f.id);
                return true;
            }

            return false;
        });

        setAvailableFriends(mergedFriends);
    }, [friends, invitedFriends]));

    useFocusEffect(useCallback(() => {
        setDefaultSelectedFriends(gameSetup.setup.players.filter(p => !!p.userAccount).map((p) => p.userAccount.id))
        // setSelectedComputerPlayers(gameSetup.setup.computerPlayers.map(p => p.computerPlayer))
    }, [gameSetup]));

    useFocusEffect(useCallback(() => {
    }, [availableFriends]));

    useFocusEffect(useCallback(() => {
        setErrorMessage(selectedFriends.length + selectedComputerPlayers.length > MAX_FRIENDS_ALLOWED ?
            `Please select up to three players.` :
            null
        );

        const players = selectedFriends.map((friend) => [...friends, ...invitedFriends].find((f) => f.node.id === friend.id).node);
        if (userAccount) {
            players.push(userAccount);
        }

        dispatch(setSelectedPlayers(players, selectedComputerPlayers));

        setDefaultSelectedFriends((previousValue) =>
            previousValue.concat(
                selectedFriends.map((item) => item.id).filter((item) => previousValue.indexOf(item) < 0)));

    }, [selectedFriends, selectedComputerPlayers]));

    const handleAddNewPlayerButton = async () => {
        setNewPlayerLoading(true);
        setErrorMessage(null);
        try {
            const userAccount = await Api.inviteNewUserAccount(accessToken, newPlayerFirstName, newPlayerEmail);
            setDefaultSelectedFriends((previousValue) => [...previousValue, userAccount.id]);
            setNewPlayerFirstName('');
            setNewPlayerEmail('');
            setInvitedFriends([...invitedFriends, {node: userAccount}]);
        } catch (error) {
            setErrorMessage(error.message);
        }
        setNewPlayerLoading(false);
    };

    const addComputerPlayer = (cpp) => {
        setSelectedComputerPlayers((current) => [cpp, ...current]);
    };

    const removeComputerPlayer = (index) => {
        setSelectedComputerPlayers([
            ...selectedComputerPlayers.slice(0, index),
            ...selectedComputerPlayers.slice(index + 1)
        ]);
    }

    return (
        <View style={{
            flexDirection: 'column',
            width: '100%',
            maxWidth: 420,
            minWidth: 200,
            justifyContent: 'center',
            alignItems: 'stretch'
        }}>
            <View style={{flexDirection: 'column', flexGrow: 1, marginTop: 10}}>

                <View style={{alignItems: 'center'}}>
                    <StandardText style={{fontSize: 20, weight: 500}}>
                        Invite a new player...
                    </StandardText>
                </View>

                <View style={{alignItems: 'stretch', justifyContent: 'center'}}>
                    <View style={{marginTop: 0, alignItems: 'stretch', padding: Spacing.base}}>
                        <TextInput
                            style={[styleContext.input, {fontFamily: 'Roboto_400Regular', marginBottom: Spacing.base}]}
                            placeholder={'First Name'}
                            value={newPlayerFirstName}
                            onChangeText={(text => {
                                setNewPlayerFirstName(text)
                            })} placeholderTextColor={'#999999'}/>
                        <TextInput style={[styleContext.input, {fontFamily: 'Roboto_400Regular', margin: 0}]}
                                   placeholder={'Email or Username'}
                                   value={newPlayerEmail}
                                   onChangeText={(text => {
                                       setNewPlayerEmail(text)
                                   })} placeholderTextColor={'#999999'}/>

                        <View>
                            <LoadingButton loading={newPlayerLoading} label={'Invite new player'}
                                           onPress={handleAddNewPlayerButton}
                                           backgroundColor={Colors.primary4}
                                           labelColor={Colors.onPrimary}
                                           padding={Spacing.base}
                                           showChevron={false}
                                           disabled={newPlayerLoading || !newPlayerFirstName || !newPlayerEmail}
                            />
                            {errorMessage && 
                                <View style={{...ownStyles.resultMessageContainer}}>
                                    <StandardText style={{
                                        ...ownStyles.resultMessage,
                                        color: Colors.error
                                    }}>{errorMessage}</StandardText>
                                </View>
                            }
                        </View>
                    </View>
                </View>

                <View style={{alignItems: 'center'}}>
                    <StandardText style={{fontSize: 20, weight: 500}}>
                        ... or select from list
                    </StandardText>
                    <StandardText style={{fontSize: 16, weight: 500}}>
                        (2 or 4 players)
                    </StandardText>
                </View>

                <View style={[ownStyles.listContainer]}>

                    {computerPlayerPersonas.map((cpp) => (
                        <TouchableOpacity key={cpp.id} onPress={() => {addComputerPlayer(cpp)}} style={{...ownStyles.item, flexDirection: 'row', ...ownStyles.itemHighlight, opacity: 1}}>
                            <View style={{...ownStyles.checkboxWrapper, flexDirection: 'row'}}>
                                <View style={{marginStart: 3, marginEnd: 8}}>
                                    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M13 13V19H11V13H5V11H11V5H13V11H19V13H13Z" fill={Colors.background}/>
                                    </Svg>
                                </View>
                                <View style={{flex: 1}}>
                                    <StandardText style={{color: Colors.background, marginBottom: 5}}>{cpp.name}</StandardText>
                                    <StandardText style={{color: Colors.background, fontSize: 14, lineHeight: 18}}>{cpp.description}</StandardText>
                                </View>
                            </View>
                        </TouchableOpacity>
                    ))}

                    <View style={{...ownStyles.item, flexDirection: 'row', ...ownStyles.itemSelected, opacity: 0.5}}>
                        <View style={[ownStyles.checkboxWrapper]}>
                            <Checkbox onValueChange={() => {
                            }} value={true} label={'You'} disabled={true}/>
                        </View>
                    </View>

                    {selectedComputerPlayers.map((cpp, i) => (
                        <View key={i} style={{...ownStyles.item, flexDirection: 'row', ...ownStyles.itemHighlight, backgroundColor: Colors.secondary4, borderColor: Colors.secondary, opacity: 1}}>
                            <View style={[ownStyles.checkboxWrapper]}>
                                <Checkbox onValueChange={() => {
                                    removeComputerPlayer(i)
                                }} value={true} label={cpp.name} disabled={false} labelColor={Colors.secondary} boxColor={Colors.secondary}/>
                            </View>
                        </View>
                    ))}

                    <SelectableList
                        items={availableFriends}
                        onSelectedItemsChange={setSelectedFriends}
                        defaultSelected={defaultSelectedFriends}
                    />
                </View>

            </View>
            <View style={{...ownStyles.continueButtonContainer}}>
                <View style={{
                    width: '100%',
                    maxWidth: 420,
                    minWidth: 200,
                    paddingStart: Spacing.base,
                    paddingEnd: Spacing.base
                }}>
                    <LoadingButton label={'Continue'}
                                   disabled={!([1, 3].includes(selectedFriends.length + selectedComputerPlayers.length))}
                                   onPress={onContinuePress}/>
                </View>
            </View>
        </View>
    )
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        accessToken: state.auth.login.accessToken,
        friends: state.viewer.friends.data,
        friendsCursor: state.viewer.friends.cursor,
        friendsLoading: state.viewer.friends.loading,
        gameSetup: state.viewer.gameSetup,
        computerPlayerPersonas: state.viewer.computerPlayer.computerPlayerPersonas,
        computerPlayerPersonasLoading: state.viewer.computerPlayer.loading
    }
};

export default connect(mapStateToProps)(GameSetupPlayers);

const ownStyles = {
    listContainer: {
        flex: 1,
        marginTop: Spacing.base,
        padding: Spacing.base
    },
    errorMessageContainer: {
        alignItems: 'center',
        margin: 10,
        marginTop: 0
    },
    errorMessage: {
        color: Colors.error
    },
    continueButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        alignItems: 'center',
        backgroundColor: Colors.background,
    },
    item: {
        backgroundColor: Colors.background,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.onBackgroundBorder2,
        marginTop: 3,
        marginBottom: 3,
        padding: 8
    },
    itemSelected: {
        borderColor: Colors.primary4,
    },
    itemHighlight: {
        borderColor: Colors.secondary3,
        backgroundColor: Colors.secondary,
    },
    checkboxWrapper: {
        width: '100%',
    },
    resultMessageContainer: {
        minHeight: 30,
        padding: Spacing.base,
        backgroundColor: Colors.backgroundSecondary,
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center'
    },
    resultMessage: {
        fontSize: 14,
    },
};
