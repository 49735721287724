import React from "react";
import PropTypes from "prop-types";
import {Text, TouchableOpacity, View} from "react-native";
import {ActivityIndicator} from "react-native";
import Colors from "../../styles/colors";
import StandardText from "../standardText/StandardText";
import ChevronIcon from "../../icons/ChevronIcon";

const PlainButton = ({
                         onPress,
                         disabled,
                         label,
                         padding,
                         fontSize,
                         labelColor,
                         loading = false
                     }) => {

    onPress = onPress ?? (() => {
    });

    const style = {
        backgroundColor: 'transparent',
        borderWidth: 0,
        opacity: disabled ? 0.6 : 1,
        padding: padding ? padding : 10,
        marginTop: 8,
        marginBottom: 8,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: fontSize ?? 16
    };

    return (
        <TouchableOpacity style={[style, {opacity: disabled ? 0.4 : 1}]}
                          onPress={onPress} disabled={disabled} activeOpacity={0.5}>
            <StandardText style={{
                color: labelColor ?? Colors.primary,
                fontSize: fontSize,
                textAlign: 'center',
                opacity: loading ? 0 : 1
            }} weight={400}>{label}</StandardText>

            {loading && (
                <ActivityIndicator style={{position: 'absolute'}} animating={loading} color={labelColor} />
            )}
        </TouchableOpacity>
    );
};

export default PlainButton;
