import React from 'react';
import {View} from "react-native";
import Colors from "../../styles/colors";
import LoadingButton from "../loadingButton/LoadingButton";
import PlainButton from "../plainButton/PlainButton";
import Spacing from "../../styles/spacing";
import StandardText from "../standardText/StandardText";

const ConfirmationDialog = ({title, content, visible, onCancelPress, onConfirmPress}) => {

    onConfirmPress = onConfirmPress ?? (() => {
    });
    onCancelPress = onCancelPress ?? (() => {
    });

    return (
        !visible ? (<></>) : (
            <View style={ownStyles.modalContainer}>
                <View style={ownStyles.centeredView}>

                    <View style={ownStyles.centeredView}>
                        <View style={ownStyles.modalView}>
                            <View style={{...ownStyles.contentContainer}}>
                                <StandardText style={ownStyles.modalTitle}>{title}</StandardText>

                                <StandardText style={ownStyles.modalText}>{content}</StandardText>
                            </View>

                            <View style={{...ownStyles.buttonsContainer}}>
                                <PlainButton label={'Cancel'} onPress={onCancelPress}/>
                                <LoadingButton label={'Yes'} showChevron={false} onPress={onConfirmPress}
                                               backgroundColor={Colors.primary6}/>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    );
};

const ownStyles = {
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(17, 32, 74, 0.4)',
        zIndex: 1000
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        minWidth: 300,
        maxWidth: 600,
        margin: 20,
        backgroundColor: 'white',
        padding: Spacing.small,
        paddingStart: Spacing.base,
        paddingEnd: Spacing.base,
        alignItems: 'stretch',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    buttonsContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    openButton: {
        backgroundColor: '#F194FF',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    contentContainer: {
        padding: Spacing.base
    },
    modalTitle: {
        marginBottom: 14,
        fontSize: 14,
        textAlign: 'start',
        color: Colors.onBackground3
    },
    modalText: {
        fontSize: 16,
        lineHeight: 20,
        textAlign: 'start',
        color: Colors.onBackground
    },
};

export default ConfirmationDialog;
