import React, {useCallback} from "react";
import {connect} from "react-redux";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import ContinueGameScreen from "./ContinueGameScreen";
import HomeIcon from "../../icons/HomeIcon";
import Colors from "../../styles/colors";
import GameOptionsIcon from "../../icons/GameOptionsIcon";
import ManageProfileScreen from "./ManageProfileScreen";
import ProfileIcon from "../../icons/ProfileIcon";
import {useFocusEffect} from "@react-navigation/core";
import GameStatisticsScreen from "./GameStatisticsScreen";
import GameStatsIcon from "../../icons/GameStatsIcon";

const Tab = createBottomTabNavigator();

const HomeScreen = ({navigation, userAccount}) => {

    useFocusEffect(useCallback(() => {
        zE('webWidget', 'show');
    }, [navigation]));

    useFocusEffect(useCallback(() => {
        if (userAccount?.user && userAccount.user.email === null) {
            navigation.navigate('CompleteUserAccountInfo');
        }
    }, [userAccount]));

    const defaultTitle = 'Hand & Foot Friends & Family Edition';

    const screenOptions = ({route}) => ({
        tabBarIcon: ({focused}) => {
            if (route.name === 'ContinueGame') {
                return <HomeIcon size={32} color={focused ? Colors.onBackground : Colors.onBackgroundBorder}/>
            } else if (route.name === 'GameOptions') {
                return <GameOptionsIcon size={42} color={focused ? Colors.onBackground : Colors.onBackgroundBorder}/>
            } else if (route.name === 'GameStatistics') {
                return <GameStatsIcon size={34} color={focused ? Colors.onBackground : Colors.onBackgroundBorder}/>
            } else if (route.name === 'ManageProfile') {
                return <ProfileIcon size={32} color={focused ? Colors.onBackground : Colors.onBackgroundBorder}/>
            }
        }
    });

    return (
        <Tab.Navigator screenOptions={screenOptions} tabBarOptions={{showLabel: false}} initialRouteName={'ContinueGame'}>
            <Tab.Screen name="ContinueGame" component={ContinueGameScreen} options={{title: defaultTitle}}/>
            <Tab.Screen name="GameStatistics" component={GameStatisticsScreen} options={{title: defaultTitle}}/>
            <Tab.Screen name="ManageProfile" component={ManageProfileScreen} options={{title: defaultTitle}}/>
        </Tab.Navigator>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount
    }
};

export default connect(mapStateToProps)(HomeScreen);
