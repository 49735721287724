import React from 'react';
import {Svg, Path, Rect} from 'react-native-svg';

const CrossIcon = ({ size, color }) => {
    return (
        <Svg width={size} viewBox="0 0 20 20" fill="none">
            <Path d="M0.807693 3.63582C0.417169 3.24529 0.417168 2.61213 0.807693 2.2216L2.22191 0.807391C2.61243 0.416866 3.2456 0.416866 3.63612 0.807391L19.1925 16.3637C19.583 16.7543 19.583 17.3874 19.1925 17.778L17.7783 19.1922C17.3877 19.5827 16.7546 19.5827 16.364 19.1922L0.807693 3.63582Z" fill={color}/>
            <Path d="M16.3642 0.807693C16.7547 0.417169 17.3879 0.417168 17.7784 0.807693L19.1926 2.22191C19.5831 2.61243 19.5831 3.2456 19.1926 3.63612L3.63626 19.1925C3.24573 19.583 2.61257 19.583 2.22205 19.1925L0.807832 17.7783C0.417308 17.3877 0.417308 16.7546 0.807832 16.364L16.3642 0.807693Z" fill={color}/>
        </Svg>

    );
};

export default CrossIcon;
