import React, {useEffect, useState} from "react";
import {Pressable, View} from "react-native";
import StandardText from "../standardText/StandardText";
import Colors from "../../styles/colors";
import Svg, {Path} from "react-native-svg";

const Tooltip = ({children, content, customWidth = null, leftOffset = null}) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [comp, setComp] = useState(null);
    const [px, setPx] = useState(0);
    const [py, setPy] = useState(0);

    const tooltipWidth = customWidth ? customWidth : 130;

    useEffect(() => {
        if (comp) {
            comp.measure((fx, fy, width, height, px, py) => {
                setPx(leftOffset !== null ? leftOffset : (-tooltipWidth/2) + (width / 2));
                setPy(height + 5);
            });
        }
    }, [comp]);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <Pressable onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <View style={{position: 'relative'}}>
                <View ref={view => {
                    setComp(view);
                }}>
                    {children}
                </View>

            </View>
            {showTooltip &&
            <View style={{position: 'absolute', width: tooltipWidth, bottom: py, left: px}}>
                <View style={ownStyles.tooltip}>
                    <StandardText style={{...ownStyles.tooltipText}}>{content}</StandardText>
                </View>
                <View style={{position: 'absoluite', bottom: 1, left: leftOffset !== null ? leftOffset : ((tooltipWidth / 2) - 11)}}>
                    <Svg width="23" height="12" viewBox="0 0 23 12" fill="none">
                        <Path d="M11.25 11.5L0.25 0H22.75L11.25 11.5Z" fill="white"/>
                        <path d="M11.25 11.5L0.25 0H22.75L11.25 11.5Z" fill={Colors.inverseBackground}/>
                    </Svg>
                </View>
            </View>
            }
        </Pressable>
    );
};

const ownStyles = {
    tooltip: {
        borderWidth: 1,
        borderRadius: 4,
        padding: 5,
        backgroundColor: Colors.inverseBackground
    },
    tooltipText: {
        color: Colors.onInverseBackground,
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center'
    }
};

export default Tooltip;
