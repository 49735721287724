import React, { useCallback, useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useFocusEffect } from "@react-navigation/core";
import { performLocalLogin } from "../../redux/auth/actions/login";
import { connect } from "react-redux";
import ContinueGameScreen from "./ContinueGameScreen";
import GameOptionsScreen from "./GameOptionsScreen";
import ScoreInPersonGameScreen from "./ScoreInPersonGameScreen";
import GameInsightsScreen from "./GameInsightsScreen";
import ManageSubscriptionScreen from "./ManageSubscriptionScreen";
import ManageProfileScreen from "./ManageProfileScreen";
import TutorialScreenV2 from "./TutorialScreenV2";
import RulesScreen from "./RulesScreen";
import HelpScreen from "./HelpScreen";
import GameTableScreen from "./GameTableScreen";
import CompleteUserAccountInfoScreen from "./CompleteUserAccountInfoScreen";
import CreateDebugGameScreen from "./CreateDebugGameScreen";
import HomeScreen from "./HomeScreen";
import GameSetupScreen from "./GameSetupScreen";
import { View } from "react-native";
import SubscriptionScreen from "./SubscriptionScreen";
import UserSession from "../../services/UserSession";
import { fetchViewerTutorialScreens } from "../../redux/viewer/tutorialSlice";
import { performGetViewerSettings } from "../../redux/auth/reducers/profileSlice";
import DailyTournamentScreen from "./DailyTournamentScreen";
import Api from "../../services/Api";
import compareVersions from 'compare-versions';
import { APP_VERSION } from "../../env.json";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import GameStatisticsScreen from "./GameStatisticsScreen";
import {useRoute} from "@react-navigation/native";
import Announcement from "../../sharedComponents/announcement/Announcement";

const Stack = createStackNavigator();

const AuthenticatedScreen = ({ dispatch, navigation, accessToken, userAccount, localLoginFailed, tutorialScreens, settings }) => {

    const [attemptedLocalLogin, setAttemptedLocalLogin] = useState(false);
    const [redirectToSubscriptionScreen, setRedirectToSubscriptionScreen] = useState(null);
    const [tutorialShown, setTutorialShown] = useState(false);
    const [shouldGoToTutorial, setShouldGoToTutorial] = useState(false);
    const [appOutdated, setAppOutdated] = useState(false);
    const [appOutdatedMessage, setAppOutdatedMessage] = useState(null);
    const [isAnnouncementOpen, setIsAnnouncementOpen] = useState(true);
    const [announcements, setAnnouncements] = useState([]);

    useFocusEffect(useCallback(() => {
        async function checkIfWantsToSubscribe() {
            const value = await UserSession.getWantsToSubscribe();
            if (value) {
                await UserSession.setWantsToSubscribe(value.id, true);
                setRedirectToSubscriptionScreen(!value.redirected);
            }
        }

        checkIfWantsToSubscribe();

        return () => {
            setTutorialShown(false);
        }
    }, []));

    useFocusEffect(useCallback(() => {

        const index = tutorialScreens && tutorialScreens.filter(ts => {
            const sv = JSON.parse(ts.tutorialScreen.screenVisibility);
            return sv?.includes(1);
        }).findIndex(t => !t.read);
        const missingEmail = userAccount?.user.email === null;
        const value = tutorialScreens && (index < 18 && index >= 0) && settings.showTutorial && !missingEmail;
        setShouldGoToTutorial(value);

    }, [tutorialScreens, settings, userAccount]));

    useFocusEffect(useCallback(() => {

        if (!tutorialShown && shouldGoToTutorial) {
            setTutorialShown(true);
            navigation.navigate('Tutorial');
        }

        if (settings?.showTutorial === false) {
            setTutorialShown(true);
        }

        console.log(shouldGoToTutorial);

    }, [tutorialShown, shouldGoToTutorial, settings]));

    useFocusEffect(useCallback(() => {
        if (!attemptedLocalLogin && !userAccount) {
            setAttemptedLocalLogin(true);
            dispatch(performLocalLogin());
        }
    }, [attemptedLocalLogin, userAccount]));

    useFocusEffect(useCallback(() => {
        if (!userAccount && localLoginFailed) {
            navigation.push('Unauthenticated', { screen: 'Start' })
        }
    }));

    useFocusEffect(useCallback(() => {
        if (redirectToSubscriptionScreen !== null && redirectToSubscriptionScreen) {
            navigation.push('Authenticated', { screen: 'Subscription' })
        }
    }, [redirectToSubscriptionScreen]));


    useFocusEffect(useCallback(() => {
        if (userAccount) {
            dispatch(fetchViewerTutorialScreens());
            dispatch(performGetViewerSettings());

            checkAppVersion();
            const interval = setInterval(() => {
                checkAppVersion();
            }, 30000);

            return () => {
                clearInterval(interval);
            }
        }
    }, [userAccount]));

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            async function getAnnouncements() {
                const announcements = await Api.viewerAnnouncements(accessToken);
                console.log(announcements);
                setAnnouncements(announcements);
            }

            getAnnouncements();
        }
    }, [accessToken]));

    const onAnnouncementDismissPress = async () => {
        let newAnnouncementsList = announcements.slice(1);
        setAnnouncements(newAnnouncementsList);

        if (announcements.length > 0) {
            const announcementId = announcements[0].id;
            await Api.readAnnouncement(accessToken, announcementId);
        }
    };

    const checkAppVersion = async () => {
        const generalAppInfo = await Api.currentAppVersion(accessToken);

        if (compareVersions(APP_VERSION, generalAppInfo.appVersion) === -1) {
            setAppOutdated(true);
            setAppOutdatedMessage(generalAppInfo.appVersionMessage)
        } else {
            setAppOutdated(false);
        }
    };

    const defaultTitle = 'Hand & Foot Friends & Family Edition';
    return (
        <View style={{ flex: 1, backgroundColor: 'brown' }}>

            {appOutdated &&
                <View style={{ position: 'absolute', width: '100%', padding: 20, top: 0, left: 0, zIndex: 1000, backgroundColor: Colors.primary }}>
                    <StandardText style={{color: Colors.background, textAlign: 'center'}}>{appOutdatedMessage}</StandardText>
                </View>
            }

            {isAnnouncementOpen && announcements.length > 0 && (
                <Announcement announcement={announcements[0]} onDismissPress={onAnnouncementDismissPress} />
            )}

            <Stack.Navigator screenOptions={{ headerShown: false }}
                initialRouteName={userAccount?.user.email ? 'Home' : 'CompleteUserAccountInfo'}>
                <Stack.Screen name="Home" component={HomeScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="Tutorial" component={TutorialScreenV2}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="CompleteUserAccountInfo" component={CompleteUserAccountInfoScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="NewGame" component={GameSetupScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="DailyTournament" component={DailyTournamentScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="GameStatistics" component={GameStatisticsScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="GameTable" component={GameTableScreen}
                    options={{ title: defaultTitle, unmountOnBlur: true }} />
                <Stack.Screen name="ContinueGame" component={ContinueGameScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="GameOptions" component={GameOptionsScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="ScoreInPersonGame" component={ScoreInPersonGameScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="GameInsights" component={GameInsightsScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="ManageSubscription" component={ManageSubscriptionScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="ManageProfile" component={ManageProfileScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="Rules" component={RulesScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="Help" component={HelpScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="CreateDebugGame" component={CreateDebugGameScreen}
                    options={{ title: defaultTitle }} />
                <Stack.Screen name="Subscription" component={SubscriptionScreen}
                    options={{ title: defaultTitle }} />
            </Stack.Navigator>
        </View>
    )
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.login.accessToken,
        userAccount: state.auth.login.userAccount || null,
        localLoginFailed: state.auth.login.localLoginFailed,
        tutorialScreens: state.viewer.tutorial.tutorialScreens,
        settings: state.auth.profile.settings,
    }
};


export default connect(mapStateToProps)(AuthenticatedScreen);
