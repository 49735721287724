import Api from "../../../services/Api";

export const REQUEST_VIEWER_GAMES = 'REQUEST_VIEWER_GAMES';
export const requestViewerGames = (reset = false) => ({
    type: REQUEST_VIEWER_GAMES,
    payload: {
        reset: reset
    }
});

export const RECEIVE_VIEWER_GAMES = 'RECEIVE_VIEWER_GAMES';
export const receiveViewerGames = (games, reset = false) => ({
    type: RECEIVE_VIEWER_GAMES,
    payload: {
        games: games,
        reset: reset
    }
});

export const RECEIVE_FAILED_VIEWER_GAMES = 'RECEIVE_FAILED_VIEWER_GAMES';
export const receiveFailedViewerGames = (error) => ({
    type: RECEIVE_FAILED_VIEWER_GAMES,
    payload: {
        error: error,
    }
});

export const fetchViewerGames = (type, cursor) => async (dispatch, getState) => {
    dispatch(requestViewerGames(!cursor))

    const state = getState();
    try {
        const games = await Api.viewerGames(state.auth.login.accessToken, type, cursor);
        dispatch(receiveViewerGames(games, !cursor));
    } catch (e) {
        dispatch(receiveFailedViewerGames({edges: []}));
    }
};
