import {combineReducers} from "redux";
import signup from "./signup/reducers";
import viewer from "./viewer/reducers";
import auth from "./auth/reducers";

const rootReducer = combineReducers({
    auth: auth,
    signup: signup,
    viewer: viewer
});

export default rootReducer
