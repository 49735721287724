import React from "react";
import { TouchableOpacity, View } from "react-native";
import Colors from "../../styles/colors";
import StandardText from "../standardText/StandardText";

const OptionPicker = ({options, selectedId, color, onOptionSelected, disabled = false}) => {

    return (
        <View style={{...ownStyles.container, opacity: disabled ? 0.4 : 1 }}>
            {options.map(option => (
                <TouchableOpacity key={option.id} onPress={() => {onOptionSelected(option.id)}} style={{...ownStyles.option, ...(option.id === selectedId ? {backgroundColor: color} : {}), opacity: disabled || option?.disabled ? 0.4 : 1}} disabled={disabled || option?.disabled}>
                    <StandardText style={{...ownStyles.optionText, ...(option.id === selectedId ? ownStyles.selectedOptionText : {})}}>{option.label}</StandardText>
                </TouchableOpacity>
            ))}
        </View>
    );
};

const ownStyles = {
    container: {
        flexDirection: 'row',
        width: '100%'
    },
    option: {
        flex: 1,
        backgroundColor: Colors.onBackgroundBorder2,
        alignItems: 'center',
        paddingTop: 7,
        paddingBottom: 7,
    },
    optionText: {
        color: Colors.onBackground,
        fontSize: 14
    },
    selectedOptionText: {
        color: Colors.background
    }
};

export default OptionPicker;