import React, {useCallback, useState} from "react";
import {View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {useFocusEffect} from "@react-navigation/core";
import Spacing from "../../styles/spacing";
import Colors from "../../styles/colors";

const GameSetupTeams = ({onContinuePress, onShufflePlayers, gameSetup, userAccount}) => {

    const [shuffleIteration, setShuffleIteration] = useState(1);
    const [teams, setTeams] = useState([]);

    useFocusEffect(useCallback(() => {
        onShufflePlayers(shuffleIteration);
    }, [shuffleIteration]));

    useFocusEffect(useCallback(() => {
        const teams2 = [];
        gameSetup.setup.players.forEach((player) => {
            let team = teams2.find((t) => t.id === player.team);
            if (!team) {
                team = {id: player.team, players: []};
                teams2.push(team);
            }
            team.players.push(player);
        });

        gameSetup.setup.computerPlayers.forEach((player) => {
            let team = teams2.find((t) => t.id === player.team);
            if (!team) {
                team = {id: player.team, players: []};
                teams2.push(team);
            }
            team.players.push(player);
        });

        setTeams(teams2);

    }, [gameSetup.setup.players]));

    const handleShufflePlayersPress = () => {
        setShuffleIteration((v) => {
            if (gameSetup.setup.players.length + gameSetup.setup.computerPlayers.length === 4) {
                return v >= 3 ? 1 : v + 1
            } else if (gameSetup.setup.players.length + gameSetup.setup.computerPlayers.length === 6) {
                return v >= 15 ? 1 : v + 1
            }
        });
    };

    return (
        <View style={{
            flexDirection: 'column',
            width: '100%',
            maxWidth: 420,
            minWidth: 200,
            justifyContent: 'center',
            alignItems: 'stretch'
        }}>
            <View style={{flexDirection: 'column', flexGrow: 1, marginTop: 10, paddingBottom: 10}}>
                <StandardText style={{fontSize: 24, weight: 500, lineHeight: 32, textAlign: 'center'}}>
                    {`Play game with the teams\nbelow or re-shuffle teams.`}
                </StandardText>

                <View style={{marginTop: 20}}>
                    <LoadingButton
                        label={'Re-Shuffle Teams' + (shuffleIteration > 1 ? ` (${shuffleIteration} of ${gameSetup.setup.teamsCombinations.length})` : ``)}
                        onPress={handleShufflePlayersPress}
                        showChevron={false}/>
                </View>

                <View style={{...ownStyles.teamsContainer}}>

                    {teams.slice().sort((a, b) => a.id - b.id).map((team, index) => (
                        <View key={team.id}>
                            <View style={{...ownStyles.teamContainer}}>
                                {team.players.map((player, i) => (
                                    <View key={`${player.id}-${i}`} style={{
                                        ...ownStyles.item,
                                        flexDirection: 'row', ...ownStyles.itemSelected,
                                    }}>
                                        <View>
                                            <StandardText style={{...(userAccount?.id === player.id ? {color: Colors.primary4} : {})}}>
                                                {userAccount?.id === player.id ? 'You' : (player.userAccount ? player.userAccount.user.firstName : player.computerPlayer.name)}
                                            </StandardText>
                                        </View>
                                        {player.userAccount && userAccount?.id !== player.userAccount.id && <View style={{marginLeft: 5}}>
                                            <StandardText
                                                style={{color: Colors.onBackground3, fontSize: 14, opacity: 0.7}}>
                                                ({player.userAccount.username})
                                            </StandardText>
                                        </View>}
                                    </View>
                                ))}
                            </View>

                            {index < teams.length - 1 && (
                                <View>
                                    <StandardText style={{textAlign: 'center', weight: 500}}>vs</StandardText>
                                </View>
                            )}
                        </View>
                    ))}
                </View>

                <View style={{...ownStyles.continueButtonContainer}}>
                    <View style={{width: '100%', maxWidth: 420, minWidth: 200, paddingStart: Spacing.base, paddingEnd: Spacing.base}}>
                        <LoadingButton label={'Continue'}
                                       loading={false}
                                       disabled={false}
                                       onPress={onContinuePress}/>
                    </View>
                </View>
            </View>
        </View>
    );
};

const ownStyles = {
    teamsContainer: {
        marginTop: Spacing.base
    },
    teamContainer: {
        marginTop: Spacing.large,
        marginBottom: Spacing.large,
    },
    item: {
        alignItems: 'center',
        backgroundColor: Colors.background,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.onBackgroundBorder2,
        marginTop: 3,
        marginBottom: 3,
        padding: 13,
        height: 48
    },
    itemSelected: {
        borderColor: Colors.primary4,
    },
    checkboxWrapper: {
        width: '100%',
    },
    teamTitle: {
        fontSize: 12,
        marginLeft: 10,
        marginBottom: 5,
        textTransform: 'uppercase',
        color: Colors.onBackground2
    },
    continueButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        alignItems: 'center',
        backgroundColor: Colors.background,
    },
};

export default GameSetupTeams;
