import {WAMP_URL} from "../env.json";
import autobahn from "autobahn-browser/autobahn.min";

export default class Wamp {
    static WAMP_URL = `${WAMP_URL}`;

    static subscribe = (gameId, teamPlayerId, onGameMessage, onPlayerMessage, onOpen = () => {}, onClose = () => {}) => {
        const connection = new autobahn.Connection({url: Wamp.WAMP_URL, realm: 'hffe'});
        connection.onopen = (session) => {
            session.subscribe(`com.handandfootfamilyedition.game.${gameId}`, onGameMessage);
            session.subscribe(`com.handandfootfamilyedition.player.messages.${teamPlayerId}`, onPlayerMessage);
            onOpen();
        };

        connection.onclose = onClose;

        connection.open();
        return connection;
    };
}
