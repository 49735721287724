import React, {useCallback, useEffect, useState} from "react";
import {Image, Linking, Platform, TouchableOpacity, View} from "react-native";
import BlockIcon from "../../icons/BlockIcon";
import Colors from "../../styles/colors";
import StandardText from "../../sharedComponents/standardText/StandardText";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import blurredSuits from "../../../assets/blurred_suits_bg.png";
import suitsGrid from "../../../assets/suits_grid.png";
import UserSession from "../../services/UserSession";
import LogoNoText from "../../icons/LogoNoText";

const SubscriptionBlocked = ({navigation, contactUsUrl, freePlanScreenConfig, annualSave, onPlayFreeButtonPress}) => {

    const MIN_WAIT_SECONDS = 10;
    const MAX_WAIT_SECONDS = 15;

    const [remainingSeconds, setRemainingSeconds] = useState(null);
    const [boxMessage, setBoxMessage] = useState(null);
    const [waitSeconds, setWaitSeconds] = useState(null);
    const [waitMessage, setWaitMessage] = useState('');
    const [playFreeButtonEnabled, setPlayFreeButtonEnabled] = useState(false);

    useEffect(() => {
        const m = freePlanScreenConfig.box_messages[Math.floor(Math.random() * freePlanScreenConfig.box_messages.length)];
        setBoxMessage(m);
        const seconds = Math.floor(Math.random() * (MAX_WAIT_SECONDS - MIN_WAIT_SECONDS + 1) + MIN_WAIT_SECONDS);
        setWaitSeconds(seconds);
        setRemainingSeconds(seconds);

    }, []);

    useEffect(() => {
        if (waitSeconds !== null) {
            const interval = setInterval(() => {
                setRemainingSeconds((v) => v > 0 ? v - 1 : 0);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [waitSeconds]);

    useEffect(() => {
        const waitMessageTemplate = freePlanScreenConfig.wait_button_template;
        setWaitMessage(waitMessageTemplate.replace('%s', remainingSeconds));
        if (remainingSeconds === 0) {
            setPlayFreeButtonEnabled(true);
        }
    }, [remainingSeconds]);

    const handleGoSubscribeButtonPress = useCallback(async (id) => {
        await UserSession.setWantsToSubscribe(id, true);
        navigation.navigate('Subscription');
    }, []);

    const handleContactUsPress = () => {
        if(Platform.OS === 'web'){
            window.open(freePlanScreenConfig.contact_us_url, '_blank');
        } else {
            Linking.openURL(freePlanScreenConfig.contact_us_url);
        }
    };

    return (
        <View style={{...ownStyles.container}}>

            <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                <Image source={blurredSuits} style={{width: '100%', height: '100%'}} resizeMode={'repeat'} />
            </View>

            <View style={{position: 'absolute', marginTop: 240, opacity: 0.5}}>
                <Image source={suitsGrid} style={{width: 320, height: 158}} resizeMode={'repeat'} />
            </View>

            <View style={{marginTop: -80}}>
                <LogoNoText size={200}/>
            </View>

            <View style={{alignItems: 'center', marginTop: 0}}>
                <StandardText style={{...ownStyles.baseText, ...ownStyles.headerText}}>{freePlanScreenConfig.title}</StandardText>
                <StandardText style={{...ownStyles.baseText, ...ownStyles.subheaderText}}>{freePlanScreenConfig.subtitle}</StandardText>
                <View style={{...ownStyles.box}}>
                    <StandardText style={{...ownStyles.baseText, ...ownStyles.boxText}}>
                        {boxMessage}
                    </StandardText>
                </View>

                <View style={{...ownStyles.buttonsContainer, width: 300}}>

                    <LoadingButton label={freePlanScreenConfig.monthly_button_label} onPress={() => handleGoSubscribeButtonPress('3')} />
                    <LoadingButton label={freePlanScreenConfig.annual_button_label} onPress={() => handleGoSubscribeButtonPress('2')} backgroundColor={Colors.primary6} />

                    <StandardText style={{...ownStyles.baseText, fontSize: 14, marginTop: 20}}>
                        <StandardText style={{weight: 500, fontSize: 14}}>Save {annualSave}</StandardText> with an annual subscription!
                    </StandardText>

                    <View style={{backgroundColor: 'white'}}>
                        <LoadingButton label={waitMessage} onPress={onPlayFreeButtonPress} backgroundColor={Colors.onBackground} disabled={!playFreeButtonEnabled} />
                    </View>
                </View>
            </View>

            {!!contactUsUrl &&
            <View style={{backgroundColor: Colors.onBackgroundBorder3, position: 'absolute', bottom: 0, left: 0, right: 0, alignItems: 'center', padding: 20}}>
                <StandardText style={{fontSize: 14}}>{freePlanScreenConfig.footer_text}</StandardText>
                <TouchableOpacity style={{marginTop: 10}} onPress={handleContactUsPress}>
                    <StandardText style={{fontSize: 14, weight: 500}}>Contact us!</StandardText>
                </TouchableOpacity>
            </View>
            }
        </View>
    );
}

const ownStyles = {
    container: {
        backgroundColor: Colors.background,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    baseText: {
        textAlign: 'center'
    },
    headerText: {
        fontSize: 24,
        weight: 500,
        marginBottom: 10
    },
    subheaderText: {
        fontSize: 20,
        lineHeight: 30,
        marginBottom: 10
    },
    box: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingStart: 30,
        paddingEnd: 30,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: Colors.onBackgroundBorder2,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        width: 300
    },
    boxText: {},
    buttonsContainer: {
        marginTop: 10
    }
};

export default SubscriptionBlocked;
