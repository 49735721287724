import React from 'react';
import { Svg, Path, Rect, Circle } from 'react-native-svg';

const Logo = ({ size }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 96 71" fill="none">
            <Rect x="13.7637" y="61.4902" width="44.7794" height="29.8529" rx="4.47794" transform="rotate(-106.497 13.7637 61.4902)" fill="white" stroke="#FF4A6B" strokeWidth="1.49265" />
            <Circle cx="8.96553" cy="23.6036" r="2.79871" transform="rotate(-16.4966 8.96553 23.6036)" fill="#FF4A6B" />
            <Circle cx="14.3347" cy="22.0148" r="2.79871" transform="rotate(-16.4966 14.3347 22.0148)" fill="#FF4A6B" />
            <Path d="M13.2633 29.4782L7.48139 25.9895L11.5594 22.5116L16.8737 23.208L13.9239 29.2826C13.8041 29.5294 13.4982 29.6199 13.2633 29.4782Z" fill="#FF4A6B" />
            <Path d="M29.8678 40.8591L31.4528 40.3897L33.3545 46.8113C33.614 47.6876 33.5455 48.465 33.149 49.1434C32.7566 49.8206 32.1118 50.292 31.2145 50.5577C30.2585 50.8409 29.4491 50.8183 28.7864 50.4901C28.1237 50.1619 27.665 49.568 27.4105 48.7084L28.9954 48.2391C29.1395 48.7254 29.3676 49.0569 29.6799 49.2335C29.9951 49.4047 30.3812 49.4226 30.8383 49.2873C31.2659 49.1606 31.564 48.9196 31.7324 48.5641C31.9037 48.2033 31.9155 47.7733 31.7677 47.2744L29.8678 40.8591Z" fill="#FF4A6B" />
            <Rect x="21.4023" y="52.7129" width="44.7794" height="29.8529" rx="4.47794" transform="rotate(-87.0513 21.4023 52.7129)" fill="white" stroke="#11204A" strokeWidth="1.49265" />
            <Path d="M32.0898 19.2617L31.8859 23.2213" stroke="#2F3C60" strokeWidth="1.24387" />
            <Circle cx="34.8851" cy="19.4058" r="2.79871" transform="rotate(-177.051 34.8851 19.4058)" fill="#2F3C60" />
            <Rect x="30.9766" y="16.7129" width="2.48774" height="2.48774" transform="rotate(2.94867 30.9766 16.7129)" fill="#2F3C60" />
            <Circle cx="29.2953" cy="19.1167" r="2.79871" transform="rotate(-177.051 29.2953 19.1167)" fill="#2F3C60" />
            <Path d="M32.8946 12.5664L37.0771 17.6494L27.2946 17.1455L31.9773 12.5191C32.2375 12.262 32.6622 12.2839 32.8946 12.5664Z" fill="#2F3C60" />
            <Path d="M43.4551 38.6197L45.1059 38.7047L44.7614 45.3931C44.7144 46.3058 44.391 47.0161 43.7912 47.5238C43.1958 48.0317 42.4308 48.2616 41.4963 48.2134C40.5005 48.1621 39.7449 47.8714 39.2292 47.3413C38.7135 46.8112 38.4788 46.0985 38.5249 45.2032L40.1757 45.2883C40.1496 45.7948 40.2544 46.1834 40.49 46.4539C40.7303 46.7202 41.0884 46.8657 41.5644 46.8902C42.0099 46.9131 42.3712 46.785 42.6483 46.5059C42.93 46.2227 43.0842 45.8212 43.111 45.3015L43.4551 38.6197Z" fill="#11204A" />
            <Rect x="28.085" y="46.3418" width="44.7794" height="29.8529" rx="4.47794" transform="rotate(-66.7461 28.085 46.3418)" fill="white" stroke="#FF4A6B" strokeWidth="1.49265" />
            <Path d="M47.6957 14.4196L52.3486 12.2738C52.6001 12.1578 52.8967 12.2852 52.9857 12.5475L54.6313 17.4C54.9379 18.304 54.5126 19.2937 53.6457 19.6935L48.9928 21.8394C48.7413 21.9554 48.4447 21.8279 48.3557 21.5656L46.7101 16.7132C46.4035 15.8091 46.8288 14.8194 47.6957 14.4196Z" fill="#FF4A6B" />
            <Path d="M53.6581 40.7776L55.1768 41.4302L52.5327 47.5834C52.1719 48.4231 51.6221 48.9769 50.8834 49.2449C50.1488 49.5147 49.3515 49.4648 48.4917 49.0954C47.5757 48.7017 46.9678 48.1668 46.6682 47.4907C46.3685 46.8146 46.3957 46.0647 46.7496 45.2411L48.2683 45.8937C48.068 46.3597 48.0315 46.7605 48.1586 47.096C48.2915 47.4291 48.5769 47.6898 49.0148 47.878C49.4247 48.0541 49.8079 48.0593 50.1647 47.8937C50.5272 47.7258 50.8111 47.4028 51.0166 46.9247L53.6581 40.7776Z" fill="#FF4A6B" />
            <Rect x="39.5703" y="44.4785" width="44.7794" height="29.8529" rx="4.47794" transform="rotate(-47.3009 39.5703 44.4785)" fill="white" stroke="#11204A" strokeWidth="1.49265" />
            <Circle cx="72.3415" cy="22.1677" r="2.79871" transform="rotate(42.6991 72.3415 22.1677)" fill="#2F3C60" />
            <Circle cx="67.1208" cy="23.6979" r="2.79871" transform="rotate(42.6991 67.1208 23.6979)" fill="#2F3C60" />
            <Circle cx="71.2361" cy="27.4938" r="2.79871" transform="rotate(42.6991 71.2361 27.4938)" fill="#2F3C60" />
            <Rect x="69.9521" y="22.9238" width="2.48774" height="2.48774" transform="rotate(42.6991 69.9521 22.9238)" fill="#2F3C60" />
            <Path d="M69.1777 25.5957L66.489 28.5096" stroke="#2F3C60" strokeWidth="1.24387" />
        </Svg>
    );
};

export default Logo;