import Api from "../../../services/Api";

export const SUBMIT_SAVE_GAME_CONFIG = 'SUBMIT_SAVE_GAME_CONFIG';
export const submitSaveGameConfig = () => ({
    type: SUBMIT_SAVE_GAME_CONFIG
});

export const RECEIVE_SAVE_GAME_CONFIG = 'RECEIVE_SAVE_GAME_CONFIG';
export const receiveSaveGameConfig = ({userAccountGameConfigs}) => ({
    type: RECEIVE_SAVE_GAME_CONFIG,
    payload: {
        gameConfigs: userAccountGameConfigs,
    }
});

export const RECEIVE_FAILED_SAVE_GAME_CONFIG = 'RECEIVE_FAILED_SAVE_GAME_CONFIG';
export const receiveFailedSaveGameConfig = (error) => ({
    type: RECEIVE_FAILED_SAVE_GAME_CONFIG,
    payload: {
        error: error
    }
});

export const REQUEST_GAME_CONFIGS = 'REQUEST_GAME_CONFIGS';
export const requestGameConfigs = () => ({
    type: REQUEST_GAME_CONFIGS
});

export const RECEIVE_GAME_CONFIGS = 'RECEIVE_GAME_CONFIGS';
export const receiveGameConfigs = ({userAccountGameConfigs}) => ({
    type: RECEIVE_GAME_CONFIGS,
    payload: {
        gameConfigs: userAccountGameConfigs,
    }
});

export const RECEIVE_FAILED_GAME_CONFIGS = 'RECEIVE_FAILED_GAME_CONFIGS';
export const receiveFailedGameConfigs = (error) => ({
    type: RECEIVE_FAILED_GAME_CONFIGS,
    payload: {
        error: error
    }
});

export const performSaveGameConfig = (userAccountGameConfigId, numberOfCardsToDeal) => async (dispatch, getState) => {
    dispatch(submitSaveGameConfig());

    const state = getState();
    try {
        const userAccount = await Api.saveUserAccountGameConfig(state.auth.login.accessToken, userAccountGameConfigId, numberOfCardsToDeal);
        dispatch(receiveSaveGameConfig(userAccount));
    } catch (e) {
        dispatch(receiveFailedSaveGameConfig(e.message));
    }
}

export const fetchGameConfigs = () => async (dispatch, getState) => {
    dispatch(requestGameConfigs());

    const state = getState();
    try {
        const userAccount = await Api.viewerMe(state.auth.login.accessToken);
        dispatch(receiveGameConfigs(userAccount));
    } catch (e) {
        dispatch(receiveFailedGameConfigs(e.message));
    }
}
