import React from 'react';
import {Svg, Path, Rect} from 'react-native-svg';

const HomeIcon = ({ size, color }) => {
    return (
        <Svg width={size} viewBox="0 0 32 32" fill="none">
            <Rect width="32" height="32" rx="4.8" fill="none"/>
            <Path d="M4.80078 17.2131C4.80078 16.4772 5.04389 15.7619 5.49231 15.1784L14.6043 3.32202C15.2726 2.45247 16.5835 2.45246 17.2518 3.32202L26.3638 15.1784C26.8122 15.7619 27.0553 16.4772 27.0553 17.2131V27.1301C27.0553 28.0522 26.3079 28.7996 25.3858 28.7996H21.6157C20.6937 28.7996 19.9462 28.0522 19.9462 27.1301V22.4327C19.9462 21.5107 19.1988 20.7632 18.2767 20.7632H13.5794C12.6573 20.7632 11.9099 21.5107 11.9099 22.4327V27.1301C11.9099 28.0522 11.1624 28.7996 10.2404 28.7996H6.47028C5.54824 28.7996 4.80078 28.0522 4.80078 27.1301V17.2131Z" fill={color}/>
        </Svg>
    );
};

export default HomeIcon;
