import axios from "axios";
import {GRAPHQL_URL} from "../env.json";
import Query from "./Query";

export default class Api {
    static GRAPHQL_URL = `${GRAPHQL_URL}/graphql`;

    static getToken = async (username, password) => {
        const json = await this.doQuery(Query.GET_TOKEN, {
            username: username,
            password: password
        });

        this.checkForError(json, 'token');
        return json.data.token;
    }

    static getTokenFromOAuthLogin = async (provider, accessToken, code) => {
        const json = await this.doQuery(Query.GET_TOKEN_FROM_OAUTH_LOGIN, {
            provider: provider,
            accessToken: accessToken,
            code: code
        });

        this.checkForError(json, 'tokenFromOAuthLogin');
        return json.data.tokenFromOAuthLogin;
    }

    static completeUserAccountInfo = async (accessToken, email) => {
        const json = await this.doQuery(Query.COMPLETE_USER_ACCOUNT_INFO, {
            input: {
                email: email
            }
        }, accessToken);

        this.checkForError(json, 'completeUserAccountInfo');
        return json.data.completeUserAccountInfo;
    }

    static editUserAccountInfo = async (accessToken, email, username, password, firstName, lastName, birthyear, newsletter) => {
        const json = await this.doQuery(Query.EDIT_USER_ACCOUNT_INFO, {
            input: {
                email: email,
                username: username,
                password: password,
                firstName: firstName,
                lastName: lastName,
                birthyear: birthyear?.toString(),
                newsletter: newsletter,
            }
        }, accessToken);

        this.checkForError(json, 'editUserAccountInfo');
        return json.data.editUserAccountInfo;
    }

    static editUserAccountPassword = async (accessToken, currentPassword, newPassword) => {
        const json = await this.doQuery(Query.EDIT_USER_ACCOUNT_PASSWORD, {
            input: {
                currentPassword: currentPassword,
                newPassword: newPassword,
            }
        }, accessToken);

        this.checkForError(json, 'editUserAccountPassword');
        return json.data.editUserAccountPassword;
    }

    static createUserAccount = async (firstName, birthyear, email, password) => {
        const json = await this.doQuery(Query.CREATE_USER_ACCOUNT, {
            input: {
                firstName: firstName,
                birthyear: birthyear ? birthyear.toString() : null,
                email: email,
                password: password
            }
        });

        this.checkForError(json, 'createUserAccount');
        return json.data.createUserAccount;
    }

    static requestChangePassword = async (email) => {
        const json = await this.doQuery(Query.REQUEST_CHANGE_PASSWORD, {
            email: email
        });

        this.checkForError(json, 'requestChangePassword');
        return json.data.requestChangePassword;
    }

    static changePassword = async (token, password) => {
        const json = await this.doQuery(Query.CHANGE_PASSWORD, {
            token: token,
            password: password
        });

        this.checkForError(json, 'requestChangePassword');
        return json.data.requestChangePassword;
    }

    static viewerMe = async (accessToken) => {
        const json = await this.doQuery(Query.VIEWER_ME, {}, accessToken);

        this.checkForError(json, 'me');
        return json.data.viewer.me;
    }

    static viewerFriends = async (accessToken, cursor) => {
        const variables = {first: 100}; // TODO: get them all
        if (cursor) {
            variables['cursor'] = cursor;
        }

        const json = await this.doQuery(Query.VIEWER_FRIENDS, variables, accessToken);

        this.checkForError(json, 'friends');
        return json.data.viewer.friends;
    }

    static viewerGames = async (accessToken, type, cursor) => {
        const variables = {type: type, last: 20};
        if (cursor) {
            variables['cursor'] = cursor;
        }

        const json = await this.doQuery(Query.VIEWER_GAMES, variables, accessToken);

        this.checkForError(json, 'games');
        return json.data.viewer.games;
    }

    static viewerGame = async (accessToken, gameAlphanumericId) => {
        const variables = {alphanumericId: gameAlphanumericId};
        const json = await this.doQuery(Query.VIEWER_GAME, variables, accessToken);

        this.checkForError(json, 'game');
        return json.data.viewer.game;
    }

    static viewerTutorialScreens = async (accessToken) => {
        const json = await this.doQuery(Query.VIEWER_TUTORIAL_SCREENS, {}, accessToken);

        this.checkForError(json, 'tutorialScreens');
        return json.data.viewer.tutorialScreens;
    }

    static readTutorial = async (accessToken, id) => {
        const json = await this.doQuery(Query.READ_TUTORIAL, {input: {tutorialScreenId: id}}, accessToken);

        this.checkForError(json, 'tutorialScreens');
        return json.data.readTutorialScreen.tutorialScreen;
    }

    static viewerAnnouncements = async (accessToken) => {
        const json = await this.doQuery(Query.VIEWER_ANNOUNCEMENTS, {}, accessToken);

        this.checkForError(json, 'announcements');
        return json.data.viewer.announcements;
    }

    static readAnnouncement = async (accessToken, id) => {
        const json = await this.doQuery(Query.READ_ANNOUNCEMENT, {input: {announcementId: id}}, accessToken);

        this.checkForError(json, 'readAnnouncement');
        return json.data.readAnnouncement;
    }

    static computerPlayerPersonas = async (accessToken) => {
        const json = await this.doQuery(Query.COMPUTER_PLAYER_PERSONAS, {}, accessToken);

        this.checkForError(json, 'computerPlayerPersonas');
        return json.data.computerPlayerPersonas;
    }

    static viewerSettings = async (accessToken) => {
        const json = await this.doQuery(Query.VIEWER_SETTINGS, {}, accessToken);

        this.checkForError(json, 'settings');
        return json.data.viewer.settings;
    }

    static setViewerSettings = async (accessToken, settings) => {
        const json = await this.doQuery(Query.SET_SETTINGS, {input: settings}, accessToken);

        this.checkForError(json, 'setSettings');
        return json.data.setSettings;
    }

    static inviteNewUserAccount = async (accessToken, firstName, email) => {
        const json = await this.doQuery(Query.INVITE_USER_ACCOUNT_FOR_GAME, {
            input: {
                firstName: firstName,
                email: email,
            }
        }, accessToken);

        this.checkForError(json, 'inviteUserAccountForGame');
        return json.data.inviteUserAccountForGame;
    };

    static joinFromGameToken = async (gameToken) => {
        const json = await this.doQuery(Query.JOIN_FROM_GAME_TOKEN, {
            gameToken: gameToken
        });

        this.checkForError(json, 'joinFromGameToken');
        return json.data.joinFromGameToken;
    };

    static createGame = async (accessToken, setup) => {
        const players = [...setup.players.map((player) => ({
            team: player.team,
            userAccountId: player.userAccount.id,
            computerPlayerPersonaId: null
        })),
            ...setup.computerPlayers.map((player) => ({
                team: player.team,
                userAccountId: null,
                computerPlayerPersonaId: player.computerPlayer.id
            }))]
        const json = await this.doQuery(Query.CREATE_GAME, {
            input: {
                players: players,
                cardsToDeal: setup.cardsToDeal,
                numberOfCanastas: setup.numberOfCanastas,
                swipeCards: setup.swipeCards
            }
        }, accessToken);

        this.checkForError(json, 'createGame');
        return json.data.createGame;
    }

    static createDuplicateTournamentGame = async (accessToken, solo, short = true) => {
        const json = await this.doQuery(Query.CREATE_DUPLICATE_TOURNAMENT_GAME, {
            input: {
                solo: solo,
                short: short
            }
        }, accessToken);

        this.checkForError(json, 'createDuplicateTournamentGame');
        return json.data.createDuplicateTournamentGame;
    }

    static performGameAction = async (accessToken, gameAlphanumericId, action, cardIds = [], extraArgs = []) => {
        const json = await this.doQuery(Query.PERFORM_GAME_ACTION, {
            input: {
                gameAlphanumericId: gameAlphanumericId,
                action: action,
                cardIds: cardIds,
                extraArgs: JSON.stringify(extraArgs)
            }
        }, accessToken);

        this.checkForError(json, 'performGameAction');
        return json.data.performGameAction;
    }

    static requestBreak = async (accessToken, gameAlphanumericId, duration) => {
        const json = await this.doQuery(Query.REQUEST_BREAK, {
            input: {
                gameAlphanumericId: gameAlphanumericId,
                duration: duration
            }
        }, accessToken);

        this.checkForError(json, 'requestBreak');
        return json.data.requestBreak;
    }

    static finishBreak = async (accessToken, gameAlphanumericId) => {
        const json = await this.doQuery(Query.FINISH_BREAK, {
            input: {
                gameAlphanumericId: gameAlphanumericId
            }
        }, accessToken);

        this.checkForError(json, 'finishBreak');
        return json.data.finishBreak;
    }

    static toggleArchiveGame = async (accessToken, gameAlphanumericId) => {
        const json = await this.doQuery(Query.TOGGLE_ARCHIVE_GAME, {
            gameAlphanumericId: gameAlphanumericId
        }, accessToken);

        this.checkForError(json, 'toggleArchiveGame');
        return json.data.toggleArchiveGame;
    }

    static toggleRemoveGame = async (accessToken, gameAlphanumericId) => {
        const json = await this.doQuery(Query.TOGGLE_REMOVE_GAME, {
            gameAlphanumericId: gameAlphanumericId
        }, accessToken);

        this.checkForError(json, 'toggleRemoveGame');
        return json.data.toggleRemoveGame;
    }

    static saveUserAccountGameConfig = async (accessToken, userAccountGameConfigId, numberOfCardsToDeal) => {
        const json = await this.doQuery(Query.SAVE_USER_ACCOUNT_GAME_CONFIG, {
            input: {
                userAccountGameConfigId: userAccountGameConfigId,
                numberOfCardsToDeal: parseInt(numberOfCardsToDeal)
            }
        }, accessToken);

        this.checkForError(json, 'saveUserAccountGameConfig');
        return json.data.saveUserAccountGameConfig;
    }

    static createDebugGame = async (accessToken, debugGameData) => {
        const json = await this.doQuery(Query.CREATE_DEBUG_GAME, {
            data: debugGameData
        }, accessToken);

        this.checkForError(json, 'createDebugGame');
        return json.data.createDebugGame;
    }

    static generalAppInfo = async (accessToken) => {
        const json = await this.doQuery(Query.GENERAL_APP_INFO, {}, accessToken);

        this.checkForError(json, 'generalAppInfo');
        return json.data.generalAppInfo;
    }

    static currentAppVersion = async (accessToken) => {
        const json = await this.doQuery(Query.CURRENT_APP_VERSION, {}, accessToken);

        this.checkForError(json, 'generalAppInfo');
        return json.data.generalAppInfo;
    }

    static subscriptionPlans = async (accessToken) => {
        const json = await this.doQuery(Query.SUBSCRIPTION_PLANS, {}, accessToken);

        this.checkForError(json, 'subscriptionPlans');
        return json.data.subscriptionPlans;
    }

    static subscribeToSubscriptionPlan = async (accessToken, subscriptionPlanPriceId, paymentMethodId) => {
        const json = await this.doQuery(Query.SUBSCRIBE_TO_SUBSCRIPTION_PLAN, {
            input: {
                subscriptionPlanPriceId: parseInt(subscriptionPlanPriceId),
                paymentMethodId: paymentMethodId
            }
        }, accessToken);

        this.checkForError(json, 'subscribeToSubscriptionPlanPrice');
        return json.data.subscribeToSubscriptionPlanPrice;
    }

    static cancelSubscription = async (accessToken) => {
        const json = await this.doQuery(Query.CANCEL_SUBSCRIPTION, {}, accessToken);

        this.checkForError(json, 'cancelSubscription');
        return json.data.cancelSubscription;
    }

    static viewerSubscription = async (accessToken) => {
        const json = await this.doQuery(Query.VIEWER_SUBSCRIPTION, {}, accessToken);

        this.checkForError(json, 'viewerSubscription');
        return json.data.viewer.subscription;
    }

    static viewerUserAccountDailyTournament = async (accessToken) => {
        const json = await this.doQuery(Query.VIEWER_USER_ACCOUNT_DAILY_TOURNAMENT, {}, accessToken);

        this.checkForError(json, 'viewerUserAccountDuplicateDailyTournament');
        return json.data.viewer.userAccountDuplicateDailyTournament;
    }

    static viewerDailyTournamentLeaderboard = async (accessToken, solo, short, mode, date) => {
        const json = await this.doQuery(Query.VIEWER_DAILY_TOURNAMENT_LEADERBOARD, {
            solo: solo,
            short: short,
            mode: mode,
            date: date
        }, accessToken);

        this.checkForError(json, 'duplicateDailyTournamentLeaderboard');
        return json.data.viewer.duplicateDailyTournamentLeaderboard;
    }

    static viewerMetrics = async (accessToken, solo, byOpponent, byPartner, canastasRequired) => {
        const json = await this.doQuery(Query.VIEWER_METRICS, {
            solo: solo,
            byOpponent: byOpponent,
            byPartner: byPartner,
            canastasRequired: canastasRequired
        }, accessToken);

        this.checkForError(json, 'viewerMetrics');
        return json.data.viewer.metrics;
    }

    static readCoachingMark = async (accessToken, coachingMarkId) => {
        const json = await this.doQuery(Query.READ_COACHING_MARK, {
            input: {
                coachingMarkId: coachingMarkId
            }
        }, accessToken);

        this.checkForError(json, 'readCoachingMark');
        return json.data.readCoachingMark;
    }

    static hideCoachingMark = async (accessToken, coachingMarkId) => {
        const json = await this.doQuery(Query.HIDE_COACHING_MARK, {
            input: {
                coachingMarkId: coachingMarkId
            }
        }, accessToken);

        this.checkForError(json, 'hideCoachingMark');
        return json.data.hideCoachingMark;
    }

    static doQuery = async (query, variables, accessToken = null, headers = {}) => {

        if (accessToken) {
            headers['authorization'] = `Bearer ${accessToken}`;
        }

        const response = await axios({
            url: Api.GRAPHQL_URL,
            method: 'post',
            data: JSON.stringify({
                query: query,
                variables: variables
            }),
            headers: headers
        });

        return response.data;
    }

    static checkForError = (json, field) => {
        if (json.errors) {
            const tokenErrors = json.errors.filter(e => e.path && e.path.filter(p => p === field).length);
            if (tokenErrors.length) {
                throw new Error(tokenErrors[0].message);
            } else {
                throw new Error("Server error");
            }
        }
    };
}
