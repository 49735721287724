const Colors = {
    background: 'rgba(255, 255, 255, 1)',
    inverseBackground: 'rgba(17, 32, 74, 1)',
    backgroundSecondary: 'rgba(236, 236, 236, 1)',
    background3: 'rgba(241, 242, 244, 1)',

    primary: 'rgba(106, 161, 234, 1)',
    primary2: 'rgba(166, 204, 255, 1)',
    primary3: 'rgba(240, 246, 253, 1)',
    primary4: 'rgba(94, 143, 255, 1)',
    primary5: 'rgba(197, 212, 247, 1)',
    primary6: 'rgba(0, 67, 255, 1)',

    secondary: 'rgba(248, 121, 80, 1)',
    secondary2: 'rgba(255, 155, 126, 1)',
    secondary3: 'rgb(231,84,38)',
    secondary4: 'rgb(251, 221, 212)',

    error: '#ec5757',

    success: 'rgba(0, 209, 21, 1)',
    success2: 'rgba(115, 255, 113, 1)',
    success3: 'rgba(115, 255, 113, 0.4)',
    success4: 'rgba(67, 171, 66, 1)',

    warning: 'rgba(179, 167, 63, 1)',
    warning2: 'rgb(241,236,183)',
    warning3: 'rgb(150,137,32)',

    select: 'rgba(42, 127, 239, 1)',

    redCard: 'rgba(255, 74, 107, 1)',
    redCard2: 'rgb(255,210,217)',
    blackCard: 'rgba(90, 97, 114, 1)',
    disabled: '#e0e0e0',

    onBackground: 'rgba(17, 32, 74, 1)',
    onBackground2: 'rgba(1, 11, 38, 0.5)',
    onBackground3: 'rgba(112, 121, 143, 1)',
    onBackground4: 'rgba(7, 18, 50, 1)',
    onInverseBackground: '#ffffff',
    onBackgroundBorder: 'rgba(160, 166, 183, 1)',
    onBackgroundBorder2: '#CCCCCC',
    onBackgroundBorder3: 'rgba(196, 196, 196, 0.5)',

    onPrimary: '#ffffff',
    onError: '#ffffff',
    onSuccess: '#ffffff',
    onRedCard: 'rgba(255, 255, 255, 1)',
    onRedCardSoft: 'rgba(113, 0, 20, 1)',
    onBlackCard: 'rgba(255, 255, 255, 1)',
    onBlackCardSoft: 'rgba(189, 189, 189, 1)',

    tournamentColor: 'rgba(255, 74, 107, 1)',
};

export default Colors;
