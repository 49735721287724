import React from "react";
import { TouchableOpacity, View } from "react-native";
import Colors from "../../styles/colors";
import StandardText from "../standardText/StandardText";

const TabGroup = ({ tabs, selectedTabId, onTabSelected }) => {

    return (
        <View>
            <View style={{...ownStyles.tabsContainer}}>
                {tabs.map((tab, index) => (
                    <TouchableOpacity style={{...ownStyles.tab}} key={index} onPress={() => {onTabSelected(tab.id)}}>
                        <StandardText style={{padding: 10}}>{tab.label}</StandardText>
                        {tab.id === selectedTabId && (
                            <View style={{height:2, backgroundColor: Colors.onBackgroundBorder2, width: '100%'}}></View>
                        )}
                    </TouchableOpacity>
                ))}
            </View>

            <View style={{height:1, backgroundColor: Colors.onBackgroundBorder3, width: '100%', marginTop: -1}}></View>
        </View>
    );
}

const ownStyles = {
    tabsContainer: {
        flexDirection: 'row'
    },
    tab: {
        paddingEnd: 0
    }
};

export default TabGroup;