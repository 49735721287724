import React, { useCallback, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import { useFocusEffect } from "@react-navigation/core";
import Spacing from "../../styles/spacing";
import Colors from "../../styles/colors";

const GameSetupStep = ({ stepLabel, stepDescription = 'Lorem ipsum dolor sit amet.', stepOptions = [], selectedOptionId = null, onOptionSelected, vertical = false, gameSetup, userAccount }) => {

    return (
        <View style={{
            flexDirection: 'column',
            width: '100%',
            maxWidth: 420,
            minWidth: 200,
            justifyContent: 'center',
            alignItems: 'stretch'
        }}>
            <View style={{ flexDirection: 'column', flexGrow: 1, marginTop: 10, paddingBottom: 10, paddingEnd: 10 }}>
                <StandardText style={{ fontSize: 24, weight: 500, lineHeight: 32, textAlign: 'start' }}>
                    {stepLabel}
                </StandardText>

                <StandardText style={{ fontSize: 16, weight: 400, color: Colors.onBackground3, lineHeight: 26, textAlign: 'start' }}>
                    {stepDescription}
                </StandardText>

                <View style={{ ...ownStyles.optionsContainer, flexDirection: vertical ? 'column' : 'row', alignItems: 'start' }}>
                    {stepOptions.map((option, i) => (
                        <View key={i} style={{ flexDirection: 'row', alignItems: 'start', justifyContent: 'center', flexDirection: vertical ? 'column' : 'row' }}>
                            <View style={{alignItems: 'center', flexDirection: vertical ? 'row' : 'column'}}>
                                <TouchableOpacity style={{ ...ownStyles.optionBox, ...(selectedOptionId === option.id ? ownStyles.optionBoxSelected : {}) }}
                                    onPress={() => onOptionSelected(option.id)}>
                                    <StandardText style={{ color: selectedOptionId === option.id ? Colors.primary4 : Colors.onBackgroundBorder2 }}>{option.label}</StandardText>
                                </TouchableOpacity>
                                {option.extra &&
                                    <StandardText style={{marginTop: 5, textAlign: vertical ? 'start' : 'center', marginStart: vertical ? 5 : 0, fontSize: 14, color: Colors.onBackgroundBorder}}>{option.extra}</StandardText>
                                }

                            </View>

                            {i < stepOptions.length - 1 && (
                                <View style={{ marginTop: vertical ? 10 : 10, marginBottom: vertical ? 10 : 0, marginStart: Spacing.large, marginEnd: Spacing.large }}>
                                    <StandardText style={{ color: Colors.onBackgroundBorder2 }}>or</StandardText>
                                </View>
                            )}
                        </View>
                    ))}
                </View>

            </View>
        </View>
    );
};

const ownStyles = {
    optionsContainer: {
        marginTop: Spacing.large,
        flexDirection: 'row'
    },
    optionBox: {
        borderRadius: 6,
        borderWidth: 1,
        backgroundColor: 'rgba(169, 175, 188, 0.1)',
        borderColor: Colors.onBackgroundBorder,
        padding: Spacing.extraLarge,
        paddingTop: Spacing.base,
        paddingBottom: Spacing.base,
        textAlign: 'center'
    },
    optionBoxSelected: {
        borderColor: Colors.primary4,
    }
};

export default GameSetupStep;
