import React, {useEffect, useRef} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import StyleContext from "./src/StyleContext";
import {connect} from "react-redux";
import {StyleSheet, View} from "react-native";
import AuthenticatedScreen from "./src/screens/authenticated/AuthenticatedScreen";
import UnauthenticatedScreen from "./src/screens/unauthenticated/UnauthenticatedScreen";
import Colors from "./src/styles/colors";
import Analytics from "./src/services/Analytics";

const Stack = createStackNavigator();
const linking = {
    config: {
        screens: {
            Authenticated: {
                screens: {
                    Home: {
                        path: 'home',
                        screens: {
                            ContinueGame: 'games',
                            GameStatistics: 'statistics',
                            GameOptions: 'game-options',
                            ManageProfile: 'profile'
                        }
                    },
                    CompleteUserAccountInfo: 'complete-user-info',
                    NewGame: 'new-game',
                    DailyTournament: 'daily-tournament',
                    GameTable: {
                        path: 'game/:id'
                    },
                    ScoreInPersonGame: 'score-in-person-game',
                    GameInsights: 'game-insights',
                    ManageSubscription: 'manage-subscription',
                    Rules: 'rules',
                    Tutorial: 'tutorial',
                    Help: 'help',
                    CreateDebugGame: 'create-debug-game',
                    Subscription: 'subscription',
                },
            },
            Unauthenticated: {
                screens: {
                    Start: '',
                    FacebookLogin: 'fblogin',
                    GoogleLogin: 'glogin',
                    RequestChangePassword: 'request-password-reset',
                    ChangePassword: 'change-password/:token',
                    JoinGame: {
                        path: 'join-game/:gameToken'
                    }
                },
            },
        },
    },
};

const RootContainer = ({userAccount}) => {
    const defaultTitle = 'Hand & Foot Friends & Family Edition';
    const navigationRef = useRef();
    const routeNameRef = useRef();

    useEffect(() => {
        if (userAccount) {
            Analytics.setUser(userAccount);
        }
    }, [userAccount]);

    return (
        <View style={{flex: 1, backgroundColor: 'magenta'}}>
            <StyleContext.Provider value={styles}>
                <NavigationContainer linking={linking}
                                     ref={navigationRef}
                                     onReady={async () => {
                                         routeNameRef.current = navigationRef.current.getCurrentRoute().name;
                                         await Analytics.setCurrentScreen(routeNameRef.current);
                                     }}
                                     onStateChange={async () => {
                                         const previousRouteName = routeNameRef.current;
                                         const currentRouteName = navigationRef.current.getCurrentRoute().name;

                                         if (previousRouteName !== currentRouteName) {
                                             await Analytics.setCurrentScreen(currentRouteName);
                                         }

                                         routeNameRef.current = currentRouteName;
                                     }}
                                     >
                    <Stack.Navigator screenOptions={{headerShown: false}}
                                     initialRouteName={'Unauthenticated'}>
                        <Stack.Screen name="Authenticated" component={AuthenticatedScreen}
                                      options={{title: defaultTitle}}/>
                        <Stack.Screen name="Unauthenticated" component={UnauthenticatedScreen}
                                      options={{title: defaultTitle}}/>
                    </Stack.Navigator>
                </NavigationContainer>
            </StyleContext.Provider>
        </View>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Colors.background,
        alignItems: 'center',
        justifyContent: 'center',
    },
    safeArea: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: Colors.background,
        maxHeight: '100%'
    },
    input: {
        backgroundColor: '#ffffff',
        color: Colors.onBackground,
        borderWidth: 1,
        borderColor: 'rgba(112, 121, 143, 1)',
        borderRadius: 5,
        padding: 12,
        marginTop: 8,
        marginBottom: 8
    },
    buttonWrapper: {
        alignItems: "stretch",
        margin: 5,
    },
    header: {
        fontSize: 30,
        textAlign: 'center',
        marginBottom: 0
    },
    subheader: {
        fontSize: 15,
        fontWeight: 'normal',
        textAlign: 'center',
        marginBottom: 20
    },
    title: {
        fontSize: 20,
        fontWeight: 'normal',
        textAlign: 'center',
        marginBottom: 0
    },
    link: {
        color: '#ffffff',
    },
    errorMessage: {
        color: Colors.redCard,
        fontSize: 14,
        textAlign: 'center',
    }
});

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount || null
    }
};

export default connect(mapStateToProps)(RootContainer);
