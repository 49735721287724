import * as React from 'react';
import {Svg, Path} from "react-native-svg";

const DiamondsIcon = ({size, color}) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
            <Path
                d="M5.5562 7.99278L9.75503 3.32742C10.053 2.99638 10.572 2.99638 10.87 3.32742L15.0688 7.99278C16.0956 9.13369 16.0956 10.8657 15.0688 12.0066L10.87 16.6719C10.572 17.003 10.053 17.003 9.75503 16.6719L5.5562 12.0066C4.52938 10.8657 4.52938 9.1337 5.5562 7.99278Z"
                fill={color}/>
        </Svg>
    );
};


export default DiamondsIcon;
