import Api from "../../../services/Api";
import UserSession from "../../../services/UserSession";
import Analytics from "../../../services/Analytics";
import { performGetViewerSubscription } from "../reducers/profileSlice";

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const submitLogin = () => ({
    type: SUBMIT_LOGIN
});

export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const receiveLogin = ({userAccount, accessToken, refreshToken, newUser}) => ({
    type: RECEIVE_LOGIN,
    payload: {
        userAccount: userAccount,
        accessToken: accessToken,
        refreshToken: refreshToken,
        newUser: newUser || false
    }
});

export const RECEIVE_USER_ACCOUNT = 'RECEIVE_USER_ACCOUNT';
export const receiveUserAccount = (userAccount) => ({
    type: RECEIVE_USER_ACCOUNT,
    payload: {
        userAccount: userAccount,
    }
});

export const RECEIVE_FAILED_LOGIN = 'RECEIVE_FAILED_LOGIN';
export const receiveFailedLogin = (error) => ({
    type: RECEIVE_FAILED_LOGIN,
    payload: {
        error: error
    }
});

export const SUBMIT_FACEBOOK_CODE_LOGIN = 'SUBMIT_FACEBOOK_CODE_LOGIN';
export const submitFacebookCodeLogin = () => ({
    type: SUBMIT_FACEBOOK_CODE_LOGIN
});

export const SUBMIT_GOOGLE_CODE_LOGIN = 'SUBMIT_GOOGLE_CODE_LOGIN';
export const submitGoogleCodeLogin = () => ({
    type: SUBMIT_GOOGLE_CODE_LOGIN
});

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
    type: LOGOUT
});

export const SUBMIT_LOCAL_LOGIN = 'SUBMIT_LOCAL_LOGIN';
export const submitLocalLogin = () => ({
    type: SUBMIT_LOCAL_LOGIN
});

export const RECEIVE_LOCAL_LOGIN = 'RECEIVE_LOCAL_LOGIN';
export const receiveLocalLogin = ({userAccount, accessToken, refreshToken, newUser}) => {
    return {
        type: RECEIVE_LOCAL_LOGIN,
        payload: {
            userAccount: userAccount,
            accessToken: accessToken,
            refreshToken: refreshToken,
            newUser: newUser || false
        }
    }
};

export const FAIL_LOCAL_LOGIN = 'FAIL_LOCAL_LOGIN';
export const failLocalLogin = () => ({
    type: FAIL_LOCAL_LOGIN
});

export const SUBMIT_REQUEST_CHANGE_PASSWORD = 'SUBMIT_REQUEST_CHANGE_PASSWORD';
export const submitRequestChangePassword = () => ({
    type: SUBMIT_REQUEST_CHANGE_PASSWORD
});

export const RECEIVE_REQUEST_CHANGE_PASSWORD = 'RECEIVE_REQUEST_CHANGE_PASSWORD';
export const receiveRequestChangePassword = () => ({
    type: RECEIVE_REQUEST_CHANGE_PASSWORD
});

export const RECEIVE_FAILED_REQUEST_CHANGE_PASSWORD = 'RECEIVE_FAILED_REQUEST_CHANGE_PASSWORD';
export const receiveFailedRequestChangePassword = () => ({
    type: RECEIVE_FAILED_REQUEST_CHANGE_PASSWORD
});


export const SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD';
export const submitChangePassword = () => ({
    type: SUBMIT_CHANGE_PASSWORD
});

export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD';
export const receiveChangePassword = () => ({
    type: RECEIVE_CHANGE_PASSWORD
});

export const RECEIVE_FAILED_CHANGE_PASSWORD = 'RECEIVE_FAILED_CHANGE_PASSWORD';
export const receiveFailedChangePassword = (error) => ({
    type: RECEIVE_FAILED_CHANGE_PASSWORD,
    payload: {
        error: error
    }
});

export const SUBMIT_JOIN_FROM_GAME_TOKEN = 'SUBMIT_JOIN_FROM_GAME_TOKEN';
export const submitJoinFromGameToken = () => ({
    type: SUBMIT_JOIN_FROM_GAME_TOKEN
});

export const RECEIVE_JOIN_FROM_GAME_TOKEN = 'RECEIVE_JOIN_FROM_GAME_TOKEN';
export const receiveJoinFromGameToken = ({userAccount, accessToken, refreshToken}, game) => ({
    type: RECEIVE_JOIN_FROM_GAME_TOKEN,
    payload: {
        userAccount: userAccount,
        accessToken: accessToken,
        refreshToken: refreshToken,
        joinedGameId: game.alphanumericId,
    }
});

export const RECEIVE_FAILED_JOIN_FROM_GAME_TOKEN = 'RECEIVE_FAILED_JOIN_FROM_GAME_TOKEN';
export const receiveFailedJoinFromGameToken = (error) => ({
    type: RECEIVE_FAILED_JOIN_FROM_GAME_TOKEN,
    payload: {
        error: error
    }
});

export const SUBMIT_COMPLETE_USER_ACCOUNT_INFO = 'SUBMIT_COMPLETE_USER_ACCOUNT_INFO';
export const submitCompleteUserAccountInfo = () => ({
    type: SUBMIT_COMPLETE_USER_ACCOUNT_INFO
});

export const RECEIVE_COMPLETE_USER_ACCOUNT_INFO = 'RECEIVE_COMPLETE_USER_ACCOUNT_INFO';
export const receiveCompleteUserAccountInfo = ({userAccount}) => ({
    type: RECEIVE_COMPLETE_USER_ACCOUNT_INFO,
    payload: {
        userAccount: userAccount
    }
});

export const RECEIVE_FAILED_COMPLETE_USER_ACCOUNT_INFO = 'RECEIVE_FAILED_COMPLETE_USER_ACCOUNT_INFO';
export const receiveFailedCompleteUserAccountInfo = (error) => ({
    type: RECEIVE_FAILED_COMPLETE_USER_ACCOUNT_INFO,
    payload: {
        error: error
    }
});

export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const clearLoginError = () => ({
    type: CLEAR_LOGIN_ERROR
});

export const performLogin = (username, password) => async (dispatch) => {
    dispatch(submitLogin());

    try {
        const tokenData = await Api.getToken(username, password);
        await UserSession.setTokenData(tokenData);
        dispatch(receiveLogin(tokenData));
    } catch (e) {
        dispatch(receiveFailedLogin(e.message));
    }
}

export const performRequestChangePassword = (email) => async (dispatch) => {
    dispatch(submitRequestChangePassword());

    try {
        await Api.requestChangePassword(email);
        dispatch(receiveRequestChangePassword());
    } catch (e) {
        dispatch(receiveFailedRequestChangePassword(e.message));
    }
}

export const performResetPassword = (token, password) => async (dispatch) => {
    dispatch(submitChangePassword());

    try {
        await Api.changePassword(token, password);
        dispatch(receiveChangePassword());
    } catch (e) {
        dispatch(receiveFailedChangePassword(e.message));
    }
}

export const performOAuthCodeLogin = (provider, code) => async (dispatch) => {
    if (provider === 'facebook') {
        dispatch(submitFacebookCodeLogin());
    } else if (provider === 'google') {
        dispatch(submitGoogleCodeLogin());
    } else {
        throw new Error("Invalid OAuth provider");
    }

    try {
        const tokenData = await Api.getTokenFromOAuthLogin(provider, null, code);
        await UserSession.setTokenData(tokenData);
        dispatch(receiveLogin(tokenData));
    } catch (e) {
        dispatch(receiveFailedLogin(e.message));
    }
}

export const performLogout = () => async (dispatch) => {
    await UserSession.clearTokenData();
    await UserSession.clearNewGameSetup();
    await UserSession.clearSubscription();
    await UserSession.clearLastSubscriptionCheck();
    await Analytics.setUser(null);
    dispatch(logout());
}

export const performLocalLogin = () => async (dispatch) => {
    dispatch(submitLocalLogin());
    const login = await UserSession.getTokenData();
    if (login) {
        dispatch(receiveLocalLogin(login));
    } else {
        dispatch(failLocalLogin());
    }
};


export const performJoinFromGameToken = (gameToken) => async (dispatch) => {
    dispatch(submitJoinFromGameToken());

    try {
        const {token, game} = await Api.joinFromGameToken(gameToken);
        await UserSession.setTokenData(token);
        dispatch(receiveJoinFromGameToken(token, game));
    } catch (e) {
        dispatch(receiveFailedLogin(e.message));
    }
};

export const performCompleteUserAccountInfo = (accessToken, emailAddress) => async (dispatch) => {
    dispatch(submitCompleteUserAccountInfo());

    try {
        const userAccount = await Api.completeUserAccountInfo(accessToken, emailAddress);
        const tokenData = await UserSession.getTokenData();

        dispatch(performGetViewerSubscription(true));

        tokenData.userAccount = userAccount;
        await UserSession.setTokenData(tokenData);
        dispatch(receiveCompleteUserAccountInfo(tokenData));
    } catch (e) {
        dispatch(receiveFailedCompleteUserAccountInfo(e.message));
    }
}
