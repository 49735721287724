import OAuthRedirectLoginScreen from "./OAuthRedirectLoginScreen";
import React from "react";

const FacebookRedirectLoginScreen = () => {
    return (
        <OAuthRedirectLoginScreen provider={'facebook'} />
    );
};

export default FacebookRedirectLoginScreen;
