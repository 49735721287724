import React, {useState} from "react";
import {TouchableOpacity, View} from "react-native";
import Colors from "../../styles/colors";
import HTML from "react-native-render-html";
import Svg, {Defs, LinearGradient, Path, Rect, Stop} from "react-native-svg";
import StandardText from "../standardText/StandardText";

const CoachingMark = ({coachingMark, onDismissPress, onHidePress, useMobilePosition = false}) => {

    const [currentPage, setCurrentPage] = useState(0);

    const config = JSON.parse(coachingMark.config);
    const totalPages = config.pages.length;
    const pageContent = coachingMark.parsedPages[currentPage];
    const boxPosition = useMobilePosition ? config.boxPositionMobile : config.boxPosition;
    const previousPageAvailable = currentPage > 0;
    const nextPageAvailable = currentPage < totalPages - 1;
    const validArrowPositions = [1,2,3,4,5,6,7,8,9,10,11,12];
    let arrowPosition = useMobilePosition ? config.arrowPositionMobile : config.arrowPosition;
    arrowPosition = validArrowPositions.includes(arrowPosition) ? arrowPosition : 0;

    const handleOnPreviousPagePress = () => {
        if (previousPageAvailable) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleOnNextPagePress = () => {
        if (nextPageAvailable) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleOnPageNumberPress = (page) => {
        if (page >= 0 && page <= totalPages - 1) {
            setCurrentPage(page);
        }
    };

    return (
        <View style={{...ownStyles.coachingMarkBox, ...boxPosition}}>
            {arrowPosition !== 0 && <View style={{...ownStyles.arrow, ...ownStyles['arrow' + arrowPosition]}}>
                <Svg width="23" height="12" viewBox="0 0 23 12" fill="none">
                    <Path d="M10.2932 10.7611L0 0L22.5 0L11.723 10.777C11.3263 11.1737 10.681 11.1665 10.2932 10.7611Z"
                          fill="white"/>
                    <Path
                        d="M10.6546 10.4155L1.17017 0.5L21.2929 0.5L11.3694 10.4235C11.1711 10.6218 10.8484 10.6182 10.6546 10.4155Z"
                        stroke="url(#paint0_linear)" strokeOpacity="0.2"/>
                    <Defs>
                        <LinearGradient id="paint0_linear" x1="1.5" y1="1.5" x2="1.5" y2="1"
                                        gradientUnits="userSpaceOnUse">
                            <Stop stopColor="#11204A"/>
                            <stop offset="1" stopColor="#11204A" stopOpacity="0"/>
                        </LinearGradient>
                    </Defs>
                </Svg>
            </View>}
            <View style={{...ownStyles.topContent}}>
                <View style={ownStyles.contentContainer}>
                    <HTML source={{html: pageContent}}
                          baseFontStyle={{lineHeight: 20, fontSize: 16, color: Colors.onBackground}}/>
                </View>
                <View style={ownStyles.buttonsContainer}>
                    <TouchableOpacity onPress={onDismissPress}>
                        <Svg width="26" height="26" viewBox="0 0 24 24" fill="none">
                            <Rect width="24" height="24" rx="0.6" fill="#11204A"/>
                            <Path
                                d="M6.48481 8.18208C6.2505 7.94776 6.2505 7.56786 6.48481 7.33355L7.33334 6.48502C7.56765 6.25071 7.94755 6.25071 8.18187 6.48502L17.5157 15.8188C17.75 16.0531 17.75 16.433 17.5157 16.6674L16.6671 17.5159C16.4328 17.7502 16.0529 17.7502 15.8186 17.5159L6.48481 8.18208Z"
                                fill="white"/>
                            <Path
                                d="M15.8189 6.48481C16.0532 6.2505 16.4331 6.2505 16.6674 6.48481L17.516 7.33334C17.7503 7.56765 17.7503 7.94755 17.516 8.18187L8.18215 17.5157C7.94783 17.75 7.56793 17.75 7.33362 17.5157L6.48509 16.6671C6.25077 16.4328 6.25077 16.0529 6.48509 15.8186L15.8189 6.48481Z"
                                fill="white"/>
                        </Svg>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onHidePress} style={{marginTop: 20}}>
                        <Svg width="26" height="26" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18ZM8 15L4 11L5.41 9.59L8 12.17L14.59 5.58L16 7L8 15Z"
                                fill="#2E3A59"/>
                        </Svg>
                    </TouchableOpacity>
                </View>
            </View>
            {totalPages > 1 &&
            <View style={{...ownStyles.bottomContent}}>
                <TouchableOpacity onPress={handleOnPreviousPagePress} disabled={!previousPageAvailable}>
                    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <Rect width="24" height="24" rx="3.6" fill="white"/>
                        <Path
                            d="M7.72114 12.4184C7.48682 12.1841 7.48682 11.8042 7.72114 11.5699L8.56967 10.7213C8.80398 10.487 9.18388 10.487 9.4182 10.7213L14.5148 15.8179C14.7491 16.0523 14.7491 16.4322 14.5148 16.6665L13.6663 17.515C13.432 17.7493 13.0521 17.7493 12.8177 17.515L7.72114 12.4184Z"
                            fill={previousPageAvailable ? Colors.primary4 : "#D3D3D3"}/>
                        <Path
                            d="M12.8189 6.48481C13.0532 6.2505 13.4331 6.2505 13.6674 6.48481L14.516 7.33334C14.7503 7.56765 14.7503 7.94755 14.516 8.18187L9.4323 13.2655C9.19799 13.4998 8.81809 13.4998 8.58378 13.2655L7.73525 12.417C7.50093 12.1827 7.50093 11.8028 7.73525 11.5685L12.8189 6.48481Z"
                            fill={previousPageAvailable ? Colors.primary4 : "#D3D3D3"}/>
                    </Svg>
                </TouchableOpacity>
                <View style={{...ownStyles.pageNumbersContainer}}>
                    {[...Array(totalPages)].map((e, i) =>
                        <TouchableOpacity key={i} style={{...ownStyles.pageNumberContainer}} onPress={() => {
                            handleOnPageNumberPress(i)
                        }} disabled={currentPage === i}>
                            <StandardText
                                style={{...ownStyles.pageNumber, ...(currentPage === i ? ownStyles.pageNumberActive : {})}}>{i + 1}</StandardText>
                        </TouchableOpacity>
                    )}
                </View>
                <TouchableOpacity onPress={handleOnNextPagePress} disabled={!nextPageAvailable}>
                    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <Rect x="24" y="24" width="24" height="24" rx="3.6" transform="rotate(180 24 24)" fill="white"/>
                        <Path
                            d="M16.2789 11.5816C16.5132 11.8159 16.5132 12.1958 16.2789 12.4301L15.4303 13.2787C15.196 13.513 14.8161 13.513 14.5818 13.2787L9.4852 8.18205C9.25089 7.94774 9.25089 7.56784 9.4852 7.33353L10.3337 6.485C10.568 6.25068 10.9479 6.25068 11.1823 6.485L16.2789 11.5816Z"
                            fill={nextPageAvailable ? Colors.primary4 : "#D3D3D3"}/>
                        <Path
                            d="M11.1811 17.5152C10.9468 17.7495 10.5669 17.7495 10.3326 17.5152L9.48404 16.6667C9.24973 16.4323 9.24973 16.0524 9.48404 15.8181L14.5677 10.7345C14.802 10.5002 15.1819 10.5002 15.4162 10.7345L16.2648 11.583C16.4991 11.8173 16.4991 12.1972 16.2648 12.4315L11.1811 17.5152Z"
                            fill={nextPageAvailable ? Colors.primary4 : "#D3D3D3"}/>
                    </Svg>
                </TouchableOpacity>
            </View>}
        </View>
    );
};

const ownStyles = {
    coachingMarkBox: {
        position: 'absolute',
        padding: 18,
        borderRadius: 4,
        boxShadow: '0px 8px 16px rgba(17, 32, 74, 0.2)',
        borderWidth: 1,
        borderColor: 'rgba(17, 32, 74, 0.2)',
        background: Colors.background,
        width: 365,
        height: 245,
        zIndex: 2
    },
    topContent: {
        flexDirection: 'row',
        flex: 1,
    },
    contentContainer: {
        flex: 1,
    },
    buttonsContainer: {
        flexGrow: 0,
        marginStart: 20,
        alignItems: 'center'
    },
    bottomContent: {
        flexGrow: 0,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageNumbersContainer: {
        flexDirection: 'row',
    },
    pageNumberContainer: {
        marginStart: 5,
        marginEnd: 5
    },
    pageNumber: {
        color: Colors.primary2,
        fontSize: 16,
    },
    pageNumberActive: {
        color: Colors.primary4
    },
    arrow: {
        position: 'absolute',
        width: 23,
    },
    arrow1: {transform: 'rotate(180deg)', top: -11, right: 50},
    arrow2: {transform: 'rotate(270deg)', top: 50, right: -16},
    arrow3: {transform: 'rotate(270deg)', top: 120, right: -16},
    arrow4: {transform: 'rotate(270deg)', bottom: 50, right: -16},
    arrow5: {transform: 'rotate(0deg)', bottom: -11, right: 50},
    arrow6: {transform: 'rotate(0deg)', bottom: -11, left: 170},
    arrow7: {transform: 'rotate(0deg)', bottom: -11, left: 50},
    arrow8: {transform: 'rotate(90deg)', bottom: 50, left: -16},
    arrow9: {transform: 'rotate(90deg)', top: 120, left: -16},
    arrow10: {transform: 'rotate(90deg)', top: 50, left: -16},
    arrow11: {transform: 'rotate(180deg)', top: -11, left: 50},
    arrow12: {transform: 'rotate(180deg)', top: -11, left: 170},
};

export default CoachingMark;
