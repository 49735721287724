import React from 'react';
import {TouchableOpacity, View} from "react-native";
import StandardText from "../standardText/StandardText";
import Colors from "../../styles/colors";
import Spacing from "../../styles/spacing";

const states = {
    active: [
        'active',
        'active_pricing_transition',
    ],
    warning: [
        'trialing_without_payment_method',
        'trialing_with_payment_method',
        'trialing_with_payment_method_canceled',
        'active_pending_cancellation',
        'active_payment_fail'
    ],
    inactive: [
        'trialing_expired',
        'canceled',
    ]
};

const SubscriptionStatus = ({subscription, displayLinkInDescription = false, onLinkInDescriptionPress = () => {}}) => {

    let currentStatusColor;
    if (states.active.includes(subscription?.subscriptionDetails.state)) {
        currentStatusColor = Colors.success4;
    } else if (states.warning.includes(subscription?.subscriptionDetails.state)) {
        currentStatusColor = Colors.warning;
    } else if (states.inactive.includes(subscription?.subscriptionDetails.state)) {
        currentStatusColor = Colors.error;
    }

    let description;
    let linkedText;
    if (displayLinkInDescription) {
        linkedText = subscription?.subscriptionDetails.statusDescription.includes('re-subscribe now') ? 're-subscribe now' : 'Subscribe now';
        description = (subscription?.subscriptionDetails.statusDescription ?? '').split(` ${linkedText} `);
    } else {
        description = [subscription?.subscriptionDetails.statusDescription ?? ''];
    }

    return (
        <View>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                marginBottom: Spacing.base
            }}>
                <StandardText style={{
                    fontSize: 14,
                    color: currentStatusColor,
                    padding: 5,
                    backgroundColor: Colors.backgroundSecondary,
                    borderRadius: 5
                }}>
                    {subscription?.subscriptionDetails?.statusLabel ?? ''}
                </StandardText>
            </View>

            <StandardText>
                <StandardText style={{...ownStyles.sectionDescription}}>
                    {`${description[0] ?? ''} `}
                </StandardText>

                {!!description[1] && (<TouchableOpacity onPress={onLinkInDescriptionPress}>
                    <StandardText style={{...ownStyles.sectionDescription, color: Colors.primary}}>
                        {linkedText}
                    </StandardText>
                </TouchableOpacity>)}

                <StandardText style={{...ownStyles.sectionDescription}}>
                    {` ${description[1] ?? ''}`}
                </StandardText>
            </StandardText>


        </View>
    );
};

const ownStyles = {
    sectionDescription: {
        fontSize: 14,
        lineHeight: 20,
        weight: 400,
        color: Colors.onBackgroundBorder,
    },
};

export default SubscriptionStatus;
