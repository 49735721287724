import * as React from 'react';
import {Svg, Circle, Rect, Path} from "react-native-svg";

const ClubsIcon = ({size, color}) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
            <Circle cx="10" cy="6.25" r="3.75" fill={color}/>
            <Circle cx="6.25" cy="12.5" r="3.75" fill={color}/>
            <Circle cx="13.75" cy="12.5" r="3.75" fill={color}/>
            <Rect x="8.33325" y="9.16699" width="3.33333" height="3.33333" fill={color}/>
            <Path d="M10 12.5V17.8125" stroke={color} strokeWidth="1.66667"/>
        </Svg>

    );
};


export default ClubsIcon;
