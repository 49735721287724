import React, { useCallback, useContext, useEffect, useState } from "react";
import { ActivityIndicator, View, Dimensions } from "react-native";
import { useWindowDimensions } from "react-native-web";
import StandardText from "../../sharedComponents/standardText/StandardText";
import StyleContext from "../../StyleContext";
import { SafeAreaView } from "react-native-safe-area-context";
import { connect } from "react-redux";
import {
    applyPartialStates,
    fetchViewerGame,
    performGameAction
} from "../../redux/viewer/gameSlice";
import Colors from "../../colors";
import { useFocusEffect } from "@react-navigation/core";
import Wamp from "../../services/Wamp";
import GameHelper from "../../game/GameHelper";
import GameTable from "../../game/components/GameTable";
import NotificationMessage from "../../sharedComponents/notificationMessage/NotificationMessage";
import { LoadingContext } from "../../context/LoadingContext";
import Api from "../../services/Api";
import { DEBUG } from "../../env.json";
import SubscriptionBlocked from "../../game/components/SubscriptionBlocked";

const GameTableScreen = ({
    dispatch,
    navigation,
    route,
    userAccount,
    accessToken,
    settings,
    game,
    gameLoading,
    gameError,
    gamePerformingAction,
    lastActionId,
    subscription
}) => {

    const [gameAlphanumericId, setGameAlphanumericId] = useState(null);
    const [playingGame, setPlayingGame] = useState(null);
    const [performingAction, setPerformingAction] = useState(false);
    const [currentActionId, setCurrentActionId] = useState(null);
    const [inPartialState, setInPartialState] = useState(false);
    const [triggeredInitialFetch, setTriggeredInitialFetch] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [wampConnection, setWampConnection] = useState(null);
    const [wampConnectionIsOpen, setWampConnectionIsOpen] = useState(false);
    const [wampConnectionInitiated, setWampConnectionInitiated] = useState(false);
    const [currentPlayerMessage, setCurrentPlayerMessage] = useState(null);
    const [canPutNewPlayerMessage, setCanPutNewPlayerMessage] = useState(true);
    const [showScore, setShowScore] = useState(false);
    const [messagesQueue, setMessagesQueue] = useState([]);
    const [isUserAccountBlocked, setIsUserAccountBlocked] = useState(null);
    const [firstPartialStates, setFirstPartialStates] = useState(null);
    const [firstMessages, setFirstMessages] = useState(null);
    const [generalAppInfo, setGeneralAppInfo] = useState(null);
    const [playFreeButtonPressed, setPlayFreeButtonPressed] = useState(false);
    const loadingContext = useContext(LoadingContext);

    const windowWidth = useWindowDimensions().width;
    const windowHeight = useWindowDimensions().height;
    const isMobileScreen = windowWidth <= 900;
    const isIpadPortraitScreen = isMobileScreen && windowHeight >= 820;

    const closeWampConnection = () => {
        if (wampConnection) {
            if (wampConnection.isOpen) {
                wampConnection.close();
                wampConnection.close();
            }
            setWampConnection(null);
        }
    }

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getInfo(accessToken);
        }
    }, [accessToken]));

    useFocusEffect(
        useCallback(() => {
            if (DEBUG && game && game.hasStarted) {
                GameHelper.getAllPlayers(game).filter((tp) => tp.userAccount === null).forEach((tp) => {
                    let handCards = [...tp.hand.handCards];
                    handCards.sort((a, b) => a.card.cardRank.sortOrder - b.card.cardRank.sortOrder);
                    console.log(tp.firstName, handCards.map((hc) => `${hc.card.cardRank.value}${(hc.card.cardSuit?.symbol ?? '')}`));
                });
            }
        }, [game])
    );

    useEffect(() => {
        return navigation.addListener('blur', (e) => {
          navigation.setParams({ from_score: undefined });
        });
      }, [navigation]);

    useFocusEffect(
        useCallback(() => {
            if (route.params && route.params.from_score) {
                setShowScore(true);
            } else {
                setShowScore(false);
            }

        }, [route.params])
    );

    useFocusEffect(
        useCallback(() => {
            return () => {
                if (gameAlphanumericId) {
                    setShowScore(false);
                }
            };
        }, [gameAlphanumericId, showScore])
    );

    useFocusEffect(
        useCallback(() => {
            loadingContext.setScreenLoading(firstPartialStates === null || (!showTable && !gameError));
            setPlayFreeButtonPressed(false)
        }, [showTable, gameError])
    );

    useFocusEffect(
        useCallback(() => {
            if (inPartialState || (currentActionId !== null && currentActionId === lastActionId)) {
                setTimeout(() => {
                    setPerformingAction(false);
                }, 300);
            }
        }, [currentActionId, lastActionId, inPartialState])
    );

    useFocusEffect(
        useCallback(() => {
            return () => {
                setMessagesQueue([]);
                setCurrentPlayerMessage(null);
                setPlayingGame(null);
                setGameAlphanumericId(null);
                setTriggeredInitialFetch(false);
                setShowTable(false);
                setIsUserAccountBlocked(null);
                setFirstPartialStates(null);
            }
        }, [])
    );

    useFocusEffect(
        useCallback(() => {
            if (!route.params.id) {
                navigation.navigate('Home');
            } else {
                setGameAlphanumericId(route.params.id);
                if (route.params.e?.ps) {
                    setFirstPartialStates(route.params.e.ps);
                } else {
                    setFirstPartialStates([]);
                }
                if (route.params.e?.gm) {
                    setFirstMessages(route.params.e.gm);
                } else {
                    setFirstMessages([]);
                }
            }
        }, [dispatch, route])
    );

    useFocusEffect(
        useCallback(() => {
            if (game && firstPartialStates?.length > 0) {
                dispatch(applyPartialStates(firstPartialStates));
                setFirstPartialStates([]);
            }

            if (game && firstMessages?.length > 0) {
                const messages = firstMessages.filter(m => m[0] === userAccount.id).map(m => ({ message: m[1], ttl: m[2] ?? null, overridable: m[3] ?? false }))
                setMessagesQueue(messages);
                setFirstMessages([]);
            }
        }, [game, firstPartialStates, firstMessages])
    );

    useFocusEffect(
        useCallback(() => {
            return () => {
                closeWampConnection();
            }
        }, [navigation, wampConnection])
    );

    useFocusEffect(
        useCallback(() => {
            if (userAccount && gameAlphanumericId) {
                dispatch(fetchViewerGame(gameAlphanumericId, false, false));
                setTriggeredInitialFetch(true);
            }
        }, [userAccount, gameAlphanumericId])
    );

    useFocusEffect(
        useCallback(() => {
            if (subscription?.subscriptionDetails?.isUserAccountBlocked === true) {
                setIsUserAccountBlocked(true);
            } else if (subscription?.subscriptionDetails?.isUserAccountBlocked === false) {
                setIsUserAccountBlocked(false);
            }
        })
    );


    useFocusEffect(
        useCallback(() => {
            let isMounted = true;
            if (showTable && playingGame && !wampConnection) {
                setWampConnection(Wamp.subscribe(playingGame.id, GameHelper.getViewerPlayer(playingGame).id,
                    (args) => {
                        const partialStates = args[2] ?? [];
                        if (args[0] && args[1] && args[0] === 'updated' && args[1] !== userAccount.id && partialStates.length === 0) {
                            dispatch(fetchViewerGame(gameAlphanumericId, true, false));
                        } else if (partialStates.length > 0) {
                            setInPartialState(true);
                            dispatch(applyPartialStates(partialStates, currentActionId));
                        }
                    },
                    (args) => {
                        if (args[0] && args[1] && args[0] === userAccount.id) {
                            const ttl = args[2] ?? null;
                            const m = { "message": args[1], "ttl": ttl, overridable: args[3] ?? false };
                            setMessagesQueue((prev) => [...prev, m]);
                        }
                    },
                    () => {
                        setWampConnectionIsOpen(true);
                        setWampConnectionInitiated(true);
                        dispatch(fetchViewerGame(gameAlphanumericId, true, false, null, true));
                    },
                    () => {
                        if (isMounted) {
                            setWampConnectionIsOpen(false)
                        }
                    }
                ));
            }

            return () => {
                isMounted = false;
            }
        }, [showTable, playingGame])
    );

    useFocusEffect(
        useCallback(() => {
            if (game) {
                setPlayingGame(game);
            }
        }, [game])
    );

    useFocusEffect(
        useCallback(() => {
            setShowTable(triggeredInitialFetch && !gameLoading && gameAlphanumericId && playingGame);
        }, [triggeredInitialFetch, gameLoading, gameAlphanumericId, playingGame])
    );

    useFocusEffect(
        useCallback(() => {
            if (canPutNewPlayerMessage && messagesQueue.length > 0) {
                setCanPutNewPlayerMessage(false);
                showNextMessage(1);
            }
        }, [messagesQueue, canPutNewPlayerMessage, currentPlayerMessage])
    );

    const getInfo = async (accessToken) => {
        const generalAppInfoData = await Api.generalAppInfo(accessToken);
        setGeneralAppInfo(generalAppInfoData);
    };

    const showNextMessage = useCallback((from) => {
        if (messagesQueue.length > 0) {
            const nextMessage = messagesQueue[0];
            setMessagesQueue((prev) => prev.slice(1));
            setCurrentPlayerMessage(nextMessage);

            if (nextMessage.overridable) {
                setCanPutNewPlayerMessage(true);
            }
        }
    }, [messagesQueue]);

    const handleOnNotificationCleared = () => {
        setCurrentPlayerMessage(null);
        setCanPutNewPlayerMessage(true);
    };

    const handleGameActionTriggered = async (gameAction, cardIds = [], extraArgs = []) => {
        setPerformingAction(true);
        const actionId = new Date().getTime();
        setCurrentActionId(actionId);
        setInPartialState(false);
        dispatch(performGameAction(gameAlphanumericId, gameAction, cardIds, extraArgs, actionId));
    };

    const handleOnGameSwitched = () => {
        closeWampConnection();
        setMessagesQueue([]);
        setCurrentPlayerMessage(null);
    };

    const handleOnHideCoachingMark = async (id) => {
        await Api.hideCoachingMark(accessToken, id);
    };

    const handleOnReadCoachingMark = async (id) => {
        await Api.readCoachingMark(accessToken, id);
    };

    const connectToWamp = () => {
        
    };

    return (
        <StyleContext.Consumer>
            {styles => (
                <SafeAreaView style={[styles.safeArea, styles.container, { flexDirection: 'column' }]}>
                    {DEBUG && !wampConnectionIsOpen && wampConnectionInitiated &&
                        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 3, backgroundColor: Colors.redCard, zIndex: 1 }} />
                    }
                    {showTable && game.isRoundFinished && !game.hasEnded && generalAppInfo && isUserAccountBlocked && !playFreeButtonPressed &&
                        <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1 }}>
                            <SubscriptionBlocked
                                navigation={navigation}
                                contactUsUrl={generalAppInfo.submitSupportRequestUrl}
                                freePlanScreenConfig={JSON.parse(generalAppInfo.freePlanScreenConfig)}
                                annualSave={generalAppInfo.annualSave}
                                onPlayFreeButtonPress={() => {setPlayFreeButtonPressed(true)}}
                            />
                        </View>
                    }
                    <View style={{ flex: 1, alignItems: 'center', width: '100%' }}>
                        {showTable ? (
                            <GameTable
                                accessToken={accessToken}
                                game={playingGame}
                                roundScoreOpen={game.isRoundFinished}
                                scoreBoardOpen={showScore}
                                onGameActionTriggered={handleGameActionTriggered}
                                onGoHomePressed={() => {
                                    navigation.navigate('Home')
                                }}
                                onLeaderboardPress={() => {
                                    navigation.navigate('DailyTournament', {from_game: 1, solo: GameHelper.getIsSolo(game) ? '1' : '0', short: GameHelper.getIsShort(game) ? '1' : '0', date: game.duplicateConfigGame.date})
                                }}
                                windowHeight={windowHeight}
                                disableInteractions={performingAction}
                                showRankLabels={(!isMobileScreen || isIpadPortraitScreen) && settings?.showRankLabels}
                                notificationMessage={<NotificationMessage
                                    message={currentPlayerMessage?.message === '<empty>' ? '' : currentPlayerMessage?.message}
                                    timeout={currentPlayerMessage?.ttl ? currentPlayerMessage.ttl : 8000} onClear={handleOnNotificationCleared} />}
                                onGameSwitched={handleOnGameSwitched}
                                onHideCoachingMark={handleOnHideCoachingMark}
                                onCoachingMarkDisplayed={handleOnReadCoachingMark}
                                mobileDisplay={isMobileScreen}
                                ipadPortraitDisplay={isIpadPortraitScreen}
                            />
                        ) : (
                            <View style={[ownStyles.tableContainer, { justifyContent: 'center' }]}>
                                <View>
                                    {gameError ? (
                                        <StandardText style={{ color: Colors.error }}>{gameError}</StandardText>
                                    ) : (
                                        <ActivityIndicator color={Colors.onBackground} />
                                    )}
                                </View>
                            </View>
                        )}
                    </View>


                </SafeAreaView>
            )}
        </StyleContext.Consumer>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        accessToken: state.auth.login.accessToken,
        game: state.viewer.game.game,
        lastActionId: state.viewer.game.lastActionId,
        gameLoading: state.viewer.game.loading,
        gamePerformingAction: state.viewer.game.isPerformingAction,
        gameError: state.viewer.game.error,
        subscription: state.auth.profile.subscription,
        settings: state.auth.profile.settings
    }
};

export default connect(mapStateToProps)(GameTableScreen);

const ownStyles = {
    stepWithArrow: {
        flexDirection: 'row',
    },
    drawImg: {
        width: 200,
        height: 100,
        resizeMode: 'contain',
        marginVertical: 30,
        marginLeft: -20,
    },
    cardsImg: {
        width: 250,
        height: 120,
        resizeMode: 'contain',
    },
    close: {
        width: '100%',
        height: 40,
        zIndex: 40,
        position: 'absolute',
        top: 10,
        left: 10,
    },
    closeImg: {
        width: 40,
        height: 40,
        resizeMode: 'contain'
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
    centeredContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 100,
    },
    playerCardsContainer: {
        width: 280,
        flexDirection: 'row',
    },
    tableContainer: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10
    },
    overlay: {
        position: 'absolute',
        flex: 1,
        zIndex: 10,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#EEEEEE',
        opacity: 0.93,
    },
    imageBackground: {
        position: 'absolute',
        zIndex: 9,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        resizeMode: 'stretch'
    },
    scoreBg: {
        top: 0,
        left: 0,
        width: 1200,
        height: 700,
        resizeMode: 'contain',
    },
    coachTextContainer: {
        alignItems: 'center',
        backgroundColor: '#EEEEEE',
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        height: 500,
        zIndex: 11,
        marginTop: -140,
    },
    coachingStepHeading: {
        fontSize: 22,
        marginVertical: 30,
    },
    innerContainer: {
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 2,
        bottom: 0,
        position: 'absolute',
        height: Dimensions.get('screen').height,
        zIndex: 11,
    },
    button: {
        backgroundColor: '#76A1E4',
        height: 40,
        width: 150,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        marginHorizontal: 20,
    },
    back: {
        backgroundColor: 'white',
    },
    initialText: {
        fontSize: 20,
        fontWeight: 300,
        width: 430,
        color: '#142147',
    },
    meldImage: {
        width: 320,
        height: 120,
        resizeMode: 'contain',
        fontSize: 33,
        weight: 500,
        color: '#142147',
    },
    bottomContainer: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#76A1E4',
        paddingBottom: 5
    },
    whiteBox: {
        backgroundColor: 'white',
        width: 400,
        borderColor: '#CDCFD7',
        borderRadius: 6,
        padding: 15,
        marginVertical: 10,
    },
    footerButtonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        bottom: 150,
        position: 'absolute',
    }
};
