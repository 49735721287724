import {RECEIVE_VIEWER_FRIENDS, REQUEST_VIEWER_FRIENDS} from "../actions/friends";

const friends = (
    state = {
        data: [],
        cursor: null,
        loading: false
    }
    , action) => {
    switch (action.type) {
        case REQUEST_VIEWER_FRIENDS:
            return Object.assign({}, state, {
                loading: true
            });
        case RECEIVE_VIEWER_FRIENDS:
            return Object.assign({}, state, {
                data: [...(action.payload.reset ? []: state.data), ...action.payload.friends.edges],
                cursor: action.payload.friends.edges.length ? action.payload.friends.pageInfo.endCursor : state.cursor,
                loading: false
            });
    }

    return state;
};

export default friends;
