import {combineReducers} from "redux";
import friends from "./friends";
import gameReducer from "./../gameSlice";
import gameSetup from "./../gameSetupSlice";
import tutorial from "./../tutorialSlice";
import computerPlayer from "./../computerPlayerSlice";
import games from "./games";

const viewer = combineReducers({
    friends: friends,
    game: gameReducer,
    games: games,
    gameSetup: gameSetup,
    tutorial: tutorial,
    computerPlayer: computerPlayer
});

export default viewer;
