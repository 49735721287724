import { useFocusEffect, useNavigation } from '@react-navigation/core';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { ActivityIndicator, Image, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from "react-redux";
import Colors from "../../styles/colors";
import { LoadingContext } from '../../context/LoadingContext';
import SubscriptionBlocked from '../../game/components/SubscriptionBlocked';
import Api from '../../services/Api';
import StyleContext from '../../StyleContext';
import StandardText from '../../sharedComponents/standardText/StandardText';
import blurredSuitsBg from './../../../assets/blurred_suits_bg.png';
import LoadingButton from '../../sharedComponents/loadingButton/LoadingButton';
import DailyTournamentLeaderboards from '../../game/components/DailyTournamentLeaderboards';
import { format, toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { add, isEqual, parseISO, sub } from 'date-fns';
import IconButton from '../../sharedComponents/iconButton/IconButton';
import ChevronIcon from '../../icons/ChevronIcon';
import TabGroup from '../../sharedComponents/tabGroup/TabGroup';
import WebView from "react-native-web-webview/src";
import Timer from '../../sharedComponents/timer/Timer';
import GameStatisticsBoards from '../../game/components/GameStatisticsBoards';
import { ScrollView } from 'react-native-web';

const GameStatisticsScreen = ({ dispatch, accessToken, userAccount, subscription, route }) => {

    const navigation = useNavigation();
    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);

    const [generalAppInfo, setGeneralAppInfo] = useState(null);
    const [selectedTab, setSelectedTab] = useState(1);
    const [statsRows, setStatsRows] = useState(null);
    const [loadingStats, setLoadingStats] = useState(false);
    const [solo, setSolo] = useState(null);
    const [byPlayers, setByPlayers] = useState(1);
    const [canastasRequired, setCanastasRequired] = useState(null);
    const containerRef = useRef();

    useFocusEffect(useCallback(() => {
        zE('webWidget', 'show');
        resetFilters();

        return navigation.addListener('tabPress', (e) => {
            resetFilters();
            containerRef.current?.scrollTo({
                y: 0,
                animated: true,
            });
        });

    }, [navigation]));

    useFocusEffect(useCallback(() => {
        if (generalAppInfo) {
            loadingContext.setScreenLoading(false);
        }
    }, [generalAppInfo]));

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getInfo(accessToken);
        }
    }, [accessToken]));

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getMetrics();
        }
    }, [accessToken, solo, byPlayers, canastasRequired]));

    const resetFilters = () => {
        setSelectedTab(1);
        setSolo(null);
        setByPlayers(1);
        setCanastasRequired(null);
    }

    const getInfo = async (accessToken) => {
        const generalAppInfoData = await Api.generalAppInfo(accessToken);
        setGeneralAppInfo(generalAppInfoData);
    };

    const getMetrics = async () => {
        setLoadingStats(true);
        console.log(canastasRequired);
        const metrics = await Api.viewerMetrics(accessToken, solo, byPlayers === 2, byPlayers === 3, canastasRequired);
        let rows = [];
        if (metrics.length > 0) {
            rows = metrics.map((row) => ({
                metrics: row.metrics,
                dimensions: row.dimensions
            }));
        }

        setStatsRows(rows);
        setLoadingStats(false);
    };

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch'
            }]}>

            <View style={{ ...ownStyles.blurredSuitsBgContainer }}>
                <Image source={{ uri: blurredSuitsBg }} style={{ width: 300, height: 306 }} />
            </View>

            <View style={{ ...ownStyles.topBar, }}>
                <View>
                    <StandardText style={{ ...ownStyles.screenTitle }}>Game Statistics</StandardText>
                </View>
            </View>

            <ScrollView ref={containerRef} contentContainerStyle={{ height: '100%', justifyContent: 'stretch' }}>
                <View style={{ width: '100%', alignItems: 'center' }}>
                    <View style={{ ...ownStyles.overviewContainer, height: 200 }}>
                        {generalAppInfo &&
                            <WebView
                                source={{ html: '<html><head><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap" rel="stylesheet"><style>body {font-family: \'Roboto\', sans-serif}</style></head><body>' + generalAppInfo.gameStatsOverview + '</body></html>' }} />
                        }
                    </View>
                </View>

                <View style={{ flex: 1, flexGrow: 1, alignItems: 'center', paddingBottom: 30 }}>
                    <View style={{ flex: 1, width: '100%', maxWidth: 500 }}>

                        <TabGroup selectedTabId={selectedTab} tabs={[{ id: 1, label: 'Stats' }, { id: 2, label: 'Insights' }]} onTabSelected={(id) => { setSelectedTab(id) }} />

                        {selectedTab === 1 && (
                            <GameStatisticsBoards
                                loading={loadingStats}
                                rows={statsRows}
                                solo={solo}
                                onSoloChanged={v => setSolo(v)}
                                byPlayers={byPlayers}
                                onByPlayersChanged={v => setByPlayers(v)}
                                canastasRequired={canastasRequired}
                                onCanastasRequiredChanged={v => setCanastasRequired(v)}
                            />
                        )}

                        {selectedTab === 2 && generalAppInfo && (
                            <View style={{ ...ownStyles.overviewContainer, height: '100%' }}>
                                <WebView
                                    source={{ html: '<html><head><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap" rel="stylesheet"><style>body {font-family: \'Roboto\', sans-serif}</style></head><body>' + generalAppInfo.gameStatsInsights + '</body></html>' }} />
                            </View>
                        )}

                        <View style={{ marginTop: 50, position: 'absolute', top: 120, width: '100%', textAlign: 'center' }}>
                            {loadingStats ? (
                                <ActivityIndicator animating={true} color={Colors.tournamentColor} />
                            ) : (
                                <View>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </ScrollView>

        </SafeAreaView>
    );
};

const ownStyles = {
    topBar: {
        width: '100%',
        padding: 20,
        height: 82,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 20,
        weight: 500,
        color: Colors.onBackground4
    },
    blurredSuitsBgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    overviewContainer: {
        width: '100%',
        maxWidth: 500,
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    statsContainer: {

    },
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.login.accessToken,
        userAccount: state.auth.login.userAccount,
        gamesError: state.viewer.games.error,
        subscription: state.auth.profile.subscription,
    }
};

export default connect(mapStateToProps)(GameStatisticsScreen);