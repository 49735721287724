import {combineReducers} from "redux";
import login from "./login";
import config from "./config";
import profile from "./profileSlice";

const auth = combineReducers({
    login: login,
    config: config,
    profile: profile
});

export default auth;
