import React, {useState} from "react";
import {TouchableOpacity, View} from "react-native";
import StandardText from "../standardText/StandardText";
import GameHelper from "../../game/GameHelper";
import {format, parseISO} from 'date-fns'
import Colors from "../../styles/colors";
import ChevronIcon from "../../icons/ChevronIcon";
import ClubsIcon from "../../game/components/suits/ClubsIcon";
import HeartsIcon from "../../game/components/suits/HeartsIcon";
import DiamondsIcon from "../../game/components/suits/DiamondsIcon";
import SpadesIcon from "../../game/components/suits/SpadesIcon";
import PlayerBadge from "../../game/components/PlayerBadge";
import LoadingButton from "../loadingButton/LoadingButton";

const GameItem = ({game, onPress, onArchiveTogglePress, onRemoveTogglePress}) => {

    const [toggleIndex, setToggleIndex] = useState(0);

    const totalPlayers = GameHelper.getAllPlayers(game).length;
    const isViewerTurn = GameHelper.getViewerPlayer(game).isCurrentTurn;
    const currentTurnPlayer = GameHelper.getAllPlayers(game).find((player) => player.isCurrentTurn);
    const isDailyTournamentGame = !!game.duplicateConfigGame;
    const createdAt = format(parseISO(game.createdAt), "MM/dd/yyyy 'at' HH:mm");
    const endedAt = game.endDate ? format(parseISO(game.endDate), "MM/dd/yyyy 'at' HH:mm") : null;

    const addThousandsCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const viewerTeamScore = GameHelper.getViewerTeam(game).gameTeamScores.reduce(
        (acc, score) => acc + score.bonusCount + score.baseCount + score.secondCount, 0
    );
    const opponentTeamScore = GameHelper.getOpponentTeam(game).gameTeamScores.reduce(
        (acc, score) => acc + score.bonusCount + score.baseCount + score.secondCount, 0
    );

    const partnerPlayer = GameHelper.getViewerPartnerPlayer(game);
    const opponentPlayer1 = GameHelper.getOpponentTeam(game).teamPlayers[0];
    const opponentPlayer2 = totalPlayers > 2 ? GameHelper.getOpponentTeam(game).teamPlayers[1] : null;
    const viewerScoreText = `${totalPlayers > 2 ? `You and ${partnerPlayer.firstName}` : 'You'}: ${addThousandsCommas(viewerTeamScore)}`;
    const opponentScoreText = `${opponentPlayer1.firstName} ${totalPlayers > 2 ? `and ${opponentPlayer2.firstName}` : ''}: ${addThousandsCommas(opponentTeamScore)}`;
    const itemColor = isDailyTournamentGame ? Colors.tournamentColor : (isViewerTurn ? 'rgba(136, 80, 255, 1)' : 'rgba(248, 121, 80, 1)');
    const togglableItems = isViewerTurn ? 5 : (game.hasEnded ? 3 : 4);


    const handleTogglePress = () => {
        setToggleIndex((v) => {
            return v === togglableItems - 1 ? 0 : v + 1;
        });
    };

    const translateToggleIndex = (index) => {
        return isViewerTurn ? index : index + 1;
    };

    const getSuitIcon = () => {
        if (isDailyTournamentGame) return null;
        const i = game.alphanumericId.charCodeAt(0) % 4;
        const color = isViewerTurn ? 'rgba(136, 59, 229, 1)' : 'rgba(255, 155, 126, 1)';
        const size = 200;
        switch (i) {
            case 0:
                return <ClubsIcon color={color} size={size}/>;
            case 1:
                return <DiamondsIcon color={color} size={size}/>;
            case 2:
                return <HeartsIcon color={color} size={size}/>;
            case 3:
                return <SpadesIcon color={color} size={size}/>;
        }
    };

    return (
        <View>
            <View style={{...ownStyles.itemContainer, ...(isDailyTournamentGame ? ownStyles.dailyTournamentGameContainer : (isViewerTurn ? ownStyles.currentTurnContainer : {}))}}>
                <View style={{position: 'absolute', top: -50, right: 10}}>
                    {getSuitIcon()}
                </View>
                <TouchableOpacity onPress={handleTogglePress} style={{justifyContent: 'center'}}>
                    {translateToggleIndex(toggleIndex) === 0 && (
                        <View style={{...ownStyles.yourTurnButton}}>
                            <StandardText style={{weight: 500}}>Your turn!</StandardText>
                        </View>
                    )}
                    {translateToggleIndex(toggleIndex) === 1 && (
                        <View>
                            <StandardText style={{...ownStyles.itemText}}>{isDailyTournamentGame ? game.duplicateConfigGameName : `Game ${game.id}`}</StandardText>
                            {(!game.hasEnded || game.winnerTeam?.id === GameHelper.getViewerTeam(game).id) && (
                                <StandardText
                                    style={{...ownStyles.itemText, ...ownStyles.itemTextSub}}>
                                        {game.hasEnded && game.winnerTeam?.id === GameHelper.getViewerTeam(game).id ? `YOU WON!` : `Round ${GameHelper.getRoundPoints(game.currentRound)}`}
                                </StandardText>
                            )}
                        </View>
                    )}
                    {translateToggleIndex(toggleIndex) === 2 && (
                        <View>
                            <StandardText style={{...ownStyles.itemText}}>Started: {createdAt}</StandardText>
                            {game.hasEnded ? (
                                <StandardText style={{...ownStyles.itemText}}>Ended: {endedAt}</StandardText>
                            ) : (
                                <StandardText style={{...ownStyles.itemText, ...ownStyles.itemTextSub}}>
                                    Next
                                    Turn: {isViewerTurn ? 'You' : (currentTurnPlayer ? (currentTurnPlayer.firstName) : 'Start Round ' + GameHelper.getRoundPoints(game.currentRound))}
                                </StandardText>
                            )}
                            
                        </View>
                    )}
                    {translateToggleIndex(toggleIndex) === 3 && (
                        <View>
                            <StandardText style={{...ownStyles.itemText, ...ownStyles.itemTextSub}}>
                                {viewerScoreText}
                            </StandardText>
                            <StandardText style={{...ownStyles.itemText, ...ownStyles.itemTextSub}}>
                                {opponentScoreText}
                            </StandardText>
                        </View>
                    )}
                    {translateToggleIndex(toggleIndex) === 4 && (
                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1}}>
                            <View style={{marginRight: 6}}>
                                <LoadingButton
                                    onPress={onArchiveTogglePress}
                                    label={GameHelper.getViewerPlayer(game).archived ? 'Move to Active' : 'Archive'}
                                    showChevron={false}
                                    backgroundColor={Colors.background}
                                    labelColor={Colors.onBackground}
                                />
                            </View>
                            <LoadingButton
                                onPress={onRemoveTogglePress}
                                label={'Delete'}
                                showChevron={false}
                                backgroundColor={Colors.background}
                                labelColor={Colors.onBackground}
                            />
                            <TouchableOpacity onPress={handleTogglePress} style={{marginStart: 10}}>
                                <StandardText style={{color: Colors.onPrimary}}>Cancel</StandardText>
                            </TouchableOpacity>
                        </View>
                    )}
                </TouchableOpacity>


                <TouchableOpacity onPress={onPress}
                                  style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 20}}>
                    {GameHelper.getViewerTeam(game).teamPlayers.map((player) => (
                        player.isCurrentTurn ? (
                            <PlayerBadge key={player.id} player={player} showBottomInfo={false} active={true} activePlainColor={itemColor}/>
                        ) : (
                            <View key={player.id}
                                  style={{...ownStyles.playerBadge, ...(player.isCurrentTurn ? {backgroundColor: 'white'} : {backgroundColor: itemColor})}}>
                                <StandardText style={{
                                    color: 'white',
                                    weight: 500, ...(player.isCurrentTurn ? {color: itemColor} : {})
                                }}>
                                    {player.firstName.substring(0, 1).toUpperCase()}
                                    {player.isNameInitialRepeated &&
                                    <StandardText style={{
                                        fontSize: 10,
                                        verticalAlign: 'super',
                                        color: 'white',
                                        weight: 500, ...(player.isCurrentTurn ? {color: itemColor} : {})
                                    }}>{player.seat}</StandardText>}
                                </StandardText>
                            </View>
                        )
                    ))}
                    <View style={{marginStart: 5, marginEnd: 5}}><StandardText
                        style={{weight: 500}}>vs</StandardText></View>
                    {GameHelper.getOpponentTeam(game).teamPlayers.map((player) => (
                        player.isCurrentTurn ? (
                            <PlayerBadge key={player.id} player={player} showBottomInfo={false} active={true} activePlainColor={itemColor}/>
                        ) : (
                            <View key={player.id}
                                  style={{...ownStyles.playerBadge, ...(player.isCurrentTurn ? {backgroundColor: 'white'} : {backgroundColor: itemColor})}}>
                                <StandardText style={{
                                    color: 'white',
                                    weight: 500, ...(player.isCurrentTurn ? {color: itemColor} : {})
                                }}>
                                    {player.firstName.substring(0, 1).toUpperCase()}
                                    {player.isNameInitialRepeated &&
                                    <StandardText style={{
                                        fontSize: 10,
                                        verticalAlign: 'super',
                                        color: 'white',
                                        weight: 500, ...(player.isCurrentTurn ? {color: itemColor} : {})
                                    }}>{player.seat}</StandardText>}
                                </StandardText>
                            </View>
                        )
                    ))}
                    <View style={{marginStart: 10, marginEnd: 5}}><ChevronIcon size={10} color={'white'}/></View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const ownStyles = {
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 2,
        padding: 7,
        marginBottom: 12,
        backgroundColor: 'rgba(248, 121, 80, 1)',
        overflow: 'hidden',
        height: 54
    },
    currentTurnContainer: {
        backgroundColor: 'rgba(136, 80, 255, 1)'
    },
    dailyTournamentGameContainer: {
        backgroundColor: Colors.tournamentColor
    },
    itemText: {
        fontSize: 14,
        weight: 500,
        color: Colors.onPrimary,
        lineHeight: 20
    },
    itemTextSub: {
        fontSize: 16
    },
    playerBadge: {
        width: 36,
        height: 36,
        borderRadius: 100,
        borderWidth: 2,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
    },
    yourTurnButton: {
        backgroundColor: 'white',
        borderRadius: 100,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
    }
};

export default GameItem;
