import * as React from 'react';
import {Svg, Path} from "react-native-svg";

const PoligonIcon = ({size, color}) => {

    return (
        <Svg width={size} height={size} viewBox="0 0 22 24" fill="none" >
            <Path fillRule="evenodd" clipRule="evenodd" d="M11.4997 0.288271C11.1903 0.109639 10.8091 0.109639 10.4997 0.288272L1.10742 5.71092C0.798021 5.88955 0.607422 6.21968 0.607422 6.57695V17.4222C0.607422 17.7795 0.798021 18.1096 1.10742 18.2883L10.4997 23.7109C10.8091 23.8896 11.1903 23.8896 11.4997 23.7109L20.892 18.2883C21.2014 18.1096 21.392 17.7795 21.392 17.4222V6.57695C21.392 6.21968 21.2014 5.88955 20.892 5.71092L11.4997 0.288271ZM10.9997 15.9996C13.2089 15.9996 14.9997 14.2087 14.9997 11.9996C14.9997 9.79046 13.2089 7.9996 10.9997 7.9996C8.79059 7.9996 6.99973 9.79046 6.99973 11.9996C6.99973 14.2087 8.79059 15.9996 10.9997 15.9996Z" fill={color}/>
        </Svg>
    );
};

export default PoligonIcon;
