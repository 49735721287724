import * as React from 'react';
import {Svg, Path, Ellipse, Circle} from "react-native-svg";

const HeartsIcon = ({size, color}) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
            <Ellipse cx="6.25" cy="7.5" rx="3.75" ry="3.75" fill={color}/>
            <Circle cx="13.75" cy="7.5" r="3.75" fill={color}/>
            <Path d="M9.44614 16.5806L3.4375 9.99967L10 7.08301L16.5625 9.99967L10.5539 16.5806C10.2565 16.9063 9.74354 16.9063 9.44614 16.5806Z" fill={color}/>
        </Svg>
    );
};


export default HeartsIcon;
