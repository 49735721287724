import {
    CLEAR_SIGNUP_ERROR,
    RECEIVE_FAILED_SIGNUP,
    RECEIVE_SIGNUP,
    SUBMIT_SIGNUP
} from "../actions";

const signup = (
    state = {
        isSigningUp: false,
        error: null,
    }
    , action) => {
    switch (action.type) {
        case SUBMIT_SIGNUP:
            return Object.assign({}, state, {
                isSigningUp: true,
                error: null
            });
        case RECEIVE_SIGNUP:
            return Object.assign({}, state, {
                isSigningUp: false,
                error: null
            });
        case RECEIVE_FAILED_SIGNUP:
            return Object.assign({}, state, {
                isSigningUp: false,
                error: action.payload.error
            });
        case CLEAR_SIGNUP_ERROR:
            return Object.assign({}, state, {
                error: null
            });
        default:
            return state;
    }
};

export default signup;
