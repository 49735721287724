import React from 'react';
import {Rect, Svg} from 'react-native-svg';

const GameOptionsIcon = ({ size, color }) => {
    return (
        <Svg width={size} viewBox="0 0 42 43" fill="none">
            <Rect x="3.18801" y="16.8134" width="16.6595" height="22.0212" rx="1.5" transform="rotate(-22.3381 3.18801 16.8134)" stroke={color} strokeWidth="3"/>
            <Rect x="14.9038" y="9.0909" width="16.6595" height="22.0212" rx="1.5" transform="rotate(7.66193 14.9038 9.0909)" fill="white" stroke={color} strokeWidth="3"/>
        </Svg>
    );
};

export default GameOptionsIcon;
