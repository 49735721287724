import React from 'react';
import {View} from "react-native";
import StandardText from "../standardText/StandardText";
import Spacing from "../../styles/spacing";
import Colors from "../../styles/colors";

const CreditCard = ({brand, last4, expMonth, expYear}) => {

    return (
        <View style={{...ownStyles.creditCardContainer}}>
            <View style={{...ownStyles.brandNumber}}>
                <StandardText style={{...ownStyles.brandText}}>{brand.charAt(0).toUpperCase() + brand.slice(1)}</StandardText>
                <StandardText style={{...ownStyles.numberText}}><StandardText style={{...ownStyles.dots}}>•••• •••• ••••</StandardText></StandardText>
                <StandardText style={{...ownStyles.last4}}>{last4}</StandardText>
            </View>
            <View style={{...ownStyles.expiration}}>
                <StandardText style={{...ownStyles.expirationText}}>Expires: {("0" + expMonth).substr(-2)}/{expYear}</StandardText>
            </View>
        </View>
    );
};

const ownStyles = {
    creditCardContainer: {
        flex: 1,
        paddingTop: Spacing.small,
        paddingStart: Spacing.large,
        paddingEnd: Spacing.large,
        paddingBottom: Spacing.base,
        borderWidth: 1,
        borderColor: Colors.onBackground,
        borderRadius: 5,
    },
    brandNumber: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: Spacing.small,
        borderBottomWidth: 1,
        borderBottomColor: Colors.onBackgroundBorder2,
        paddingBottom: Spacing.small
    },
    expiration: {},
    brandText: {
        fontSize: 14,
        color: Colors.onBackground
    },
    numberText: {
        fontSize: 14,
        alignItems: 'center',
        color: Colors.onBackground
    },
    last4: {
        fontSize: 14,
        lineHeight: 12,
        color: Colors.onBackground
    },
    expirationText: {
        fontSize: 14,
        color: Colors.onBackground
    },
    dots: {
        fontSize: 18,
        marginStart: Spacing.base,
        marginEnd: Spacing.small,
        color: Colors.onBackgroundBorder
    }
};

export default CreditCard;
