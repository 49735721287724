import React from "react";
import {TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import StyleContext from "../../StyleContext";
import {SafeAreaView} from "react-native-safe-area-context";
import {MaterialIcons} from "@expo/vector-icons";
import {useNavigation} from "@react-navigation/native";

const ManageSubscriptionScreen = () => {
    const navigation = useNavigation();
    
    return (
        <StyleContext.Consumer>
            {styles => (
                <SafeAreaView style={[styles.safeArea, styles.container, {flexDirection: 'column'}]}>
                    <View style={{position: 'absolute', top: 20, left: 20, width: 24, height: 24}}>
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('Home')
                        }}>
                            <MaterialIcons name="home" size={24} color={'#999999'}/>
                        </TouchableOpacity>
                    </View>
                    <View>
                        <StandardText style={styles.header}>Manage Subscription</StandardText>
                    </View>
                </SafeAreaView>
            )}
        </StyleContext.Consumer>
    );
};

export default ManageSubscriptionScreen;
