import * as React from 'react';
import { Svg, Circle, Rect, Path } from "react-native-svg";

const JokerIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 20 14" fill="none">
            <Path fillRule="evenodd" clipRule="evenodd" d="M5.22897 6.34922C4.27075 6.17241 3.32457 6.71189 2.98867 7.62655L1.90479 10.578C2.21761 11.115 1.90479 10.578 2.21736 11.115L4.55151 9.96395L4.85168 10.3587C5.61143 11.3577 6.0228 12.5782 6.0228 13.8333H14.0404C14.0404 12.5782 14.4518 11.3577 15.2117 10.3588L15.512 9.96395L17.8462 11.115C18.1588 10.578 17.8462 11.115 18.1588 10.578L17.2357 8.06437C16.8349 6.97302 15.5912 6.45222 14.5323 6.93233L13.7164 7.30229C13.0384 7.60972 12.2742 7.09498 12.3043 6.35112L12.3699 4.72847L14.7339 4.144C14.8207 3.39926 14.7339 4.144 14.8207 3.39926L12.577 1.78393C11.6204 1.09522 10.2787 1.37899 9.68285 2.39604L7.15825 6.70519L5.22897 6.34922Z" fill={color} />
            <Circle cx="15.7142" cy="3.83287" r="1.42857" fill="white" />
            <Circle cx="18.5714" cy="11.452" r="1.42857" fill="white" />
            <Circle cx="1.42857" cy="11.452" r="1.42857" fill="white" />
        </Svg>
    );
}

export default JokerIcon;