import Api from "../../../services/Api";
import UserSession from "../../../services/UserSession";
import {receiveLogin} from "../../auth/actions/login";

export const SUBMIT_SIGNUP = 'SUBMIT_SIGNUP';
export const submitSignup = () => ({
    type: SUBMIT_SIGNUP
});

export const RECEIVE_SIGNUP = 'RECEIVE_SIGNUP';
export const receiveSignup = ({userAccount, accessToken, refreshToken, newUser}) => ({
    type: RECEIVE_SIGNUP,
    payload: {
        userAccount: userAccount,
        accessToken: accessToken,
        refreshToken: refreshToken,
        newUser: newUser || false,
    }
});

export const RECEIVE_FAILED_SIGNUP = 'RECEIVE_FAILED_SIGNUP';
export const receiveFailedSignup = (error) => ({
    type: RECEIVE_FAILED_SIGNUP,
    payload: {
        error: error
    }
});

export const CLEAR_SIGNUP_ERROR = 'CLEAR_SIGNUP_ERROR';
export const clearSignupError = () => ({
    type: CLEAR_SIGNUP_ERROR
});

export const performSignup = (firstName, birthdate, email, password) => async (dispatch) => {
    dispatch(submitSignup());

    try {
        const tokenData = await Api.createUserAccount(firstName, birthdate, email, password);
        await UserSession.setTokenData(tokenData);
        dispatch(receiveSignup(tokenData));
        dispatch(receiveLogin(tokenData));
    } catch (e) {        
        console.error(e);
        dispatch(receiveFailedSignup(e.message));
    }
}
