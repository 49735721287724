import React, { useEffect, useState, useRef } from "react";
import {Pressable, TouchableOpacity, View} from "react-native";
import StandardText from "../standardText/StandardText";

const NotificationMessage = ({ message, timeout, onClear }) => {

    const [currentMessage, setCurrentMessage] = useState(null);
    const currentMessageRef = useRef(null);

    useEffect(() => {
        setCurrentMessage(message);
        currentMessageRef.current = message;
        if (message) {
            setTimeout(() => {
                if (currentMessageRef.current === message) {
                    setCurrentMessage(null);
                    onClear();
                }
            }, timeout);
        }

    }, [message]);

    const handleOnNotificationPress = () => {
        setCurrentMessage(null);
        onClear();
    };

    return (
        <View>
            {!!message && (
                <Pressable onPress={handleOnNotificationPress} style={{ backgroundColor: 'rgba(30, 30, 30, 0.9)', justifyContent: 'center', borderRadius: 4, padding: 15, alignItems: 'center' }}>
                    <StandardText style={{ color: 'white', lineHeight: 30 }}>{currentMessage}</StandardText>
                </Pressable>
            )}

        </View>
    );
};

export default NotificationMessage;
