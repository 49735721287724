import React from 'react';
import { Svg, Path } from 'react-native-svg';

const TutorialIcon = ({ size, color }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 21 26" fill="none">
            <Path d="M2.5 1.33333C1.85567 1.33333 1.33333 1.85567 1.33333 2.5V24C1.33333 24.6443 1.85567 25.1667 2.5 25.1667H18.8864C19.5307 25.1667 20.053 24.6443 20.053 24V2.5C20.053 1.85567 19.5307 1.33333 18.8864 1.33333H2.5Z" fill="white" stroke={color} strokeWidth="1.33333"/>
            <Path d="M6.59082 5.42513C5.94649 5.42513 5.42415 5.94746 5.42415 6.5918V8.65998C5.42415 9.30431 5.94649 9.82665 6.59082 9.82665H14.7954C15.4397 9.82665 15.962 9.30431 15.962 8.65998V6.5918C15.962 5.94747 15.4397 5.42513 14.7954 5.42513H6.59082Z" fill="white" stroke={color} strokeWidth="1.33333"/>
            <Path d="M5.06812 13.25H16.3181" stroke={color} strokeWidth="1.33333"/>
            <Path d="M5.06812 16.3184H16.3181" stroke={color} strokeWidth="1.33333"/>
        </Svg>


    );
};

export default TutorialIcon;
