import React, {useState, useEffect} from "react";
import {View, TouchableOpacity} from "react-native";
import Colors from "../../styles/colors";
import StandardText from "../standardText/StandardText";
import CheckIcon from "../../icons/CheckIcon";

const Checkbox = ({value, onValueChange, label, sublabel, description, disabled, labelColor, boxColor}) => {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    const handlePress = () => {
        onValueChange();
    };

    return (
        <TouchableOpacity onPress={handlePress} style={{width: '100%'}} disabled={disabled} >
            <View style={ownStyles.container}>
                <View style={{...ownStyles.box, backgroundColor: checked ? (boxColor ? boxColor : Colors.primary6) : 'rgba(246, 247, 248, 1)', ...(checked ? {borderColor: (boxColor ? boxColor : Colors.primary6)} : {})}}>
                    {checked && (
                        <CheckIcon color={Colors.onPrimary} size={12} />
                    )}
                </View>
                <View style={ownStyles.label}>
                    <StandardText style={{color: labelColor ? labelColor : (checked ? Colors.onBackground4 : Colors.onBackground)}}>{label}</StandardText>
                    <StandardText style={{fontSize: 14, marginStart: 5, opacity: 0.7, color: checked ? Colors.onBackground3 : Colors.onBackground3}}>{sublabel}</StandardText>
                </View>

            </View>
            {description &&
            <View>
                <StandardText style={{color: Colors.redCard2, marginStart: 36, fontSize: 14, lineHeight: 18}}>{description}</StandardText>
            </View>
            }
        </TouchableOpacity>
    );
};

export default Checkbox;

const ownStyles = {
    container: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        padding: 5
    },
    box: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderRadius: 3,
        borderColor: Colors.onBackgroundBorder,
        marginRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    }
};
